import React from 'react';
import PropTypes from 'prop-types';
import { replaceId, routes } from 'paths';
import { connect } from 'react-redux';
import { fetchCustomer, deleteCustomer } from 'state/actions';
import { CreateContract } from 'components/contract/CreateContract';
import { SendCustomerMessage } from 'components/negotiation/SendCustomerMessage';
import { SendCustomerNotification } from 'components/negotiation/SendCustomerNotification';
import { Card } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { CardActions } from 'pamind-ui';
import withStyles from '@mui/styles/withStyles';
import { ListItem } from 'pamind-ui';
import { ListItemAvatar } from 'pamind-ui';
import { List } from 'pamind-ui';
import FaceIcon from '@mui/icons-material/Face';
import IconContract from '@mui/icons-material/Description';
import moment from 'moment';

import { getContractTypes } from 'services/domain';
import { AlignHorizontally } from 'components/ui/AlignHorizontally';
import { Avatar } from 'pamind-ui';
import { VerticalSpacing } from 'domain-components';
import { ConfirmationDialog } from 'domain-components';
import { ListItemText } from 'pamind-ui';
import { Link } from 'react-router-dom';
import { Button, Chip, Typography } from 'pamind-ui';
import { getConvertedMobileNumber } from 'components/utils/getConvertedMobileNumber';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Box } from '@mui/material';

const styles = theme => ({
  actions: {
    display: 'flex'
  }
});
export class Component extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchCustomer(Number(this.props.match.params.id)));
  }

  delete = () => {
    this.props.dispatch(deleteCustomer(this.props.customer.CustomerId));
  };

  render() {
    if (!this.props.customer) {
      return null;
    }

    const { customer } = this.props;

    const handleLogin = e => {
      window.open(
        '/api/a/customer/assume-identity/' + customer.CustomerId,
        '_blank'
      );
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      return false;
    };

    const handleExport = e => {
      window.open('/api/a/customer/export/' + customer.CustomerId, '_blank');
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      return false;
    };

    const contractTypes = getContractTypes(this.props.metadata).map(
      contractType => {
        return {
          type: contractType.label,
          contracts: this.props.customer.Contracts.filter(
            c => c.ContractType === contractType.key
          )
        };
      }
    );

    const convertedMobileNumber = getConvertedMobileNumber(
      customer?.MobileNumber
    );

    const activeContractTypes = contractTypes.filter(
      c => c.contracts.length > 0
    );

    const fullName = `${this.props.customer.FirstName} ${this.props.customer.LastName}`;
    return (
      <VerticalSpacing style={{ maxWidth: '70rem' }}>
        <Card>
          <CardHeader
            avatar={
              <Avatar>
                <FaceIcon />
              </Avatar>
            }
            title={<Typography variant="h4">{fullName}</Typography>}
            subheader={customer.SecurityNumber}
          />
          <AlignHorizontally fillSpace={true}>
            <List dense={true}>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="h6">Email</Typography>}
                  secondary={customer.Email}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="h6">Personnummer</Typography>}
                  secondary={customer.SecurityNumber}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="h6">Mobilnummer</Typography>}
                  secondary={convertedMobileNumber}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h6">Senaste OrderRef</Typography>
                  }
                  secondary={
                    <Box
                      onClick={() => {
                        navigator.clipboard.writeText(customer.LatestOrderRef);
                      }}
                    >
                      {customer.LatestOrderRef ? customer.LatestOrderRef : '-'}
                      {'  '}
                      <FileCopyIcon />
                    </Box>
                  }
                />
              </ListItem>
            </List>
            <List dense={true}>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="h6">Adress</Typography>}
                  secondary={customer?.Address?.Name ?? '-'}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="h6">Postnummer</Typography>}
                  secondary={customer?.Address?.ZipCode}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="h6">Ort</Typography>}
                  secondary={customer?.Address?.City}
                />
              </ListItem>
            </List>
            <List dense={true}>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h6">Signerad fullmakt</Typography>
                  }
                  secondary={customer.SignedPowerOfAttorney ? 'Ja' : 'Nej'}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="h6">GDPR consent</Typography>}
                  secondary={moment(customer.GdprConsent).format('YYYY-MM-DD')}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="h6">Kampanj</Typography>}
                  secondary={
                    customer.TrackingSource ||
                    customer.TrackingMedium ||
                    customer.TrackingCampaign
                      ? customer.TrackingSource +
                        customer.TrackingMedium +
                        customer.TrackingCampaign
                      : '-'
                  }
                />
              </ListItem>
            </List>
            <List dense={true}>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h6">Registreringsdatum</Typography>
                  }
                  secondary={moment(customer.RegisterDate).format('YYYY-MM-DD')}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h6">Senaste inloggning</Typography>
                  }
                  secondary={customer.LatestLogIn ? customer.LatestLogIn : '-'}
                />
              </ListItem>
            </List>
          </AlignHorizontally>
          <CardActions expandable={true}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              to={replaceId(routes.customers.edit, customer.CustomerId)}
            >
              Redigera
            </Button>
            <ConfirmationDialog
              label="Radera kund"
              color="secondary"
              size="small"
              variant="outlined"
              onConfirm={this.delete}
              confirmLabel="Radera"
              dialogTitle="Radera kund"
              dialogMessage="Är du säker på att du vill radera kunden? All relaterad data och statistik kommer försvinna permanent."
            />
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleExport}
            >
              Exportera
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleLogin}
            >
              Logga in som kund
            </Button>
            <SendCustomerMessage
              customerId={customer.CustomerId}
              label="Skicka kundmail"
              templates={[
                'OnboardingWelcome',
                'OnboardingContractsNotAdded',
                'OnboardingContractsNotAddedReminder'
              ]}
            />
            <SendCustomerNotification
              label="Skicka kundnotifikation"
              email={this.props.customer.Email}
              firstName={this.props.customer.FirstName}
              lastName={this.props.customer.LastName}
              templates={['RemoveAccount']}
            />
          </CardActions>
        </Card>
        <Card>
          <CardHeader title={<Typography variant="h3">Avtal</Typography>} />
          {activeContractTypes.map((item, index) => (
            <List key={index}>
              <ListItem>
                <Typography variant="h4">{item.type}</Typography>
              </ListItem>
              {item.contracts.map((c, index) => {
                const values = c.Attributes;
                if (!c.CurrentNegotiation)
                  return (
                    <List dense={true} key={index}>
                      <ListItem
                        component={Link}
                        button
                        to={replaceId(routes.contracts.show, c.Id)}
                        values={values}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <IconContract />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <span>
                              <Typography variant="h6">
                                {c.ContractProvider}
                              </Typography>
                            </span>
                          }
                          secondary={<span>¯\_(ツ)_/¯</span>}
                        />
                      </ListItem>
                    </List>
                  );
                values['State'] = c.CurrentNegotiation.State;
                return (
                  <List dense={true} key={index}>
                    <ListItem
                      component={Link}
                      button
                      to={replaceId(routes.contracts.show, c.Id)}
                      values={values}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <IconContract />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        primary={
                          <span>
                            <Typography variant="h6">
                              {c.ContractProvider} (
                              {c.CurrentNegotiation &&
                              c.CurrentNegotiation.ReferenceNumber
                                ? c.CurrentNegotiation.ReferenceNumber
                                : 'Ref. nummer saknas'}
                              ){' '}
                              {c.IsNonNegotiable ? (
                                <span>
                                  {' '}
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    label="Ej förhandlingsbar"
                                  />
                                </span>
                              ) : (
                                ''
                              )}
                            </Typography>
                          </span>
                        }
                        secondary={
                          <span>
                            {c.CurrentNegotiation.State}{' '}
                            <Chip
                              variant="outlined"
                              size="small"
                              label={`contract: ${c.State}`}
                            />
                          </span>
                        }
                      />
                    </ListItem>
                  </List>
                );
              })}
            </List>
          ))}
        </Card>
        <CreateContract
          customerId={customer.CustomerId}
          signedPowerOfAttorney={customer.SignedPowerOfAttorney}
        />
      </VerticalSpacing>
    );
  }
}

Component.propTypes = {
  metadata: PropTypes.object,
  customer: PropTypes.object,
  dispatch: PropTypes.func,
  classes: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
};

export const ShowCustomer = connect(state => ({
  metadata: state.metadata,
  customer: state.customer
}))(withStyles(styles)(Component));
