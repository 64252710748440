"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Theme = void 0;

var _BaseTheme = require("./BaseTheme");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var theme = (0, _BaseTheme.baseTheme)({});
var customerTheme = (0, _BaseTheme.baseTheme)({
  palette: {
    mode: 'light'
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        variant: 'outlined'
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          textDecorationColor: theme.palette.text.primary
        }
      }
    }
  }
});
var silverTheme = (0, _BaseTheme.baseTheme)({
  palette: {
    mode: 'light',
    primary: {
      main: '#523A2D'
    },
    secondary: {
      main: '#FFE68E'
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        variant: 'outlined'
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          textDecorationColor: theme.palette.text.primary
        }
      }
    }
  }
});
var darkCustomerTheme = (0, _BaseTheme.baseTheme)({
  palette: {
    mode: 'dark'
  },
  components: {
    MuiTextField: {
      variant: 'outlined'
    }
  }
});
var lightTheme = (0, _BaseTheme.baseTheme)({
  themeName: 'Light Theme',
  palette: {
    mode: 'light'
  },
  props: {
    MuiTextField: {
      variant: 'outlined'
    }
  }
});
var darkTheme = (0, _BaseTheme.baseTheme)({
  themeName: 'Dark Theme',
  palette: {
    mode: 'dark',
    primary: {
      main: '#080E08'
    },
    secondary: {
      main: '#f50057'
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)'
    },
    background: {
      default: '#303030',
      paper: '#5F6473'
    }
  },
  typography: {},
  props: {
    MuiTextField: {
      variant: 'outlined'
    }
  }
});
var funkyBlue = (0, _BaseTheme.baseTheme)({
  themeName: 'Funky Blue',
  palette: {
    type: 'dark',
    primary: {
      main: '#067FE4'
    },
    secondary: {
      main: '#00EBF5'
    },
    info: {
      main: '#219CF3'
    }
  },
  props: {
    MuiTextField: {
      variant: 'outlined'
    }
  }
});
var forestGreen = (0, _BaseTheme.baseTheme)({
  themeName: 'Forest Green',
  palette: {
    mode: 'light',
    primary: {
      main: '#388e3c'
    },
    secondary: {
      main: '#c2185b'
    }
  },
  props: {
    MuiTextField: {
      variant: 'outlined'
    }
  }
});
var coolBanana = (0, _BaseTheme.baseTheme)({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffeb3b'
    },
    secondary: {
      main: '#f50057'
    },
    background: {
      default: '#ffeb3b',
      paper: '#fbfbe2'
    }
  },
  props: {
    MuiTextField: {
      variant: 'outlined'
    }
  },
  typography: {
    fontFamily: ['Comic Sans MS'].join(',')
  }
});
var supplierPalette = {
  green: {
    100: '#e7f1f1',
    200: '#8ac9ca',
    300: '#55afb0',
    350: undefined,
    400: '#159394',
    500: '#00696a',
    600: '#004e4f'
  },
  grey: {
    100: '#eaedf1',
    200: '#ced2d9',
    300: '#a1a9b7',
    400: '#7b8494',
    500: '#5e6773',
    600: '#484c57'
  }
};
var supplierTheme = (0, _BaseTheme.baseTheme)({
  themeName: 'Supplier theme',
  palette: {
    mode: 'light',
    green: _objectSpread({
      light: supplierPalette.green[400],
      main: supplierPalette.green[500],
      dark: supplierPalette.green[600]
    }, supplierPalette.green),
    primary: _objectSpread({
      light: supplierPalette.green[400],
      main: supplierPalette.green[500],
      dark: supplierPalette.green[600],
      contrastText: supplierPalette.grey[200]
    }, supplierPalette.green),
    success: _objectSpread({
      light: supplierPalette.green[400],
      main: supplierPalette.green[500],
      dark: supplierPalette.green[600]
    }, supplierPalette.green),
    secondary: {
      main: '#fd968f'
    },
    background: {
      default: '#E4EFEE',
      main: supplierPalette.green[100]
    },
    error: {
      main: '#AD393D'
    }
  },
  props: {
    MuiTextField: {
      variant: 'filled'
    }
  },
  components: {
    ContentWrapper: {
      maxWidth: 960
    }
  },
  domainOverrides: {
    Modal: {
      root: {
        borderRadius: theme.shape.borderRadius * 2
      },
      topbar: {
        textAlign: 'left'
      },
      content: {
        padding: theme.spacing(12, 22)
      },
      mobile: {
        '&$paperScrollBody': _defineProperty({}, theme.breakpoints.up('sm'), {
          maxWidth: '640px'
        })
      }
    }
  }
});
var newSupplierTheme = (0, _BaseTheme.baseTheme)({
  themeName: 'Supplier theme',
  palette: {
    mode: 'light',
    text: {
      disabled: supplierPalette.grey[300]
    }
  },
  props: {
    MuiTextField: {
      variant: 'outlined'
    }
  },
  domainOverrides: {
    Modal: {
      root: {
        borderRadius: theme.shape.borderRadius * 2
      },
      topbar: {
        textAlign: 'left'
      },
      content: {
        padding: theme.spacing(12, 22)
      },
      mobile: {
        '&$paperScrollBody': _defineProperty({}, theme.breakpoints.up('sm'), {
          maxWidth: '640px'
        })
      }
    }
  },
  components: {
    TextField: {
      backgroundColor: theme.palette.common.white
    }
  }
});
var Theme = {
  coolBanana: coolBanana,
  funkyBlue: funkyBlue,
  darkTheme: darkTheme,
  lightTheme: lightTheme,
  customerTheme: customerTheme,
  darkCustomerTheme: darkCustomerTheme,
  supplierWeb: supplierTheme,
  forestGreen: forestGreen,
  newSupplierTheme: newSupplierTheme,
  silverTheme: silverTheme
};
exports.Theme = Theme;