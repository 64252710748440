"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

/**
 * @param {String} currentValue value in form field
 */
var usePrevious = function usePrevious(currentValue) {
  var ref = (0, _react.useRef)();
  (0, _react.useEffect)(function () {
    ref.current = currentValue;
  });
  return ref.current;
};

var _default = usePrevious;
exports.default = _default;