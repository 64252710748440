export function setLoggedIn() {
  window.location.reload();
}

let cookieName;

if (window.location.hostname === 'admin.pamind.se')
  cookieName = '__Sec.1.Production.Admin';
else if (window.location.hostname === 'admin.dev.pamind.se')
  cookieName = '__Sec.1.Progress.Admin';
else if (/^admin\.test-[^.]+\.pamind\.se$/.test(window.location.hostname))
  cookieName = '__Sec.1.Testing.Admin';
else if (window.location.hostname === 'admin.staging.pamind.se')
  cookieName = '__Sec.1.Staging.Admin';
else cookieName = '__Sec.1.Development.Admin';

export function setLoggedOut() {
  document.cookie =
    cookieName +
    '=; ' +
    (window.location.hostname.indexOf('.pamind.se') >= 0
      ? 'Domain=.pamind.se; '
      : '') +
    'Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  window.location.reload();
}

export function isLoggedIn() {
  // TODO: Temporary! Replace this so we can start using HttpOnly cookies.
  return document.cookie.indexOf(cookieName) >= 0;
}
