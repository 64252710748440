import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'pamind-ui';
import EditIcon from '@mui/icons-material/Create';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmIcon from '@mui/icons-material/ThumbUp';
import ListIcon from '@mui/icons-material/List';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInBrowser';
import { Card } from 'pamind-ui';
import { CardContent } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { CardActions } from 'pamind-ui';
import { Switch } from 'pamind-ui';
import { Avatar, Box } from 'pamind-ui';
import { Select, MenuItem } from 'pamind-ui';
import { NegotiationDetailsForm } from 'components/negotiation/NegotiationDetailsForm';
import { GeneratedForm, GeneratedList } from 'domain-components';
import { Savings as SavingsComponent } from 'domain-components';
import { AlignHorizontally } from 'components/ui/AlignHorizontally';
import { KeyValueListItem, KeyValueList } from 'components/ui/KeyValueList';
import { RequestCompletion } from 'components/negotiation/RequestCompletion';
import { isNullOrEmpty } from 'components/utils/IsNullOrEmpty';
import { NotProvideTerms } from 'components/negotiation/NotProvideTerms';
import { SelectOfferCategory } from 'components/negotiation/SelectOfferCategory';
import { SendNegotiationProviderMessage } from 'components/negotiation/SendNegotiationProviderMessage';
import { SendNegotiationCustomerMessage } from 'components/negotiation/SendNegotiationCustomerMessage';
import { ListSubheader } from 'pamind-ui';
import { editTermsAllowed, editTermsEnabled } from 'services/domain';
import { selectors } from 'domain-methods';
import {
  updateNegotiationTerms,
  selectTerms,
  deleteTerms,
  confirmTerms,
  improveContract,
  getOfferTemplates
} from 'state/actions';
import { connect } from 'react-redux';
import { substitution } from 'domain-methods';
import { FormGroup, FormControlLabel } from 'pamind-ui';
import { Typography } from 'pamind-ui';
import PausedIcon from '@mui/icons-material/Snooze';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ConfirmationDialog } from 'domain-components';
import withStyles from '@mui/styles/withStyles';
import IconSend from '@mui/icons-material/Send';
import moment from 'moment';

const style = theme => ({
  card: {
    flexBasis: '50%',
    paddingRight: '0.5rem'
  }
});

const TermsCard = withStyles(style, { name: 'TermsCard' })(props => {
  const avatar = props.showSelectedAt ? (
    props.selectedAt ? (
      <Avatar color="secondary">
        <CheckIcon color={'inherit'} />
      </Avatar>
    ) : (
      <Avatar color="primary">
        <CloseIcon color={'inherit'} />
      </Avatar>
    )
  ) : (
    <Avatar color="primary">
      <ListIcon color={'inherit'} />
    </Avatar>
  );

  const handleSelectTemplate = (props, value) => {
    props.setValuesFromTemplate(value);
  };

  const subheader = props.showSelectedAt
    ? props.selectedAt
      ? 'Vald: ' + new Date(props.selectedAt).toLocaleDateString()
      : 'Avvisad'
    : null;

  return (
    <Box className={props.classes.card}>
      <Card elevation={3}>
        <CardHeader
          title={
            <Typography variant="h2" noWrap>
              {props.title}
            </Typography>
          }
          subheader={subheader}
          avatar={avatar}
        />
        <CardContent>
          <NotProvideTerms
            notProvideTerms={props.notProvideTerms}
            reason={props.notProvideTermsReason}
            editMode={props.editMode}
            onChange={props.onChangeNotProvideTerms}
            title={props.title}
          />
          {props.contracts && (
            <Select
              label={'Koppla ihop med ett befintlig avtal'}
              autoWidth={true}
              onChange={e =>
                props.onChangeParentContract(e.target.value, props.contracts)
              }
              id="parentContract"
              value={props.parentContractId ?? 0}
              variant="outlined"
              style={{ marginBottom: '20px' }}
            >
              <MenuItem key={0} value={0}>
                Koppla ihop med ett befintlig avtal
              </MenuItem>
              {props.contracts.map((item, index) => (
                <MenuItem key={index} value={item.Id}>
                  {`${item.ContractProvider} (${item.ContractType})`}
                </MenuItem>
              ))}
            </Select>
          )}
          {!!props.templates && (
            <Select
              label={'Produktmall'}
              autoWidth={true}
              onChange={e => handleSelectTemplate(props, e.target.value)}
              id="templates"
              value={props.selectedTemplate ?? 0}
              variant="outlined"
              style={{ marginBottom: '20px' }}
            >
              <MenuItem key={0} value={0}>
                Produktmall
              </MenuItem>
              {props.templates.map((item, index) => (
                <MenuItem key={index} value={item.Id}>
                  {item.Name}
                </MenuItem>
              ))}
            </Select>
          )}
          {props.editMode && !props.notProvideTerms && (
            <GeneratedForm
              values={props.terms}
              initialValues={props.initialTerms}
              view={props.fields}
              onChange={props.onChange}
              onValidate={props.onValidate}
              settings={{
                autoFocus: props.autoFocus,
                startDate: props.negotiationDeadlineDate
              }}
            />
          )}
          {!props.editMode && !props.notProvideTerms && (
            <GeneratedList
              values={props.terms}
              view={selectors.removeNullValuesFromView(
                props.fields,
                props.terms
              )}
              settings={{
                keyValueDivider: 'exceptLast',
                keyValueCompact: true
              }}
            />
          )}
        </CardContent>
        <CardActions>
          {!props.editMode && props.state === 'OfferReceived' && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                props.selectTerms(props.negotiationId, props.contractTermsId);
              }}
              size="small"
            >
              <AddIcon color={'primary'} />
              Välj
            </Button>
          )}
          {Object.values(props.terms).length > 0 &&
            props.terms !== null &&
            props.state === 'SupplierResponded' &&
            props.deleteTerms !== undefined &&
            !props.editMode && (
              <ConfirmationDialog
                size="small"
                onConfirm={() => props.deleteTerms(props.contractTermsId)}
                label="Radera terms"
                icon={<DeleteIcon color={'primary'} />}
              />
            )}
        </CardActions>
      </Card>
    </Box>
  );
});

TermsCard.propTypes = {
  editMode: PropTypes.bool,
  terms: PropTypes.object,
  initialTerms: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  deleteTerms: PropTypes.func,
  fields: PropTypes.array,
  title: PropTypes.string.isRequired,
  selectTerms: PropTypes.func,
  contractTermsId: PropTypes.number,
  negotiationId: PropTypes.number,
  state: PropTypes.string,
  selectedAt: PropTypes.string,
  autoFocus: PropTypes.bool,
  showSelectedAt: PropTypes.bool,
  notProvideTerms: PropTypes.bool,
  notProvideTermsReason: PropTypes.string,
  onChangeNotProvideTerms: PropTypes.func,
  negotiationDeadlineDate: PropTypes.string,
  contracts: PropTypes.object,
  onChangeParentContract: PropTypes.func,
  parentContractId: PropTypes.number,
  templates: PropTypes.array,
  setValuesFromTemplate: PropTypes.func,
  selectedTemplate: PropTypes.number
};

export class Component extends React.Component {
  state = {
    editMode: false,
    nonNegotiatedValues: this.props.negotiation.NonNegotiatedTerms || {},
    nonNegotiatedValidationResult: { isValid: false, isChanged: false },
    offerValues: this.props.negotiation.Offer || {},
    offerValidationResult: { isValid: false, isChanged: false },
    details: '',
    detailsIsChanged: false,
    offerCategory: null,
    offerCategoryChanged: false,
    noOfferIsChanged: false,
    noNonNegotiatedIsChanged: false,
    isSupplierResponse: false,
    isSupplierResponseChanged: false,
    parentContractId: null,
    selectedTemplate: null,
    currentSupplierName: '',
    generateAutoPO: true
  };

  handleConfirmTerms = () => {
    const negotiationId = this.props.negotiation.Id;
    const contractId = this.props.contractId;
    this.props.dispatch(confirmTerms(negotiationId, contractId));
  };

  componentDidMount() {
    this.props.dispatch(getOfferTemplates(this.props.contractId));
    if (this.props.negotiation !== undefined) {
      this.setState({
        editMode: editTermsEnabled(
          this.props.negotiation.State,
          this.props.contractState
        ),
        details: this.props.negotiation.NegotiationDetails || '',
        detailsIsChanged: false,
        noNonNegotiatedIsChanged: false,
        noOfferIsChanged: false,
        noOffer: this.props.negotiation.NoOffer,
        noNonNegotiated: this.props.negotiation.NoNonNegotiated,
        noOfferReason: this.props.negotiation.NoOfferReason,
        isSupplierResponse:
          this.props.negotiation.State === 'Waiting' ||
          this.props.negotiation.State === 'SupplierResponded' ||
          this.props.negotiation.State === 'TermsRequested',
        parentContractId: this.props.contract?.ParentId
      });
      if (this.props.negotiation.Offer) {
        this.setState({
          offerCategory: this.props.negotiation.Offer.OfferCategory || null,
          offerCategoryChanged: false
        });
      }
    }
  }

  improveContract = () => {
    this.props.dispatch(improveContract(this.props.contract.Id));
  };

  setNonNegotiatedTerms = values => {
    this.setState({
      nonNegotiatedValues: values
    });
  };

  setNonNegotiatedValidationResult = validationResult => {
    this.setState({
      nonNegotiatedValidationResult: validationResult
    });
  };

  setOfferTerms = values => {
    this.setState({
      offerValues: values
    });
  };

  setOfferValidationResult = validationResult => {
    this.setState({
      offerValidationResult: validationResult
    });
  };

  deleteTerms = contractTermsId => {
    this.props.dispatch(deleteTerms(contractTermsId, this.props.contractId));
  };

  setDetails = value => {
    const parsedValue = substitution.string(
      value,
      '{leverantör}',
      this.props.contractProvider
    );
    this.setState({
      details: parsedValue,
      detailsIsChanged:
        (this.props.negotiation.NegotiationDetails || '') !== parsedValue
    });
  };

  setNoNonNegotiated = data => {
    if (data.notProvideTerms !== undefined)
      this.setState({ noNonNegotiated: data.notProvideTerms });
    this.setState({
      noNonNegotiatedIsChanged:
        this.props.negotiation.NoNonNegotiated !== data.notProvideTerms
    });
  };

  setNoOffer = data => {
    if (data.notProvideTerms !== undefined)
      this.setState({ noOffer: data.notProvideTerms });
    if (data.notProvideTermsReason !== undefined)
      this.setState({
        noOfferReason: data.notProvideTermsReason
      });
    this.setState({
      noOfferIsChanged:
        (this.props.negotiation.NoOfferReason || '') !==
          data.notProvideTermsReason ||
        this.props.negotiation.NoOffer !== data.notProvideTerms
    });
  };

  setSupplierResponse = (e, value) => {
    this.setState({
      isSupplierResponse: value,
      isSupplierResponseChanged:
        (this.props.negotiation.State === 'SupplierResponded') !== value
    });
  };

  setGenerateAutoPO = e => {
    this.setState({
      generateAutoPO: e.target.checked
    });
  };

  setOfferCartegory = e => {
    this.setState({
      offerCategory: e.target.value,
      offerCategoryChanged:
        (this.props.negotiation.OfferCategory || null) !== e.target.value
    });
  };

  selectTerms = (negotiationId, contractTermsId) => {
    this.props.dispatch(
      selectTerms(negotiationId, contractTermsId, this.props.contractId)
    );
  };

  allTermsAreEmpty = (terms, fields) => {
    for (const key in terms) {
      if (
        terms[key] !== null &&
        terms[key].Value !== undefined &&
        isNullOrEmpty(terms[key].Value)
      ) {
        terms[key].Value = null;
      }
    }

    for (const key in terms) {
      const implierParent = Object.keys(fields).find(
        f => fields[f].percentageImplierField === key
      );
      if (
        (!isNullOrEmpty(terms[key]) ||
          (implierParent !== undefined && terms[implierParent] !== null)) &&
        key !== 'Id' &&
        key !== 'CalculatedCost' &&
        terms[key].Value !== null
      ) {
        return false;
      }
    }
    return true;
  };

  saveButtonDisabled = () => {
    if (
      !this.state.nonNegotiatedValidationResult.isChanged &&
      !this.state.offerValidationResult.isChanged &&
      !this.state.isSupplierResponseChanged &&
      !this.state.detailsIsChanged &&
      !this.state.offerCategoryChanged &&
      !this.state.noOfferIsChanged &&
      !this.state.noNonNegotiatedIsChanged
    ) {
      return true;
    }

    return false;
  };

  onChangeParentContract = (value, contracts) => {
    let supplierName = contracts.find(contract => contract.Id === value)
      .ContractProvider;
    this.setState({ currentSupplierName: supplierName });
    this.setState({
      parentContractId: value
    });
  };

  setValuesFromTemplate = value => {
    const template = this.props.contractTermsTemplates.find(
      temp => temp.Id === value
    );
    this.setState({
      details: template?.Terms.StandardText
    });
    this.setState({ offerValues: template?.Terms });
    this.setState({
      selectedTemplate: value
    });
  };

  handleUpdateNegotiationTerms = () => {
    const state = {
      metadata: { data: this.props.metadata },
      negotiation: { data: { ...this.props.negotiation, State: 'other' } }
    };

    const adminFieldsNonNegotiated = selectors.getNegotiationFieldsForView(
      state,
      'adminwebNonNegotiated'
    );

    const adminFieldsOffer = selectors.getNegotiationFieldsForView(
      state,
      'adminwebOffer'
    );

    const nonNegotiated = this.allTermsAreEmpty(
      this.state.nonNegotiatedValues,
      adminFieldsNonNegotiated
    )
      ? null
      : this.state.nonNegotiatedValues;

    const offer = this.allTermsAreEmpty(
      this.state.offerValues,
      adminFieldsOffer
    )
      ? null
      : this.state.offerValues;

    if (offer && this.state.offerCategory) {
      offer.OfferCategory = this.state.offerCategory;
    }

    this.props.dispatch(
      updateNegotiationTerms(
        this.props.negotiation.Id,
        this.props.contractId,
        nonNegotiated,
        offer,
        this.state.details,
        this.state.isSupplierResponse,
        this.state.noNonNegotiated,
        this.state.noOffer,
        this.state.noOfferReason,
        this.state.parentContractId,
        this.state.generateAutoPO,
        this.state.selectedTemplate
      )
    );
  };

  render() {
    if (!this.props.negotiation) {
      return null;
    }
    const {
      ReferenceNumber,
      Type,
      State,
      NonNegotiatedTerms,
      Offer,
      ContractType,
      Savings,
      NewSupplierWebUsed
    } = this.props.negotiation;

    const startDate = moment(this.props.negotiation.StartDate, 'YYYY-MM-DD');
    const lastReplyDate = moment(
      this.props.negotiation.LastReplyDate,
      'YYYY-MM-DD'
    );
    const deadlineDate = moment(
      this.props.negotiation.NegotiationDeadlineDate,
      'YYYY-MM-DD'
    );

    const state = {
      metadata: { data: this.props.metadata },
      negotiation: { data: { ...this.props.negotiation, State: 'other' } }
    };
    const adminFieldsNonNegotiated = selectors.getNegotiationFieldsForView(
      state,
      'adminwebNonNegotiated'
    );

    const adminFieldsOffer = [
      ...selectors.getNegotiationFieldsForView(state, 'adminwebOffer'),
      ...(this.props.contractState === 'Draft'
        ? selectors.getNegotiationFieldsForView(
            state,
            'adminwebOfferDraftOfferExtra'
          )
        : [])
    ];

    const showSelectedAt =
      (NonNegotiatedTerms && NonNegotiatedTerms.SelectedAt) ||
      (Offer && Offer.SelectedAt);
    const showEditButton =
      editTermsAllowed(State, this.props.contractState) && !this.state.editMode;
    const rawFields =
      !!this.props.metadata &&
      this.props.metadata.contractTypes[ContractType].fields;
    const offerCategories =
      rawFields && rawFields.OfferCategory
        ? rawFields.OfferCategory.options
        : [];
    const calculatedCostUnit =
      !!rawFields &&
      !!rawFields.CalculatedCost &&
      !!rawFields.CalculatedCost.unit
        ? rawFields.CalculatedCost.unit
        : 'kr/år';
    const contracts =
      this.props.contractState === 'Draft'
        ? this.props.customer.Contracts?.filter(c => c.State === 'Ongoing')
        : null;

    return (
      <Fragment>
        {this.props.contractState === 'Draft' && State === 'OfferReceived' && (
          <Box padding={4}>
            <Typography>länk till kundens avtal</Typography>
            <code style={{ backgroundColor: '#eeebeb' }}>
              {`https://app.pamind.se/dashboard/contract/${this.props.contractId}`}
            </code>
          </Box>
        )}

        <KeyValueList>
          <KeyValueListItem itemKey="Ärendenr" itemValue={ReferenceNumber} />
          <KeyValueListItem itemKey="Tillstånd" itemValue={State} />
          <KeyValueListItem itemKey="Förhandlingstyp" itemValue={Type} />
          <KeyValueListItem
            itemKey="Startdatum"
            itemValue={
              startDate.isValid() &&
              `${startDate.format('YYYY-MM-DD')} (${deadlineDate.diff(
                startDate,
                'days'
              )} till deadline)`
            }
          />
          <KeyValueListItem
            itemKey="Sista svarsdatum"
            itemValue={
              lastReplyDate.isValid() &&
              `${lastReplyDate.format('YYYY-MM-DD')} (${deadlineDate.diff(
                lastReplyDate,
                'days'
              )} till deadline)`
            }
          />
          <KeyValueListItem
            itemKey="Deadline"
            itemValue={deadlineDate.format('YYYY-MM-DD')}
          />
          <KeyValueListItem
            itemKey="Villkor från nya LW"
            itemValue={
              NewSupplierWebUsed != null ? NewSupplierWebUsed.toString() : null
            }
          />
        </KeyValueList>

        <NegotiationDetailsForm
          editMode={this.state.editMode}
          onChange={this.setDetails}
          metadata={this.props.metadata}
          contractType={ContractType}
          value={this.state.details}
        />

        <ListSubheader>
          Ange de villkor som gäller efter
          <strong> {deadlineDate.format('YYYY-MM-DD')}</strong>
        </ListSubheader>

        <AlignHorizontally>
          <TermsCard
            editMode={this.state.editMode}
            title="Icke-förhandlat"
            terms={this.state.nonNegotiatedValues || {}}
            initialTerms={NonNegotiatedTerms || {}}
            onChange={this.setNonNegotiatedTerms}
            onValidate={this.setNonNegotiatedValidationResult}
            deleteTerms={this.deleteTerms}
            fields={adminFieldsNonNegotiated}
            selectTerms={this.selectTerms}
            state={State}
            contractTermsId={NonNegotiatedTerms && NonNegotiatedTerms.Id}
            negotiationId={this.props.negotiation.Id}
            selectedAt={NonNegotiatedTerms && NonNegotiatedTerms.SelectedAt}
            showSelectedAt={showSelectedAt}
            notProvideTerms={this.state.noNonNegotiated}
            onChangeNotProvideTerms={this.setNoNonNegotiated}
            negotiationDeadlineDate={deadlineDate.format('YYYY-MM-DD')}
          />

          <React.Fragment>
            <TermsCard
              editMode={this.state.editMode}
              title="Erbjudande"
              terms={this.state.offerValues || {}}
              initialTerms={Offer || {}}
              onChange={this.setOfferTerms}
              onValidate={this.setOfferValidationResult}
              deleteTerms={this.deleteTerms}
              fields={adminFieldsOffer}
              selectTerms={this.selectTerms}
              state={State}
              autoFocus={false}
              contractTermsId={Offer && Offer.Id}
              negotiationId={this.props.negotiation.Id}
              selectedAt={Offer && Offer.SelectedAt}
              showSelectedAt={showSelectedAt}
              notProvideTerms={this.state.noOffer}
              notProvideTermsReason={this.state.noOfferReason}
              onChangeNotProvideTerms={this.setNoOffer}
              negotiationDeadlineDate={deadlineDate.format('YYYY-MM-DD')}
              contracts={contracts}
              onChangeParentContract={this.onChangeParentContract}
              parentContractId={this.state.parentContractId}
              templates={this.props.contractTermsTemplates}
              setValuesFromTemplate={this.setValuesFromTemplate}
              selectedTemplate={this.state.selectedTemplate}
            />
          </React.Fragment>
        </AlignHorizontally>
        <FormGroup>
          <AlignHorizontally>
            <SelectOfferCategory
              disabled={this.props.negotiation.State !== 'SupplierResponded'}
              offer={this.props.negotiation.Offer}
              offerCategory={this.state.offerCategory}
              categories={offerCategories}
              onChange={this.setOfferCartegory}
            />
          </AlignHorizontally>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  disabled={[
                    'DecisionNonreceipt',
                    'Selected',
                    'Confirmed',
                    'ConfirmationNonreceipt',
                    'TermsRequested'
                  ].includes(this.props.negotiation.State)}
                  checked={this.state.generateAutoPO}
                  onChange={this.setGenerateAutoPO}
                  checkedIcon={<AddCircleOutlineIcon color={'primary'} />}
                />
              }
              label={
                this.state.generateAutoPO ? 'Med Auto-PO' : 'Ingen Auto-PO'
              }
            />

            <FormControlLabel
              control={
                <Switch
                  disabled={
                    [
                      'DecisionNonreceipt',
                      'Selected',
                      'Confirmed',
                      'ConfirmationNonreceipt'
                    ].includes(this.props.negotiation.State) ||
                    this.props.contract.Children.length > 0
                  }
                  value={State === 'SupplierResponded'}
                  checked={this.state.isSupplierResponse}
                  onChange={this.setSupplierResponse}
                  checkedIcon={<PausedIcon color={'primary'} />}
                />
              }
              label={this.state.isSupplierResponse ? 'Pausad 💤' : 'Pausa'}
            />
          </Box>
        </FormGroup>
        {NonNegotiatedTerms && Offer && !this.state.editMode && (
          <SavingsComponent sum={Savings} unit={calculatedCostUnit} />
        )}

        <Box style={{ marginTop: '1rem' }}>
          <AlignHorizontally>
            {showEditButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.setState({ editMode: true })}
                style={{ marginRight: '0.5rem' }}
                size="small"
              >
                <EditIcon />
                Redigera
              </Button>
            )}
            {(State === 'TermsRequested' || State === 'Selected') && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Button
                      variant="contained"
                      color="primary"
                      href={this.props.negotiation.SupplierWebUrl}
                      target="_blank"
                      size="small"
                    >
                      <OpenInNewIcon color={'inherit'} />
                      Öppna leverantörswebben
                    </Button>
                  }
                  label={''}
                />
              </FormGroup>
            )}
            {(State === 'SupplierResponded' || State === 'TermsReceived') &&
              !this.state.editMode && (
                <RequestCompletion
                  contractId={this.props.contractId}
                  color={'secondary'}
                  style={{ marginRight: '0.5rem' }}
                  negotiation={this.props.negotiation}
                  contractProvider={this.props.contractProvider}
                />
              )}
            <SendNegotiationProviderMessage
              negotiationId={this.props.negotiation.Id}
              label="Skicka leverantörsmail"
              style={{ marginRight: '0.5rem' }}
              templates={[
                'TermsRequest',
                'TermsRequestReminder',
                'RequestCompletion',
                'ConfirmationRequest',
                'ConfirmationRequestReminder',
                'NoOfferAndCompetition',
                'UserNotCustomerOfSupplier'
              ]}
            />
            <SendNegotiationCustomerMessage
              negotiationId={this.props.negotiation.Id}
              label="Skicka kundmail"
              style={{ marginRight: '0.5rem' }}
              templates={[
                'NegotiationStarted',
                'OfferReceived',
                'OfferReceivedReminder',
                'NoOffer',
                'NoInformationAndCompetition',
                'CustomerCreatedReminder'
              ]}
            />
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={this.improveContract}
            >
              <IconSend color={'inherit'} />
              Skicka till lista av förbättringsbara avtal
            </Button>
            <Button
              variant="contained"
              color="inherit"
              disabled={this.saveButtonDisabled()}
              onClick={this.handleUpdateNegotiationTerms}
              size="small"
            >
              <SaveIcon />
              Spara
            </Button>
            {(State === 'Selected' || State === 'ConfirmationNonreceipt') && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleConfirmTerms}
                disabled={this.state.editMode}
                size="small"
              >
                <ConfirmIcon color={'inherit'} />
                Leverantör bekräftar
              </Button>
            )}
          </AlignHorizontally>
        </Box>
      </Fragment>
    );
  }
}

Component.propTypes = {
  customer: PropTypes.object,
  contract: PropTypes.object,
  contractId: PropTypes.number,
  negotiation: PropTypes.object,
  metadata: PropTypes.object,
  contractProvider: PropTypes.string,
  dispatch: PropTypes.func,
  contractTermsTemplates: PropTypes.object,
  contractState: PropTypes.string
};

export const NegotiationView = connect(state => ({
  customer: state.customer,
  contract: state.contract,
  metadata: state.metadata,
  contractTermsTemplates: state.contractTermsTemplates
}))(withStyles(style)(Component));
