"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isFieldNullOrEmpty = isFieldNullOrEmpty;

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function isFieldNullOrEmpty(value) {
  if (_typeof(value) === 'object' && !Array.isArray(value)) {
    if (value === null || isFieldNullOrEmpty(value.Value) && !value.Infinity) {
      return true;
    }

    return false;
  }

  if (value === null) {
    return true;
  }

  if (value === '') {
    return true;
  }

  if (Array.isArray(value) === true && value.length === 0) {
    return true;
  }

  return false;
}