import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { search } from 'state/actions';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, Toolbar, Paper, Button } from 'pamind-ui';

export class Component extends React.Component {
  state = {
    query: ''
  };

  handleQueryChange = event => {
    this.setState({ query: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.dispatch(search(this.state.query));
  };

  render() {
    const isValid = this.state.query !== '';
    return (
      <form onSubmit={this.handleSubmit}>
        <Paper>
          <Toolbar>
            <TextField
              autoFocus
              id="standard-full-width"
              label="Sök"
              style={{ margin: 8 }}
              helperText="Sök efter personnummer, email, namn eller ärendenr"
              fullWidth
              margin="normal"
              disabled={this.props.isSearching}
              onChange={this.handleQueryChange}
              InputLabelProps={{
                shrink: true
              }}
            />
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="small"
              disabled={this.props.isSearching || !isValid}
            >
              <SearchIcon color={'inherit'} />
            </Button>
          </Toolbar>
        </Paper>
      </form>
    );
  }
}

Component.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func
};

export const SearchInputView = connect(state => ({
  isSearching: state.search.isSearching
}))(Component);
