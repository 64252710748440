import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Box, Chip } from 'pamind-ui';

import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { TextField } from 'material-ui';
import { Switch, FormControlLabel } from 'pamind-ui';

const style = theme => ({
  textField: {
    flex: 'auto',
    width: '100%'
  },
  editMode: {
    width: '50%'
  }
});
export class Component extends React.Component {
  render() {
    return (
      <Box style={{ marginBottom: '1rem' }}>
        {this.props.editMode && (
          <Box>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.props.notProvideTerms}
                    onChange={(e, value) => {
                      this.props.onChange({
                        notProvideTerms: value
                      });
                    }}
                  />
                }
                label={`Kan ej ge ${this.props.title}`}
              />

              {this.props.notProvideTerms && this.props.reason !== undefined && (
                <div>
                  <TextField
                    defaultValue={this.props.reason}
                    floatingLabelText="Anledning"
                    floatingLabelFixed={true}
                    multiLine={true}
                    rows={4}
                    maxRows={12}
                    fullWidth={true}
                    onChange={(e, value) =>
                      this.props.onChange({
                        notProvideTermsReason: value
                      })
                    }
                  />
                </div>
              )}
            </div>
          </Box>
        )}
        {!this.props.editMode && this.props.notProvideTerms && (
          <Box>
            <Chip
              icon={<ThumbDownIcon />}
              label={`Inget ${this.props.title}`}
              variant="outlined"
            />

            {this.props.notProvideTerms && this.props.reason !== undefined && (
              <Box padding={2}>{this.props.reason}</Box>
            )}
          </Box>
        )}
      </Box>
    );
  }
}
Component.propTypes = {
  onChange: PropTypes.func,
  notProvideTerms: PropTypes.bool,
  editMode: PropTypes.bool,
  reason: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.object
};

export const NotProvideTerms = withStyles(style)(Component);
