import React from 'react';
import { ContractsWithClosedNegotiation } from 'components/lists/ContractsWithClosedNegotiation';

export class ClosedNegotiationsList extends React.Component {
  render() {
    return (
      <div>
        <ContractsWithClosedNegotiation />
      </div>
    );
  }
}
