"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validation = void 0;

var _Helpers = require("../form/Helpers");

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var validation = {
  postnummer: RegExp(/^[0-9]{5}$/),
  lastFourCreditCardDigits: RegExp(/^[0-9]{4}$/),
  positiveDecimal: RegExp(/^\d*(\.\d*)?$/),
  integer: RegExp(/^(-?[1-9]+\d*)$|^0$/),
  positiveInteger: RegExp(/^([1-9]+\d*)$|^0$/),
  date: RegExp(/^\d{4}-(([1][0-2])|[0][1-9])-(([0-2][1-9])|[3][0-1]|20|10)$/),
  email: RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/ // eslint-disable-line no-useless-escape
  ),
  mobile: RegExp(/^((((00)|(\+?){1})46)|0)7[\d]{8}$/),
  name: RegExp(/^[a-zA-ZÅÄÖåäöÈÉéèËÜÏüëïÿ\s-]{1,128}$/),
  months: RegExp(/([12][0-9]{3})-(0[1-9]|1[0-2])/),
  registrationNumber: RegExp(/^[aA-zZ0-9]{6}$/),
  isFirstCharText: RegExp(/^[!^\w\s]/),
  socialSecurityNumber: {
    validationErrorMessage: 'Ogiltigt personnummer',
    test: function test(value) {
      if (!value) return false;
      var isValidsocialSecurityNumber = new RegExp(/^(19|20)?[0-9]{6}[- ]?[0-9]{4}$/);
      return isValidsocialSecurityNumber.test(value);
    }
  },
  bool: {
    test: function test(value) {
      if (typeof value === 'boolean') {
        return true;
      }

      return false;
    }
  },
  elFloatingPriceDate: {
    validationErrorMessage: 'Ange ert mest aktuella pris',
    test: function test(testDate) {
      var isValidFormat = new RegExp(/([12][0-9]{3})-(0[1-9]|1[0-2])/);
      var today = new Date().toLocaleDateString('sv-SE', {
        year: 'numeric',
        month: 'numeric'
      });
      if (!isValidFormat.test(testDate)) return false;

      if (new Date(today) > new Date(testDate)) {
        var dayInMiliseconds = 1000 * 60 * 60 * 24;
        var upperLimitForFloatingPriceAge = 90 * dayInMiliseconds;

        if (new Date(today) - new Date(testDate) < upperLimitForFloatingPriceAge) {
          return true;
        }
      }

      return false;
    }
  },
  todayAndForward: {
    validationErrorMessage: 'Angivet datum får inte ha passerat',
    test: function test(value) {
      if (new Date().getTime() <= new Date(value).getTime()) {
        return true;
      }

      return false;
    }
  },
  dateFromStartDate: {
    validationErrorMessage: 'För att ge kunden tid att svara får erbjudandets startdatum inte läggas tidigare än',
    test: function test(testDate, startDate) {
      if (!startDate) return;

      if ((0, _moment.default)(startDate) < (0, _moment.default)(testDate)) {
        return true;
      }

      return false;
    }
  },
  dateFromDeadline: {
    validationErrorMessage: 'Det verkar som att avtalet snart löper ut. Ange därför avtalsinformation och förfallodatum på kommande avtal istället (om sådan finns).',
    test: function test(testDate, startDate) {
      if (!startDate) return;

      if ((0, _moment.default)(startDate).add(15, 'days') < (0, _moment.default)(testDate)) {
        return true;
      }

      return false;
    }
  },
  dateRange: {
    validationErrorMessage: function validationErrorMessage(errorMessage) {
      return errorMessage ? errorMessage : 'Valt datum är utanför tillåtet datumspann.';
    },
    test: function test(testDate, startDate, minDays, maxDays) {
      var minDate = (0, _Helpers.addDaysToTodaysDate)(minDays).toLocaleDateString('sv-SE');
      var maxDate = (0, _Helpers.addDaysToTodaysDate)(maxDays).toLocaleDateString('sv-SE');

      if (minDate <= testDate && maxDate >= testDate) {
        return true;
      }

      return false;
    }
  },
  numberRange: {
    validationErrorMessage: 'Vald summa är utanför tillåtet spann.',
    test: function test(testValue, startDate, minValue, maxValue) {
      if (minValue <= testValue && maxValue >= testValue) {
        return true;
      }

      return false;
    }
  },
  streetname: {
    validationErrorMessage: 'Gatuadressen får inte innehålla siffror, de anges under Gatunummer',
    test: function test(value) {
      if (!value) return false;
      var isValidatedStreetname = new RegExp(/^((?![0-9]).)*$/);
      return isValidatedStreetname.test(value);
    }
  },
  noNumbers: {
    validationErrorMessage: 'Nummer är inte tillåtet i detta fält. Ange endast bokstäver.',
    test: function test(value) {
      if (!value) return false;
      var isValidatedNoNumbers = new RegExp(/^((?![0-9]).)*$/);
      return isValidatedNoNumbers.test(value);
    }
  },
  streetnumber: {
    validationErrorMessage: 'Gatunummer får endast vara nummer, eventuell port anges under Port.',
    test: function test(value) {
      if (!value) return false;
      var isValidatedStreetnumber = new RegExp(/^[0-9]*$/);
      return isValidatedStreetnumber.test(value);
    }
  }
};
exports.validation = validation;