import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProvider } from 'state/actions';
import { GeneratedForm } from 'domain-components';
import { getProviderFields } from 'services/domain';
import { Card, CardTitle, CardText } from 'material-ui/Card';
import { Button } from 'pamind-ui';
import { Typography } from 'pamind-ui';

export class Component extends React.Component {
  state = {
    values: {},
    validationResult: { isValid: false, isChanged: false }
  };

  handleCreate = data => {
    this.props.dispatch(createProvider(data));
  };

  handleLogoChange(files) {
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = () => {
      this.setState(state => ({
        values: {
          ...(state.values || {}),
          Logo: reader.result.substr(reader.result.indexOf(';base64,') + 8)
        }
      }));
    };
  }

  render() {
    return (
      <Card>
        <CardTitle title="Lägg till leverantör" />
        <CardText>
          <GeneratedForm
            values={this.state.values}
            view={getProviderFields(this.props.metadata)}
            onChange={values => {
              this.setState({ values });
            }}
            onValidate={validationResult => {
              this.setState({ validationResult });
            }}
          />
          <div>
            <Typography variant="h6" color="inherit" noWrap>
              Logga
            </Typography>
            <input
              type="file"
              onChange={e => this.handleLogoChange(e.target.files)}
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
          <Button
            disabled={
              !this.state.validationResult.isValid ||
              !this.state.validationResult.isChanged
            }
            onClick={() => {
              this.handleCreate(this.state.values);
            }}
            size="small"
          >
            Spara
          </Button>
        </CardText>
      </Card>
    );
  }
}

Component.propTypes = {
  dispatch: PropTypes.func,
  metadata: PropTypes.object
};

export const AddProvider = connect(state => ({
  metadata: state.metadata
}))(Component);
