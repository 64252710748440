import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchProviders } from 'state/actions';
import { Link } from 'react-router-dom';
import { Accordion } from 'pamind-ui';
import { AccordionSummary } from 'pamind-ui';
import { AccordionDetails } from 'pamind-ui';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { groupArrayBy } from 'components/utils/GroupArrayBy';
import { replaceId, routes } from 'paths';
import {
  Toolbar,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from 'pamind-ui';
import { Typography } from 'pamind-ui';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styles = theme => ({
  grow: {
    flexGrow: 1
  }
});

export class Component extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchProviders());
  }

  render() {
    if (!this.props.providers) {
      return null;
    }

    const providersByType = groupArrayBy(this.props.providers, 'ContractType');
    const { classes } = this.props;

    return (
      <div>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Leverantörer
          </Typography>
          <div className={classes.grow} />
          <Link to={routes.providers.add}>Skapa ny leverantör</Link>
        </Toolbar>
        <div>
          {Object.keys(providersByType).map(contractType => (
            <Accordion key={contractType}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" className={classes.heading}>
                  {contractType}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Namn</TableCell>
                        <TableCell align="right">Communication</TableCell>
                        <TableCell align="right">Terms</TableCell>
                        <TableCell align="right">Offers</TableCell>
                        <TableCell align="right">Cancellation</TableCell>
                        <TableCell align="right">AwardsLoyalty</TableCell>
                        <TableCell align="right">
                          Dagar till sista svarsdatum
                        </TableCell>
                        <TableCell align="right">
                          Dagar till deadline - default
                        </TableCell>
                        <TableCell align="right">
                          Dagar till deadline - ExpiringDiscount
                        </TableCell>{' '}
                        <TableCell align="right">
                          Dagar till deadline - ExpiringPlan
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {providersByType[contractType].map(provider => (
                        <TableRow hover tabIndex={-1} key={provider.id}>
                          <TableCell
                            component={Link}
                            to={replaceId(routes.providers.show, provider.Id)}
                          >
                            {provider.Name}
                          </TableCell>
                          <TableCell align="right">
                            {provider.AllowsCommunication && (
                              <CheckBoxIcon color={'inherit'} />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {provider.ProviderContractType.ProvidesTerms && (
                              <CheckBoxIcon color={'inherit'} />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {provider.ProviderContractType.ProvidesOffers && (
                              <CheckBoxIcon color={'inherit'} />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {provider.ProviderContractType
                              .AllowsCancellation && (
                              <CheckBoxIcon color={'inherit'} />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {provider.ProviderContractType.AwardsLoyalty && (
                              <CheckBoxIcon color={'inherit'} />
                            )}
                          </TableCell>

                          <TableCell align="right">
                            {provider.ProviderContractType.DaysToLastReplyDate
                              ? provider.ProviderContractType
                                  .DaysToLastReplyDate.Value
                              : 5}
                          </TableCell>
                          <TableCell align="right">
                            {provider.ProviderContractType.StartNegotiationDays
                              ? provider.ProviderContractType
                                  .StartNegotiationDays.Value
                              : this.props.metadata.contractTypes[contractType]
                                  .startNegotiationDays}
                          </TableCell>
                          <TableCell align="right">
                            {provider.ProviderContractType
                              .StartNegotiationDaysExpiringDiscount
                              ? provider.ProviderContractType
                                  .StartNegotiationDaysExpiringDiscount.Value
                              : this.props.metadata.contractTypes[contractType]
                                  .startNegotiationDaysExpiringDiscount}
                          </TableCell>
                          <TableCell align="right">
                            {provider.ProviderContractType
                              .StartNegotiationDaysExpiringPlan
                              ? provider.ProviderContractType
                                  .StartNegotiationDaysExpiringPlan.Value
                              : this.props.metadata.contractTypes[contractType]
                                  .startNegotiationDaysExpiringPlan}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    );
  }
}

Component.propTypes = {
  dispatch: PropTypes.func,
  metadata: PropTypes.object,
  providers: PropTypes.array,
  classes: PropTypes.object
};

export const ListProviders = connect(state => ({
  metadata: state.metadata,
  providers: state.providers
}))(withStyles(styles)(Component));
