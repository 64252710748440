import React from 'react';
import { fetchOtherContractsWithImages } from 'state/actions';
import { useDispatch, useSelector } from 'react-redux';
import { replaceId, routes } from 'paths';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from 'pamind-ui';
import { LocalTime } from 'domain-components';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
  theme => ({
    root: {
      '& td': {
        padding: 0
      },
      '& a': {
        color: theme.palette.common.black,
        display: 'block',
        padding: theme.spacing(4),
        textDecoration: 'none'
      }
    }
  }),
  { name: 'OtherContractsWithImagesList' }
);

export const OtherContractsWithImagesList = () => {
  const dispatch = useDispatch();
  const contracts = useSelector(state => state.otherContractsWithImages) || [];
  const classes = useStyles();

  React.useEffect(() => {
    dispatch(fetchOtherContractsWithImages());
  }, [dispatch]);

  return !!contracts.length ? (
    <Card className={classes.root}>
      <CardHeader title="Övriga kontrakt med bilder" />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Personnummer</TableCell>
              <TableCell>Leverantör</TableCell>
              <TableCell>Skapat datum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts.map((contract, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link to={replaceId(routes.contracts.show, contract.Id)}>
                    {contract.CustomerSecurityNumber}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={replaceId(routes.contracts.show, contract.Id)}>
                    {contract.ProviderName}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={replaceId(routes.contracts.show, contract.Id)}>
                    <LocalTime time={contract.DateCreated} />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  ) : null;
};
