export const replaceId = (path, id) => path.replace(':id', id);

export const routes = {
  dashboard: '/',
  search: '/search',
  customerList: '/minimalCustomerInfo',
  customers: {
    show: '/customers/:id',
    edit: '/customers/:id/edit'
  },
  contracts: {
    show: '/contracts/:id'
  },
  providers: {
    add: '/providers/add',
    edit: '/providers/:id/edit',
    list: '/providers',
    show: '/providers/:id'
  },
  administrators: {
    add: '/administrators/add',
    edit: '/administrators/:id/edit',
    list: '/administrators',
    show: '/administrators/:id'
  },
  jobs: {
    list: '/jobs'
  },
  lists: {
    closedNegotiations: '/lists/closedNegotiations',
    pausedNegotiations: '/lists/pausedNegotiations',
    unpausedNegotiations: '/lists/unpausedNegotiations',
    unselectedOffers: '/lists/unselectedOffers',
    otherContractsWithImages: '/lists/otherContractsWithImages'
  },
  statistics: '/statistics',
  templates: {
    list: '/templates',
    add: '/templates/add',
    show: '/templates/:id'
  },
  messageTemplates: {
    list: '/messageTemplates'
  }
};
