import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField } from 'pamind-ui';
import { MenuItem } from 'pamind-ui';
import { Select } from 'pamind-ui';
import { Switch } from 'pamind-ui';
import { Button } from 'pamind-ui';
import { updateContract } from 'state/actions';
import { GeneratedForm } from 'domain-components';
import { selectors } from 'domain-methods';
import { getContractTypeContractDetails } from 'services/domain';
import { FormGroup } from 'pamind-ui';
import { FormControlLabel } from 'pamind-ui';
import { Typography } from 'pamind-ui';
import { Menu } from 'pamind-ui';
import { AlignHorizontally } from 'components/ui/AlignHorizontally';

export class Component extends React.Component {
  state = {
    attributes: {},
    isNonNegotiable: false,
    contractDetails: '',
    attributesValidationResult: { isValid: false, isChanged: false },
    confirmationPopup: null
  };

  componentDidMount() {
    this.setState({
      attributes: this.props.contract.Attributes,
      isNonNegotiable: this.props.contract.IsNonNegotiable,
      contractDetails: this.props.contract.ContractDetails
    });
  }

  handleUpdateContract = () => {
    this.props.dispatch(
      updateContract(
        this.props.contract.Id,
        this.props.contract.Preferences,
        this.state.attributes,
        this.state.isNonNegotiable,
        this.state.contractDetails
      )
    );
  };

  handleClick = event => {
    this.setState({ confirmationPopup: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ confirmationPopup: null });
  };

  handleIsNonNegotiableChange = (e, value) => {
    this.setState({
      isNonNegotiable: value
    });
  };

  handleContractAttributesChange = values => {
    this.setState({
      attributes: values
    });
  };

  handleDetailsChange = option => {
    this.setState({
      contractDetails: option.text
    });
  };

  saveContractButtonDisabled = () => {
    if (
      this.state.isNonNegotiable === this.props.contract.IsNonNegotiable &&
      (this.state.contractDetails === null ||
        this.state.contractDetails === '') ===
        (this.props.contract.ContractDetails === null ||
          this.props.contract.ContractDetails === '') &&
      (!this.state.attributesValidationResult.isValid ||
        !this.state.attributesValidationResult.isChanged)
    ) {
      return true;
    }
    return false;
  };

  render() {
    const contractDetailsOptions = getContractTypeContractDetails(
      this.props.metadata,
      this.props.contract.ContractType
    );
    const { confirmationPopup } = this.state;
    return (
      <div>
        {!!Object.keys(this.props.contract.Attributes).length && (
          <GeneratedForm
            view={this.props.attributeFields}
            onChange={this.handleContractAttributesChange}
            initialValues={this.props.contract.Attributes}
            values={this.state.attributes}
            onValidate={validationResult => {
              this.setState({ attributesValidationResult: validationResult });
            }}
          />
        )}
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                value={this.props.contract.IsNonNegotiable}
                onChange={this.handleIsNonNegotiableChange}
                style={{ marginRight: '1rem' }}
              />
            }
            label="Ej förhandlingsbar"
          />
        </FormGroup>
        <Select
          disabled={!this.state.isNonNegotiable}
          onChange={e => {
            this.handleDetailsChange(
              contractDetailsOptions.find(
                option => option.text === e.target.value
              )
            );
          }}
        >
          {contractDetailsOptions !== null &&
            contractDetailsOptions !== undefined &&
            contractDetailsOptions.map((item, index) => (
              <MenuItem key={index} value={item.text}>
                {item.label}
              </MenuItem>
            ))}
        </Select>
        <TextField
          name="contractDetails"
          floatingLabelText="Detaljer"
          value={this.state.contractDetails}
          multiLine={true}
          rows={3}
          fullWidth={true}
          disabled={!this.state.isNonNegotiable}
          onChange={(e, value) => this.handleDetailsChange(value)}
        />
        <Button
          color="primary"
          variant="contained"
          aria-owns={confirmationPopup ? 'confirmationPopup' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          disabled={this.saveContractButtonDisabled()}
          style={{ marginRight: '1rem' }}
          size="small"
        >
          Spara
        </Button>
        {confirmationPopup && (
          <Menu
            id="confirmationPopup"
            anchorEl={confirmationPopup}
            open={Boolean(confirmationPopup)}
            onClose={this.handleClose}
            style={{ margin: 20, width: '80%' }}
          >
            <Typography variant="h3" style={{ margin: 20 }}>
              Är du säker på att du vill fortsätta?
            </Typography>
            <AlignHorizontally>
              <Button
                color="inherit"
                variant="text"
                size="small"
                onClick={this.handleClose}
              >
                STÄNG
              </Button>
              <Button
                color="primary"
                variant="text"
                size="small"
                onClick={() => {
                  this.handleUpdateContract();
                }}
              >
                FORTSÄTT
              </Button>
            </AlignHorizontally>
          </Menu>
        )}
      </div>
    );
  }
}

Component.propTypes = {
  metadata: PropTypes.object,
  value: PropTypes.string,
  contract: PropTypes.object,
  dispatch: PropTypes.func,
  attributeFields: PropTypes.array
};

export const EditContract = connect((state, props) => ({
  metadata: state.metadata,
  contract: state.contract,
  attributeFields: selectors.getContractAttributeFieldsFromMetadata(
    state.metadata,
    props.contract.ContractType
  )
}))(Component);
