import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  deleteNegotiation,
  createNegotiation,
  updateNegotiation
} from 'state/actions';
import { replaceId, routes } from 'paths';
import { GeneratedForm } from 'domain-components';
import { KeyValueListItem, KeyValueList } from 'components/ui/KeyValueList';
import { getCreateNegotiationFields } from 'services/domain';
import IconUpcomming from '@mui/icons-material/Update';
import IconDelete from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { ConfirmationDialog } from 'domain-components';
import { Card } from 'pamind-ui';
import { CardContent } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { CardActions } from 'pamind-ui';
import { Switch } from 'pamind-ui';
import { FormControl } from 'pamind-ui';
import { FormGroup } from 'pamind-ui';
import { FormControlLabel } from 'pamind-ui';
import { Button, Typography } from 'pamind-ui';
import moment from 'moment';

export class Component extends React.Component {
  state = {
    showForm: false,
    values: {},
    validationResult: { isValid: false, isChanged: false },
    agreedTermsExist: false,
    isChanged: false
  };

  handleCreate = data => {
    data.ContractId = this.props.contractId;
    this.props.dispatch(
      createNegotiation(
        data,
        replaceId(routes.contracts.show, this.props.contractId)
      )
    );
  };

  handleDelete = () => {
    this.props.dispatch(
      deleteNegotiation(
        Number(this.props.negotiation.Id),
        Number(this.props.contractId)
      )
    );
  };

  saveButtonDisabled = () => {
    return !this.state.isChanged;
  };

  setAgreedTermsExist = (e, value) => {
    this.setState({ agreedTermsExist: value });
    this.setState({ isChanged: true });
  };

  handleUpdateNegotiation = data => {
    this.props.negotiation.AgreedTermsExist = this.state.agreedTermsExist;
    this.props.negotiation.ContractId = this.props.contractId;
    this.props.dispatch(
      updateNegotiation(
        Number(this.props.negotiation.Id),
        this.props.negotiation,
        replaceId(routes.contracts.show, this.props.contractId)
      )
    );
  };

  renderViewNegotiation() {
    const negotiation = this.props.negotiation;

    const startDate = moment(negotiation.StartDate, 'YYYY-MM-DD');
    const deadlineDate = moment(negotiation.Deadline, 'YYYY-MM-DD');
    return (
      <Card>
        <CardHeader
          title={<Typography variant="h4">Kommande förhandling</Typography>}
          subheader={negotiation.Type}
          avatar={<IconUpcomming color={'primary'} />}
        />
        <CardContent>
          <KeyValueList>
            <KeyValueListItem
              itemKey="Startdatum"
              itemValue={
                startDate.isValid() &&
                `${startDate.format('YYYY-MM-DD')} (${deadlineDate.diff(
                  startDate,
                  'days'
                )} till deadline)`
              }
            />
            <KeyValueListItem
              itemKey="Deadline"
              itemValue={deadlineDate.format('YYYY-MM-DD')}
            />
          </KeyValueList>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      this.state.isChanged
                        ? this.state.agreedTermsExist
                        : negotiation.AgreedTermsExist
                    }
                    onChange={this.setAgreedTermsExist}
                    color="primary"
                  />
                }
                label="Redan överenskomment med kund"
              />
            </FormGroup>
          </FormControl>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            disabled={this.saveButtonDisabled()}
            onClick={() => {
              this.handleUpdateNegotiation(this.state.values);
            }}
          >
            <SaveIcon />
            Spara
          </Button>

          <ConfirmationDialog
            label="Radera kommande förhandling"
            onConfirm={this.handleDelete}
            confirmLabel="Radera"
            size="small"
            variant="outlined"
            dialogTitle="Radera kommande förhandling"
            dialogMessage="Är du säker på att du vill radera denna kommande förhandling?."
            icon={<IconDelete color={'primary'} />}
          />
        </CardActions>
      </Card>
    );
  }

  renderForm() {
    return (
      <Card>
        <CardHeader
          title={<Typography variant="h4">Kommande förhandling</Typography>}
          subheader="Ange datum"
          avatar={<IconUpcomming color={'primary'} />}
        />
        <CardContent>
          <GeneratedForm
            values={this.state.values}
            view={getCreateNegotiationFields()}
            onChange={values => {
              this.setState({ values });
            }}
            onValidate={validationResult => {
              this.setState({ validationResult });
            }}
          />
          <Button
            size="small"
            color="primary"
            disabled={
              !this.state.validationResult.isValid ||
              !this.state.validationResult.isChanged
            }
            onClick={() => {
              this.handleCreate(this.state.values);
            }}
          >
            Spara
          </Button>
        </CardContent>
      </Card>
    );
  }

  renderAddButton() {
    return (
      <Card>
        <CardHeader
          title={<Typography variant="h4">Kommande förhandling</Typography>}
          subheader="Det finns ingen kommande förhandling på detta avtal än"
          avatar={<IconUpcomming color={'primary'} />}
        />
        <CardActions>
          <Button
            variant="outlined"
            onClick={() => this.setState({ showForm: true })}
            color="primary"
            size="small"
          >
            Skapa en kommande förhandling
          </Button>
        </CardActions>
      </Card>
    );
  }

  render() {
    if (this.props.negotiation) {
      return this.renderViewNegotiation();
    }

    if (this.state.showForm) {
      return this.renderForm();
    }

    return this.renderAddButton();
  }
}

Component.propTypes = {
  contractId: PropTypes.number,
  negotiation: PropTypes.object,
  dispatch: PropTypes.func
};

export const UpcomingNegotiation = connect()(Component);
