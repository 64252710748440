import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createAdministrator } from 'state/actions';
import { GeneratedForm } from 'domain-components';
import { getAdministratorFields } from 'services/domain';
import { Card } from 'pamind-ui';
import { CardContent } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { Button } from 'pamind-ui';

export class Component extends React.Component {
  state = {
    values: {},
    validationResult: { isValid: false, isChanged: false }
  };

  handleCreate = data => {
    this.props.dispatch(createAdministrator(data));
  };

  render() {
    return (
      <Card>
        <CardHeader title="Lägg till administratör" />
        <CardContent>
          <GeneratedForm
            values={this.state.values}
            view={getAdministratorFields()}
            onChange={values => {
              this.setState({ values });
            }}
            onValidate={validationResult => {
              this.setState({ validationResult });
            }}
          />
          <Button
            disabled={
              !this.state.validationResult.isValid ||
              !this.state.validationResult.isChanged
            }
            onClick={() => {
              this.handleCreate(this.state.values);
            }}
            size="small"
          >
            Spara
          </Button>
        </CardContent>
      </Card>
    );
  }
}

Component.propTypes = {
  dispatch: PropTypes.func
};

export const AddAdministrator = connect()(Component);
