import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DismissableError } from 'components/ui/DismissableError';
import { dismissError } from 'state/actions';

export class Component extends React.Component {
  handleDismiss = () => {
    this.props.dispatch(dismissError());
  };

  render() {
    return (
      <DismissableError
        dismissError={this.handleDismiss}
        errorMessage="Något är trasigt!"
        open={this.props.error.show}
      />
    );
  }
}

Component.propTypes = {
  error: PropTypes.shape({
    show: PropTypes.bool
  }),
  dispatch: PropTypes.func
};

export const Error = connect(state => {
  return { error: state.error };
})(Component);
