import {
  DISMISS_ERROR,
  DISMISS_NOTIFICATION,
  API_ACTION_TYPES,
  STATUS,
  THEME
} from 'state/actions';

function progress(state = { isFetching: false }, action) {
  if (Object.values(API_ACTION_TYPES).includes(action.type)) {
    return { isFetching: action.status === STATUS.REQUESTED };
  }

  return state;
}

function error(state = { show: false }, action) {
  if (Object.values(API_ACTION_TYPES).includes(action.type)) {
    return { show: action.status === STATUS.FAILURE };
  }

  if (action.type === DISMISS_ERROR) {
    return { show: false };
  }

  return state;
}

function notification(state = { show: false }, action) {
  if (
    Object.values(API_ACTION_TYPES).includes(action.type) &&
    action.status === STATUS.SUCCESS &&
    action.notification
  ) {
    return { show: true };
  }

  if (action.type === DISMISS_NOTIFICATION) {
    return { show: false };
  }

  return state;
}

function metadata(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_METADATA) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }

  return state;
}

function customer(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_CUSTOMER) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }

  return state;
}

function providers(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_PROVIDERS) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }

  return state;
}

function contractTermsTemplates(state = null, action) {
  if (action.type === API_ACTION_TYPES.GET_OFFER_TEMPLATES) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }

  return state;
}

function messaging(state = {}, action) {
  if (
    action.type === API_ACTION_TYPES.FETCH_MESSAGES &&
    action.status === STATUS.SUCCESS
  ) {
    let messages = action.data;
    const metadata = state.metadata || {};
    messages.forEach(m => {
      const id = m.Id;
      metadata[id] = metadata[id] || {};
      metadata[id].processed = false;
      calculateRemainingMessageMetadata(id, metadata);
    });
    return {
      messages,
      metadata,
      shouldRenderEmptyState: shouldRenderEmptyStateForMessagingList(
        messages,
        metadata
      )
    };
  }

  if (action.type === API_ACTION_TYPES.PROCESS_MESSAGE) {
    const metadata = state.metadata;
    if (action.status === STATUS.SUCCESS) {
      metadata[action.id].processing = false;
      metadata[action.id].error = false;
      metadata[action.id].processed = true;
    } else if (action.status === STATUS.FAILURE) {
      metadata[action.id].processing = false;
      metadata[action.id].error = true;
      metadata[action.id].processed = false;
    } else if (action.status === STATUS.REQUESTED) {
      metadata[action.id].processing = true;
    }
    calculateRemainingMessageMetadata(action.id, metadata);
    return {
      messages: state.messages,
      metadata,
      shouldRenderEmptyState: shouldRenderEmptyStateForMessagingList(
        state.messages,
        metadata
      )
    };
  }

  return state;
}

const calculateRemainingMessageMetadata = (messageId, metadata) => {
  metadata[messageId] = metadata[messageId] || {};
  metadata[messageId].processed = metadata[messageId].processed || false;
  metadata[messageId].error = metadata[messageId].error || false;
  metadata[messageId].processing = metadata[messageId].processing || false;
  metadata[messageId].canProcess =
    !metadata[messageId].processed && !metadata[messageId].processing;
  metadata[messageId].shouldRenderErrorIcon =
    metadata[messageId].error && !metadata[messageId].processing;
};

const shouldRenderEmptyStateForMessagingList = (messages, metadata) => {
  if (!messages) return true;
  return messages.every(m => metadata[m.Id].processed);
};

function provider(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_PROVIDER) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }

  return state;
}

function contract(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_CONTRACT) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function contractsWithClosedNegotiation(state = null, action) {
  if (
    action.type === API_ACTION_TYPES.FETCH_CONTRACTS_WITH_CLOSED_NEGOTIATION
  ) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function contractsWithPausedNegotiation(state = null, action) {
  if (
    action.type === API_ACTION_TYPES.FETCH_CONTRACTS_WITH_PAUSED_NEGOTIATION
  ) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function contractsWithUnPausedNegotiation(state = null, action) {
  if (
    action.type === API_ACTION_TYPES.FETCH_CONTRACTS_WITH_UNPAUSED_NEGOTIATION
  ) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function contractsWithUnselectedOffers(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_CONTRACTS_WITH_UNSELECTED_OFFERS) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function otherContractsWithImages(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_MANUAL_CONTRACTS_WITH_IMAGES) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function statistics(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_STATISTICS) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function translations(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_TRANSLATIONS) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function jobs(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_JOBS) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function job(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_JOBS) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function administrators(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_ADMINISTRATORS) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function administrator(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_ADMINISTRATOR) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function search(state = { isSearching: false, data: null }, action) {
  if (action.type === API_ACTION_TYPES.SEARCH) {
    const isSearching = action.status === STATUS.REQUESTED;
    const data = action.status === STATUS.SUCCESS ? action.data : null;

    return { isSearching, data };
  }

  return state;
}

function theme(state = null, action) {
  if (!state) {
    if (
      /^admin.(staging|test-[^.]+)\.pamind\.se/.test(window.location.hostname)
    ) {
      state = 'coolBanana';
    } else {
      state = 'light';
    }
  }

  if (action.type === THEME.CHANGE) {
    return action.theme;
  }
  return state;
}

function templates(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_TEMPLATES) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

function messageTemplates(state = null, action) {
  if (action.type === API_ACTION_TYPES.FETCH_MESSAGETEMPLATES) {
    if (action.status === STATUS.SUCCESS) {
      return action.data;
    } else {
      return null;
    }
  }
  return state;
}

export const reducers = {
  administrator,
  administrators,
  contract,
  contractTermsTemplates,
  contractsWithClosedNegotiation,
  contractsWithPausedNegotiation,
  contractsWithUnPausedNegotiation,
  contractsWithUnselectedOffers,
  customer,
  error,
  job,
  jobs,
  messageTemplates,
  messaging,
  metadata,
  notification,
  otherContractsWithImages,
  progress,
  provider,
  providers,
  search,
  statistics,
  templates,
  theme,
  translations
};
