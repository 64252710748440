import { setLoggedOut } from 'services/auth';

function getURL(endpoint, hasParam) {
  return `/api/${endpoint}${hasParam ? '&' : '?'}v=${
    process.env.REACT_APP_VERSION
  }`;
}

function getDefaultHeaders() {
  return {
    'Content-Type': 'application/json'
  };
}

function handleResponse(response) {
  if (response.status >= 200 && response.status < 300) {
    if (response.status === 204) {
      return Promise.resolve({});
    }
    return response.json();
  } else if (response.status === 401 || response.status === 403) {
    setLoggedOut();
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

function GET(url) {
  return fetch(url, {
    method: 'GET',
    headers: getDefaultHeaders()
  }).then(handleResponse);
}

function PUT(url, body) {
  return fetch(url, {
    method: 'PUT',
    headers: getDefaultHeaders(),
    body: JSON.stringify(body)
  }).then(handleResponse);
}

function POST(url, body) {
  return fetch(url, {
    method: 'POST',
    headers: getDefaultHeaders(),
    body: JSON.stringify(body)
  }).then(handleResponse);
}

function DELETE(url) {
  return fetch(url, {
    method: 'DELETE',
    headers: getDefaultHeaders()
  }).then(handleResponse);
}

export function getMetadata() {
  return GET(getURL(`admin/metadata`));
}

export function login(email, password) {
  return POST(getURL('a/administrator/login'), { email, password });
}

export function search(query) {
  const pageSize = 30;
  const page = 1;
  query = encodeURIComponent(query);
  return GET(
    getURL(
      `a/administrator/search?query=${query}&pageSize=${pageSize}&page=${page}`,
      true
    )
  );
}

export function getCustomer(id) {
  return GET(getURL(`a/customer/${id}`));
}

export function updateCustomer(customer) {
  return PUT(getURL(`a/customer`), customer);
}

export function deleteCustomer(id) {
  return DELETE(getURL(`a/customer?id=${id}`, true));
}

export function getContract(id) {
  return GET(getURL(`a/contract/${id}`));
}

export function getContractsWithClosedNegotiation() {
  return GET(getURL(`a/contract/contractsWithClosedNegotiation`));
}

export function getContractsWithPausedNegotiation() {
  return GET(getURL(`a/contract/contractsWithPausedNegotiation`));
}

export function getContractsWithUnPausedNegotiation() {
  return GET(getURL(`a/contract/contractsWithUnPausedNegotiation`));
}

export function getContractsWithUnselectedOffers() {
  return GET(getURL(`a/contract/contractsWithUnselectedOffers`));
}

export function getOtherContractsWithImages() {
  return GET(getURL(`a/contract/otherContractsWithImages`));
}

export function getStatistics() {
  return GET(getURL(`a/statistics/statisticsNewContractTypes`));
}

export function getTranslations() {
  return GET(getURL(`a/translations/sv-SE`));
}

export function updateTranslations(json) {
  return POST(getURL(`a/translations/sv-SE`), json);
}

export function improveContract(id) {
  return POST(getURL(`a/contract/${id}/improve`));
}

export function deleteContract(id) {
  return DELETE(getURL(`a/contract/${id}`));
}

export function updateContract(
  id,
  preferences,
  attributes,
  isNonNegotiable,
  contractDetails,
  contractState
) {
  return PUT(getURL(`a/contract/${id}`), {
    preferences: preferences,
    attributes: attributes,
    isNonNegotiable: isNonNegotiable,
    contractDetails: contractDetails,
    state: contractState
  });
}

export function createNegotiation(negotiationData) {
  return POST(getURL(`a/negotiation`), negotiationData);
}
export function updateNegotiation(id, negotiationData) {
  return POST(getURL(`a/negotiation/${id}`), negotiationData);
}

export function deleteNegotiation(id) {
  return DELETE(getURL(`a/negotiation/${id}`));
}

export function requestCompletion(negotiationId, message) {
  return POST(getURL(`a/negotiation/${negotiationId}/requestCompletion`), {
    message: message
  });
}

export function sendNegotiationProviderMessage(negotiationId, template) {
  return POST(
    getURL(`a/negotiation/${negotiationId}/sendNegotiationProviderMessage`),
    {
      template: template
    }
  );
}

export function sendNegotiationCustomerMessage(negotiationId, template) {
  return POST(
    getURL(`a/negotiation/${negotiationId}/sendNegotiationCustomerMessage`),
    {
      template: template
    }
  );
}

export function sendNegotiationToAddressMessage(
  negotiationId,
  template,
  toAddress,
  ccCustomer
) {
  return POST(
    getURL(`a/negotiation/${negotiationId}/sendNegotiationToAddressMessage`),
    {
      template: template,
      toAddress: toAddress,
      ccCustomer: ccCustomer
    }
  );
}

export function sendCustomerMessage(customerId, template) {
  return POST(getURL(`a/customer/${customerId}/sendCustomerMessage`), {
    template: template
  });
}

export function sendCustomerNotification(email, firstName, lastName, template) {
  return POST(getURL(`a/customer/sendCustomerNotification`), {
    template: template,
    email: email,
    firstName: firstName,
    lastName: lastName
  });
}

export function updateNegotiationTerms(
  id,
  nonNegotiatedTerms,
  offer,
  details,
  isSupplierResponse,
  noNonNegotiated,
  noOffer,
  noOfferReason,
  parentContractId,
  generateAutoPO,
  templateId
) {
  return POST(getURL(`a/negotiation/${id}/updateTerms`), {
    NonNegotiatedTerms: nonNegotiatedTerms,
    Offer: offer,
    Details: details,
    IsSupplierResponse: isSupplierResponse,
    NoNonNegotiated: noNonNegotiated,
    NoOffer: noOffer,
    NoOfferReason: noOfferReason,
    ParentContractId: parentContractId,
    GenerateAutoPo: generateAutoPO,
    TemplateId: templateId
  });
}

export function getProviders() {
  return GET(getURL(`a/provider`));
}

export function getProvider(id) {
  return GET(getURL(`a/provider/${id}`));
}

export function deleteProvider(id) {
  return DELETE(getURL(`a/provider/${id}`));
}

export function createProvider(provider) {
  return POST(getURL(`a/provider`), provider);
}

export function updateProvider(providerId, provider) {
  return PUT(getURL(`a/provider/${providerId}`), provider);
}

export function createContract(contract) {
  return POST(getURL(`a/contract`), contract);
}

export function getUnsentMessages() {
  return GET(getURL(`messaging/Unsent`));
}

export function processMessage(id) {
  return POST(getURL(`messaging/Process/${id}`));
}

export function getJobs() {
  return GET(getURL(`a/job`));
}

export function startJob(id) {
  return GET(getURL(`a/job/start/${id}`));
}

export function cancelJob(id) {
  return GET(getURL(`a/job/cancel/${id}`));
}

export function getAdministrators() {
  return GET(getURL(`a/administrator`));
}

export function getAdministrator(id) {
  return GET(getURL(`a/administrator/${id}`));
}

export function deleteAdministrator(id) {
  return DELETE(getURL(`a/administrator/${id}`));
}

export function createAdministrator(administrator) {
  return POST(getURL(`a/administrator`), administrator);
}

export function updateAdministrator(administrator) {
  return PUT(getURL(`a/administrator/${administrator.Id}`), administrator);
}

export function selectTerms(negotiationId, contractTermsId) {
  return POST(
    getURL(
      `a/negotiation/selectTerms?negotiationId=${negotiationId}&contractTermsId=${contractTermsId}`,
      true
    )
  );
}

export function deleteTerms(contractTermsId) {
  return POST(getURL(`a/negotiation/deleteTerms`), contractTermsId);
}

export function setNotCustomer(negotiationId) {
  return POST(getURL(`a/negotiation/setNotCustomer`), negotiationId);
}

export function confirmTerms(negotiationId) {
  return POST(getURL(`a/negotiation/${negotiationId}/setState`), {
    state: 'Confirmed'
  });
}

export function getContractDocuments(contractId) {
  return GET(getURL(`a/contract/${contractId}/documents`));
}

export function updateNegotiationDates(negotiationId, numberOfDays) {
  return POST(
    getURL(`a/negotiation/${negotiationId}/updateDates`),
    Number(numberOfDays)
  );
}

export function getOfferTemplates(contractId) {
  return GET(getURL(`a/contract/${contractId}/termsTemplates`));
}

export function getTemplates() {
  return GET(getURL(`a/template`));
}

export function updateTemplate(id, template) {
  return PUT(getURL(`a/template/${id}`), template);
}

export function getMessageTemplates() {
  return GET(getURL('a/messageTemplates'));
}
