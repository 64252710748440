import React, { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from 'pamind-ui';
import { PageHeader } from 'domain-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMessageTemplates } from 'state/actions';
import { MessageTemplatesListItem } from './MessageTemplatesListItem';

const tableHead = [
  {
    label: 'Namn',
    id: 'Name',
    disablePadding: true
  },
  {
    label: 'Mail',
    id: 'Mail',
    disablePadding: true
  },
  {
    label: 'Notis',
    id: 'Notification',
    disablePadding: true
  },
  {
    label: 'Sms',
    id: 'Sms',
    disablePadding: true
  },
  {
    label: 'Skickas',
    id: 'SendAt',
    disablePadding: true
  }
];

export const MessageTemplatesList = () => {
  const [templateList, setTemplateList] = useState(null);
  const dispatch = useDispatch();
  const messageTemplates = useSelector(state => state.messageTemplates);

  useEffect(() => {
    dispatch(fetchMessageTemplates());
  }, [dispatch]);

  useEffect(() => {
    setTemplateList(messageTemplates);
  }, [messageTemplates]);

  return (
    <Box paddingBlock={8}>
      <PageHeader header="Mallar för mail/notis/sms" reduceBottomMargin />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          alignItems: 'center',
          width: '100%',
          gap: 4,
          paddingTop: 6
        }}
      />
      <Divider sx={{ marginBlock: 4 }} />
      <Table>
        <TableHead>
          <TableRow>
            {tableHead.map(item => (
              <TableCell sx={{ fontWeight: 'bold' }}>{item.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {templateList?.Templates?.map(listItem => {
            return (
              <MessageTemplatesListItem key={listItem.Id} listItem={listItem} />
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
