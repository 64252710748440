import { Box, Button } from 'pamind-ui';

export const CustomersList = () => {
  const onButtonClick = () => {
    fetch('/api/a/customer/minimalCustomerInfo').then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'CustomerList.csv';
        alink.click();
      });
    });
  };

  return (
    <Box style={{ marginTop: '2rem' }}>
      <Button onClick={() => onButtonClick()}>Ladda ner kundlista</Button>
    </Box>
  );
};
