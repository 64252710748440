"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.copyTextToClipboard = void 0;

// The original version of this code was from https://stackoverflow.com/a/30810322/722201 // Anton Lejon 2020-02-05

/**
 * Attempts to copy text to the clipboard, and then runs success or fail callback. There are some browsers and devices that do not support this, but the function attempts two different methods and should cover all modern devices.
 *
 * @param {string} text The text to be copied to the user's clipboard
 * @param {function} [successCallback=() => {}] Callback method when the copying is successful. No arguments are passed to this method. (optional)
 * @param {function} [failCallback=error => {}] Callback method for when the copying failed. If there's an error it will be passed along as the first argument. The error might be undefined. (optional)
 */
var copyTextToClipboard = function copyTextToClipboard(url) {
  var successCallback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
  var failCallback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (error) {};

  if (window.nativeapp && typeof window.nativeapp.share === 'function') {
    window.nativeapp.share(url);
    return;
  }

  if (navigator.share) {
    navigator.share({
      url: url
    }).then(function () {
      return successCallback();
    })["catch"](function (error) {
      return failCallback(error);
    });
    return;
  }

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(url, successCallback, failCallback);
    return;
  }

  navigator.clipboard.writeText(url).then(successCallback, failCallback);
};

exports.copyTextToClipboard = copyTextToClipboard;

function fallbackCopyTextToClipboard(url, successCallback, failCallback) {
  var textArea = document.createElement('textarea');
  textArea.value = url;
  textArea.style.position = 'fixed'; //avoid scrolling to bottom

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');

    if (successful) {
      successCallback();
    } else {
      failCallback();
    }
  } catch (error) {
    failCallback(error);
  }

  document.body.removeChild(textArea);
}