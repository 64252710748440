import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomer } from 'state/actions';
import { Box, Typography, Link } from 'pamind-ui';
import { useParams } from 'react-router';
import { Loader } from 'domain-components';
import { EditCustomerForm } from './EditCustomerForm';

export const EditCustomer = () => {
  const customer = useSelector(state => state.customer);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchCustomer(Number(id)));
  }, [dispatch, id]);

  if (!customer) return <Loader />;

  return (
    <Box>
      <Box sx={{ paddingBlock: 8, paddingInline: 2 }}>
        <Typography variant="h2">{`${customer?.FirstName} ${customer?.LastName}`}</Typography>
        {customer?.Email && (
          <Link href={`mailto:${customer?.Email}`} variant="body2">
            {customer?.Email}
          </Link>
        )}
      </Box>
      <EditCustomerForm customer={customer} />
    </Box>
  );
};
