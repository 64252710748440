import React from 'react';
import { FormGroup, Box, TextField, Button } from 'pamind-ui';
import { LabeledCheckbox, dataTypes } from 'domain-components';
import PropTypes from 'prop-types';
import { updateCustomer } from 'state/actions';
import { replaceId, routes } from 'paths';
import { useDispatch } from 'react-redux';

const fields = [
  {
    name: 'FirstName',
    type: 'text',
    label: 'Förnamn',
    isValid: true,
    isAddressField: false,
    helperText: 'Felaktigt format'
  },
  {
    name: 'LastName',
    type: 'text',
    label: 'Efternamn',
    isValid: true,
    isAddressField: false,
    helperText: 'Felaktigt format'
  },
  {
    name: 'Email',
    type: 'text',
    label: 'E-post',
    isValid: true,
    isAddressField: false,
    helperText: 'Felaktigt format'
  },
  {
    name: 'MobileNumber',
    type: 'text',
    label: 'Mobilnummer',
    isValid: true,
    isAddressField: false,
    helperText: 'Felaktigt format'
  },
  {
    name: 'Street',
    type: 'text',
    label: 'Gatuadress',
    isValid: true,
    isAddressField: true,
    helperText: 'Får ej innehålla siffror, fyll i gatunummer i nästa fält.'
  },
  {
    name: 'Number',
    type: 'text',
    label: 'Gatunummer',
    isValid: true,
    isAddressField: true,
    helperText: 'Information saknas'
  },
  {
    name: 'Entrance',
    type: 'text',
    label: 'Port',
    isValid: true,
    isAddressField: true
  },
  {
    name: 'Apartment',
    type: 'text',
    label: 'Lägenhetsnummer',
    isValid: true,
    isAddressField: true
  },
  {
    name: 'ZipCode',
    type: 'text',
    label: 'Postnummer',
    isValid: true,
    isAddressField: true,
    helperText: 'Felaktigt format'
  },
  {
    name: 'City',
    type: 'text',
    label: 'Ort',
    isValid: true,
    isAddressField: true,
    helperText: 'Information saknas'
  },
  {
    name: 'Vip',
    type: 'checkbox',
    label: 'VIP',
    isValid: true,
    isAddressField: false
  }
];

export const EditCustomerForm = ({ customer }) => {
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = React.useState(false);
  const [values, setValues] = React.useState({
    FirstName: customer?.FirstName,
    LastName: customer?.LastName,
    Email: customer?.Email,
    MobileNumber: customer?.MobileNumber,
    Vip: customer?.Vip,
    Address: {
      Street: customer.Address?.Street,
      Number: customer.Address?.Number,
      Entrance: customer.Address?.Entrance,
      Apartment: customer.Address?.Apartment,
      ZipCode: customer.Address?.ZipCode,
      City: customer.Address?.City
    }
  });

  const [helperText, setHelperText] = React.useState({
    Name: null,
    Street: null,
    ZipCode: null,
    MobileNumber: null
  });

  const validateField = (value, fieldName) => {
    const { name, mobile, postnummer } = dataTypes;
    switch (fieldName) {
      case 'FirstName':
      case 'LastName':
      case 'Street':
        return name.validationRule.test(value);
      case 'MobileNumber':
        return mobile.validationRule.test(value);
      case 'ZipCode':
        return postnummer.validationRule.test(value);
      case 'City':
      case 'Number':
        return value?.length >= 1;
      default:
        return true;
    }
  };

  const handleOnChange = e => {
    let { value, checked, type, name } = e.target;
    const fieldData = fields.find(f => f.name === name);
    if (type === 'text') {
      const validationAccepted = validateField(value, name);
      if (!validationAccepted) {
        const fieldHelperText = fieldData?.helperText ?? null;
        setHelperText({ ...helperText, [name]: fieldHelperText });
        setSubmitDisabled(true);
      } else {
        setHelperText({ ...helperText, [name]: null });
        setSubmitDisabled(false);
      }
    }
    if (fieldData?.isAddressField) {
      setValues({ ...values, Address: { ...values.Address, [name]: value } });
    } else {
      if (name === 'Email' && value === '') {
        value = null;
      }
      setValues({ ...values, [name]: type !== 'text' ? checked : value });
    }
  };

  if (!customer) return null;

  return (
    <React.Fragment>
      <FormGroup
        sx={{ display: 'grid', gap: 6, gridTemplateColumns: '3fr 1fr' }}
      >
        {fields.map((field, index) => {
          const value = field.isAddressField
            ? values?.Address?.[field.name]
            : values?.[field.name];
          if (field.type === 'text') {
            return (
              <Box
                sx={
                  field.name === 'Street' || field.name === 'Number'
                    ? { gridColumn: 'span 1' }
                    : { gridColumn: 'span 2' }
                }
              >
                <TextField
                  required={field?.helperText}
                  key={index}
                  label={field.label}
                  error={helperText[field.name]}
                  helperText={helperText[field.name]}
                  value={value}
                  name={field.name}
                  onChange={e => handleOnChange(e, field.isAddressField)}
                />
              </Box>
            );
          } else {
            return (
              <LabeledCheckbox
                spanColumns
                formControlLabelProps={{ name: field.name }}
                label={field.label}
                checked={value}
                noMarginBottom
                onChange={e => handleOnChange(e)}
              />
            );
          }
        })}
      </FormGroup>
      <Box marginBlock={4}>
        <Button
          onClick={() =>
            dispatch(
              updateCustomer({
                ...customer,
                ...values
              })
            )
          }
          disabled={submitDisabled}
          type="submit"
        >
          Spara ändringar
        </Button>
        <Button
          to={replaceId(routes.customers.show, customer.CustomerId)}
          variant="text"
          color="black"
        >
          Tillbaka
        </Button>
      </Box>
    </React.Fragment>
  );
};

EditCustomerForm.propTypes = {
  customer: PropTypes.object
};
