"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertHexToRgba = void 0;

var convertHexToRgba = function convertHexToRgba(hex, opacity) {
  if (hex === undefined) {
    hex = '#000000';
  }

  hex = hex.replace('#', '');

  if (hex.length !== 6) {
    switch (hex.length) {
      case 3:
        hex = hex + hex;
        break;

      case 2:
        hex = hex + hex + hex;
        break;

      default:
        hex = '000000';
        break;
    }
  }

  if (opacity === undefined) {
    opacity = 100;
  }

  if (opacity < 0 || opacity > 100) {
    opacity = Math.min(100, Math.max(opacity, 0));
  }

  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);
  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
};

exports.convertHexToRgba = convertHexToRgba;