"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  withStyles: true,
  Button: true,
  Typography: true,
  TextField: true,
  Switch: true
};
Object.defineProperty(exports, "withStyles", {
  enumerable: true,
  get: function get() {
    return _styles.withStyles;
  }
});
Object.defineProperty(exports, "Button", {
  enumerable: true,
  get: function get() {
    return _Button.Button;
  }
});
Object.defineProperty(exports, "Typography", {
  enumerable: true,
  get: function get() {
    return _Typography.Typography;
  }
});
Object.defineProperty(exports, "TextField", {
  enumerable: true,
  get: function get() {
    return _TextField.TextField;
  }
});
Object.defineProperty(exports, "Switch", {
  enumerable: true,
  get: function get() {
    return _Switch.Switch;
  }
});

require("react-app-polyfill/ie9");

require("react-app-polyfill/stable");

var _material = require("@mui/material");

Object.keys(_material).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _material[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _material[key];
    }
  });
});

var _styles = require("@mui/styles");

var _Button = require("./Button/Button");

var _Typography = require("./Typography/Typography");

var _TextField = require("./TextField/TextField");

var _Switch = require("./Switch/Switch");