import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replaceId, routes } from 'paths';
import { fetchContractsWithUnPausedNegotiation } from 'state/actions';
import { Card } from 'pamind-ui';
import { CardContent } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { Table } from 'pamind-ui';
import { TableBody } from 'pamind-ui';
import { TableCell } from 'pamind-ui';
import { TableHead } from 'pamind-ui';
import { TableRow } from 'pamind-ui';
import { Link } from 'react-router-dom';
import { LocalTime } from 'domain-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography } from 'pamind-ui';

export class Component extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchContractsWithUnPausedNegotiation());
  }

  render() {
    if (!this.props.contracts) {
      return null;
    }

    return (
      <Card>
        <CardHeader title={'Nyligen hanterade ärenden'} />
        <CardContent>
          {this.props.contracts.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Avtalstyp</TableCell>
                  <TableCell>Personnummer</TableCell>
                  <TableCell>Namn</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobil</TableCell>
                  <TableCell>Leverantör</TableCell>
                  <TableCell>Reference Nr</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Senast ändrad</TableCell>
                  <TableCell>Kundrating</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.contracts.map(c => (
                  <TableRow
                    component={Link}
                    to={replaceId(routes.contracts.show, c.Id)}
                    key={c.Id}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>{c.ContractType}</TableCell>
                    <TableCell>{c.CustomerSecurityNumber}</TableCell>
                    <TableCell>{c.Name}</TableCell>
                    <TableCell>{c.Email}</TableCell>
                    <TableCell>{c.MobileNumber}</TableCell>
                    <TableCell>{c.ProviderName}</TableCell>
                    <TableCell>{c.ReferenceNumber}</TableCell>
                    <TableCell>{c.State}</TableCell>
                    <TableCell>
                      <LocalTime time={c.ChangedAt} />
                    </TableCell>
                    <TableCell>{c.CustomerRating}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Box
              style={{
                padding: 30,
                textAlign: 'center'
              }}
            >
              <CheckCircleIcon
                style={{
                  width: 300,
                  height: 300,
                  padding: 10,
                  margin: '0 auto',
                  display: 'block',
                  fill: '#66cdaa'
                }}
              />
              <Typography type="h2">Inget här...</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    );
  }
}

Component.propTypes = {
  contracts: PropTypes.array,
  dispatch: PropTypes.func
};

export const ContractsWithUnPausedNegotiation = connect(state => ({
  contracts: state.contractsWithUnPausedNegotiation
}))(Component);
