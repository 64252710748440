import React from 'react';
import { ContractsWithUnselectedOffers } from 'components/lists/ContractsWithUnselectedOffers';

export class UnselectedOffersList extends React.Component {
  render() {
    return (
      <div>
        <ContractsWithUnselectedOffers />
      </div>
    );
  }
}
