import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { downloadFile } from 'download-csv';
import { IconButton } from 'pamind-ui';
import SaveIcon from '@mui/icons-material/CloudDownload';

export const MonthsChart = props => {
  if (!props.data) {
    return null;
  }

  const chartData = props.data.map(m => ({
    Month: m.Year + '-' + m.Month,
    Value: m.Count
  }));

  const unit = props.statisticsType === 'Ratio' ? '%' : null;

  const handleDownload = () => {
    const newLine = '\r\n';
    let csv = 'Year,Month,Count' + newLine;
    csv += props.data.map(d => `${d.Year},${d.Month},${d.Count}`).join(newLine);

    downloadFile(csv);
  };

  return (
    <div>
      <div style={{ textAlign: 'right' }}>
        <IconButton
          tooltip="Ladda ner (CSV)"
          onClick={handleDownload}
          size="large"
        >
          <SaveIcon color={'primary'} />
        </IconButton>
      </div>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={chartData}>
          <Line type="monotone" dataKey="Value" stroke="#000" unit={unit} />
          <XAxis dataKey="Month" />
          <YAxis unit={unit} />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
MonthsChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      Year: PropTypes.number,
      Month: PropTypes.number,
      Count: PropTypes.number
    })
  ),
  statisticsType: PropTypes.string
};
