import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replaceId, routes } from 'paths';
import { fetchAdministrators } from 'state/actions';
import { Link } from 'react-router-dom';
import { ListItem } from 'pamind-ui';
import { List } from 'pamind-ui';
import { ListItemText } from 'pamind-ui';
import ContentAddIcon from '@mui/icons-material/Add';
import { Toolbar } from 'pamind-ui';
import { Typography } from 'pamind-ui';
import withStyles from '@mui/styles/withStyles';
import { Paper, Button } from 'pamind-ui';

const styles = theme => ({
  grow: {
    flexGrow: 1
  }
});

export class Component extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchAdministrators());
  }

  render() {
    if (!this.props.administrators) {
      return null;
    }
    const { classes } = this.props;
    return (
      <div>
        <Toolbar>
          <Typography variant="h6" color="inherit">
            Administratörer
          </Typography>
          <div className={classes.grow} />
          <Button
            color="primary"
            icon={<ContentAddIcon color={'inherit'} />}
            to={routes.administrators.add}
          >
            Lägg till administratör
          </Button>
        </Toolbar>
        <Paper>
          <List>
            {this.props.administrators.map(administrator => (
              <ListItem
                key={administrator.Id}
                button
                component={Link}
                to={replaceId(routes.administrators.show, administrator.Id)}
              >
                <ListItemText
                  primary={
                    administrator.FirstName + ' ' + administrator.LastName
                  }
                  secondary={administrator.Email}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </div>
    );
  }
}

Component.propTypes = {
  dispatch: PropTypes.func,
  administrators: PropTypes.array,
  classes: PropTypes.object
};

export const ListAdministrators = connect(state => ({
  administrators: state.administrators
}))(withStyles(styles)(Component));
