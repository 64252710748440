"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addDaysToTodaysDate = addDaysToTodaysDate;

function addDaysToTodaysDate(days) {
  var date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}