import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from 'pamind-ui';

export const MessageTemplatesListItem = ({ listItem }) => {
  if (!listItem) return null;

  return (
    <TableRow>
      <TableCell>
        <strong>{listItem.Name}</strong>
      </TableCell>
      <TableCell>
        <strong>Id: </strong>
        {listItem.MailTemplateId}
        <br />
        <strong>Kategorier: </strong>
        {listItem.MailCategories.map(e => e).join(',')}
      </TableCell>
      <TableCell>
        <strong>Title: </strong>
        {listItem.NotificationTitle}
        <br />
        <strong>Body: </strong>
        {listItem.NotificationBody}
      </TableCell>
      <TableCell>{listItem.SmsText}</TableCell>
      <TableCell>{listItem.SendAt}</TableCell>
    </TableRow>
  );
};

MessageTemplatesListItem.propTypes = {
  listItem: PropTypes.object
};
