import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { FormControlLabel } from 'pamind-ui';
import { Card, Select, MenuItem, InputLabel } from 'pamind-ui';

const style = theme => ({
  root: {
    margin: theme.spacing(2, 1),
    padding: theme.spacing(0, 4)
  },
  formControlLabel: {
    margin: theme.spacing(2),
    marginLeft: 0
  },
  inputLabel: {
    marginRight: theme.spacing(2)
  }
});
export class Component extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        {this.props.offer && (
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <div>
                <InputLabel
                  className={classes.inputLabel}
                  htmlFor="offerCategory"
                >
                  Offer Categories:
                </InputLabel>
                <Select
                  value={
                    this.props.offerCategory ?? this.props.offer.OfferCategory
                  }
                  onChange={this.props.onChange}
                  inputProps={{
                    name: 'offerCategory',
                    id: 'offerCategory'
                  }}
                  autoWidth
                  disabled={this.props.disabled}
                >
                  {this.props.categories.map((item, index) => (
                    <MenuItem key={item.key} value={item.key}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            }
            label=""
          />
        )}
      </Card>
    );
  }
}
Component.propTypes = {
  disabled: PropTypes.bool,
  offer: PropTypes.object,
  categories: PropTypes.array,
  onChange: PropTypes.func,
  offerCategory: PropTypes.string,
  classes: PropTypes.object
};

export const SelectOfferCategory = withStyles(style)(Component);
