import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinearProgress } from 'pamind-ui';

export class Component extends React.Component {
  render() {
    if (this.props.progress.isFetching) {
      return <LinearProgress mode="indeterminate" />;
    } else {
      return null;
    }
  }
}

Component.propTypes = {
  progress: PropTypes.shape({
    isFetching: PropTypes.bool
  })
};

export const Progress = connect(state => {
  return { progress: state.progress };
})(Component);
