"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDeviceType = void 0;

var _mobileDetect = _interopRequireDefault(require("mobile-detect"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getDeviceType = function getDeviceType() {
  var md = new _mobileDetect["default"](navigator.userAgent);
  var deviceType = 'Desktop';

  if (md.isPhoneSized()) {
    deviceType = 'Android';

    if (md.is('iPhone')) {
      deviceType = 'iPhone';

      if (window.screen.height >= 812) {
        /* This works as a check for devices without a home button.
        Devices with 812 height or larger is all the devices without a 
        home button + iPhone 6 plus, iPhone 7 plus and iPhone 8 plus.
        This will work for know to make styling more solid /Elin 22feb 2022 */
        deviceType = 'iPhone X<';
      }
    }
  }

  return deviceType;
};

exports.getDeviceType = getDeviceType;