import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from 'pamind-ui';
import { Snackbar } from 'pamind-ui';
import { SnackbarContent } from 'pamind-ui';
import { IconButton } from 'pamind-ui';
import { colors } from 'pamind-ui';

export class Component extends React.Component {
  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={this.props.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          style={{ backgroundColor: colors.amber[700] }}
          message={<Typography>{this.props.errorMessage}</Typography>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.props.dismissError()}
              size="large"
            >
              <CloseIcon color="inherit" />
            </IconButton>
          ]}
        />
      </Snackbar>
    );
  }
}

Component.propTypes = {
  children: PropTypes.element,
  errorMessage: PropTypes.string,
  dismissError: PropTypes.func,
  open: PropTypes.bool
};

export const DismissableError = Radium(Component);
