import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchProvider, deleteProvider } from 'state/actions';
import { replaceId, routes } from 'paths';
import {
  ConfirmationDialog,
  KeyValueHorizontal,
  SupplierAvatar,
  Markdown
} from 'domain-components';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  MenuItem,
  Menu,
  IconButton,
  Button,
  Typography,
  CardHeader,
  Card
} from 'pamind-ui';

export class Component extends React.Component {
  state = {
    menu: null
  };

  handleClick = event => {
    this.setState({ menu: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ menu: null });
  };

  componentDidMount() {
    this.props.dispatch(fetchProvider(Number(this.props.match.params.id)));
  }

  handleDelete = () => {
    this.props.dispatch(deleteProvider(Number(this.props.match.params.id)));
  };

  render() {
    if (!this.props.provider) {
      return null;
    }
    const { menu } = this.state;
    const provider = this.props.provider;
    const dl = '/api/a/statistics/SupplierStatistics/' + provider.Id;

    return (
      <Box display="flex">
        <Box width="100%">
          <Card>
            <CardHeader
              avatar={
                <SupplierAvatar
                  logo={`${process.env.PUBLIC_URL}/api/c/provider/logo/${provider.Id}`}
                  resetPosition
                  size={48}
                />
              }
              action={
                <div>
                  <IconButton
                    aria-label="settings"
                    onClick={this.handleClick}
                    aria-owns={menu ? 'simple-menu' : undefined}
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={menu}
                    open={Boolean(menu)}
                    onClose={this.handleClose}
                    keepMounted
                  >
                    <MenuItem
                      component={Link}
                      to={dl}
                      target="_blank"
                      onClick={this.handleClose}
                    >
                      Ladda ner data för cohort analys
                    </MenuItem>
                    <MenuItem onClick={this.handleClose}>
                      <ConfirmationDialog
                        label="Radera leverantör"
                        onConfirm={this.handleDelete}
                      />
                    </MenuItem>
                  </Menu>
                </div>
              }
              title={
                <Typography variant="h2">
                  <Box fontFamily="fontFamily">{provider.Name}</Box>
                </Typography>
              }
              subheader={<span>{provider.NumberOfContracts} kontrakt</span>}
            />
            <Box padding={6}>
              <KeyValueHorizontal
                keyValueArray={[
                  {
                    key: 'Profile beskrivning',
                    value: provider.Description
                  },
                  {
                    key: 'Validated',
                    value: provider.Validated ? 'Ja' : 'Nej'
                  },
                  {
                    key: 'Max antal förfrågningar per dag',
                    value:
                      !provider.TermsRequestDailyRateLimit ||
                      !provider.TermsRequestDailyRateLimit.Value
                        ? '-'
                        : provider.TermsRequestDailyRateLimit.Value
                  },
                  {
                    key: 'Scraping alias',
                    value: provider.ScrapingAlias
                  }
                ]}
              />
              {!!this.props.provider.ContractTypes && (
                <Box marginY={12}>
                  <Typography variant="h2">
                    <Box marginBottom={4} fontFamily="fontFamily">
                      Avtalstyper
                    </Box>
                  </Typography>
                  {this.props.provider.ContractTypes.map(
                    (contractType, index) => (
                      <Box marginY={2}>
                        <Accordion key={index}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{contractType.ContractType}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Markdown
                              source={contractType.PamindOfferProfile}
                            />
                            <KeyValueHorizontal
                              keyValueArray={[
                                {
                                  key: 'E-postadress för bekräftelsemail',
                                  value: contractType.ConfirmationEmail
                                },
                                {
                                  key: 'Email',
                                  value: contractType.Email
                                },
                                {
                                  key: 'Telefonnummer',
                                  value: contractType.PhoneNumber
                                },
                                {
                                  key: 'Lämnar information',
                                  value: contractType.ProvidesTerms
                                    ? 'Ja'
                                    : 'Nej'
                                },
                                {
                                  key: 'Lämnar erbjudanden',
                                  value: contractType.ProvidesOffers
                                    ? 'Ja'
                                    : 'Nej'
                                },
                                {
                                  key: 'Kan sägas upp',
                                  value: contractType.AllowsCancellation
                                    ? 'Ja'
                                    : 'Nej'
                                },
                                {
                                  key: 'Belönar lojalitet',
                                  value: contractType.AwardsLoyalty
                                    ? 'Ja'
                                    : 'Nej'
                                },
                                {
                                  key: 'Leverantör sköter bytet vid churn',
                                  value: contractType.HandlesCancellation
                                    ? 'Ja'
                                    : 'Nej'
                                },
                                {
                                  key:
                                    'Dold från att väljas i leverantörslistan för kund',
                                  value: contractType.UnselectableByCustomer
                                    ? 'Ja'
                                    : 'Nej'
                                },
                                {
                                  key: 'Är SalesProvider',
                                  value: contractType.IsSalesProvider
                                    ? 'Ja'
                                    : 'Nej'
                                },
                                {
                                  key: 'Antal dagar till sista svarsdatum',
                                  value: contractType.DaysToLastReplyDate
                                    ? contractType.DaysToLastReplyDate.Value
                                    : '-'
                                },
                                {
                                  key:
                                    'Antal dagar till förhandlingens start med bindningstid',
                                  value: !!contractType.StartNegotiationDaysExpiringPlan
                                    ? contractType
                                        .StartNegotiationDaysExpiringPlan.Value
                                    : '-'
                                },
                                {
                                  key:
                                    'Antal dagar till förhandlingens start med rabatt',
                                  value: contractType.StartNegotiationDaysExpiringDiscount
                                    ? contractType
                                        .StartNegotiationDaysExpiringDiscount
                                        .Value
                                    : '-'
                                },
                                {
                                  key:
                                    'Antal dagar till förhandlingens start (ingen bindningstid eller rabatt)',
                                  value: contractType.StartNegotiationDays
                                    ? contractType.StartNegotiationDays.Value
                                    : '-'
                                },
                                {
                                  key: 'Ledtid vid uppsägning',
                                  value: contractType.CancellationLeadTime
                                    ? contractType.CancellationLeadTime
                                    : '-'
                                },
                                {
                                  key: 'Scraping class',
                                  value: contractType.ScrapingClrType
                                    ? contractType.ScrapingClrType
                                    : '-'
                                }
                              ]}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )
                  )}
                </Box>
              )}
              <Box marginTop={12}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  to={replaceId(routes.providers.edit, provider.Id)}
                >
                  Redigera leverantör
                </Button>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    );
  }
}

Component.propTypes = {
  metadata: PropTypes.object,
  provider: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
};

export const ShowProvider = connect(state => ({
  metadata: state.metadata,
  provider: state.provider,
  theme: state.theme
}))(Component);
