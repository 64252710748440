import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from 'pamind-ui';
import { Select } from 'pamind-ui';
import { TextField } from 'pamind-ui';
import { InputLabel } from 'pamind-ui';
import { KeyValueListItem, KeyValueList } from 'components/ui/KeyValueList';
import { getContractTypeNegotiationDetails } from 'services/domain';

export class NegotiationDetailsForm extends Component {
  render() {
    if (this.props.editMode) {
      const negotiationDetailsOptions = getContractTypeNegotiationDetails(
        this.props.metadata,
        this.props.contractType
      );
      return (
        <div>
          <InputLabel htmlFor="standard-texts">Standardtexter</InputLabel>

          <Select
            autoWidth={true}
            onChange={e => this.props.onChange(e.target.value)}
            id="standard-texts"
            value={''}
          >
            {negotiationDetailsOptions.map((item, index) => (
              <MenuItem key={index} value={item.text}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <TextField
            name="NegotiationDetails"
            value={this.props.value}
            multiline={true}
            rows={5}
            fullWidth={true}
            onChange={e => this.props.onChange(e.target.value)}
          />
        </div>
      );
    }

    const value = this.props.value.split('\n').map(function(item, index) {
      return <span key={index}>{item}</span>;
    });

    return (
      <KeyValueList>
        <KeyValueListItem itemKey="Detaljer" itemValue={value} />
      </KeyValueList>
    );
  }
}

NegotiationDetailsForm.propTypes = {
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
  metadata: PropTypes.object,
  contractType: PropTypes.string,
  value: PropTypes.string
};
