import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchJobs, startJob, cancelJob } from 'state/actions';
import { ListItem } from 'pamind-ui';
import { List } from 'pamind-ui';
import { ListItemText } from 'pamind-ui';
import { Button } from 'pamind-ui';
import withStyles from '@mui/styles/withStyles';
import { ListItemSecondaryAction } from 'pamind-ui';
import { IconButton } from 'pamind-ui';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListSubheader } from 'pamind-ui';
import { ListItemIcon } from 'pamind-ui';
import { Collapse } from 'pamind-ui';
import EventIcon from '@mui/icons-material/Event';
import { LinearProgress } from 'pamind-ui';
import { Typography } from 'pamind-ui';
import { LocalTime } from 'domain-components';

const styles = theme => ({});

export class Component extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchJobs());
  }

  handleStart = data => {
    this.props.dispatch(startJob(data));
  };

  handleCancel = data => {
    this.props.dispatch(cancelJob(data));
  };

  render() {
    if (!this.props.jobs) {
      return null;
    }
    const { classes } = this.props;
    return (
      <List
        dense={true}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <Button
              onClick={() => {
                this.props.dispatch(fetchJobs());
              }}
              size="small"
            >
              Update status of jobs
            </Button>
          </ListSubheader>
        }
        className={classes.root}
      >
        {this.props.jobs.map(job => (
          <div>
            <ListItem
              button
              onClick={() => {
                this.handleStart(job.Guid);
              }}
            >
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText
                secondary={job.Type}
                primary={
                  <Typography>
                    <LocalTime time={job.NextExecutionAt} />
                  </Typography>
                }
              />
            </ListItem>
            <div>
              {job.Instances.map(instance => (
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon />
                      <ListItemText
                        primary={
                          <Typography>
                            <strong>Startade: </strong>
                            <LocalTime time={instance.StartedAt} />
                          </Typography>
                        }
                        secondary={<LinearProgress />}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            this.handleCancel(instance.Guid);
                          }}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Collapse>
              ))}
            </div>
          </div>
        ))}
      </List>
    );
  }
}

Component.propTypes = {
  dispatch: PropTypes.func,
  jobs: PropTypes.array,
  classes: PropTypes.object
};

export const ListJobs = connect(state => ({
  jobs: state.jobs
}))(withStyles(styles)(Component));
