import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replaceId, routes } from 'paths';
import IconVip from '@mui/icons-material/Loyalty';

import { Card } from 'pamind-ui';
import { CardContent } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { Table } from 'pamind-ui';
import { TableBody } from 'pamind-ui';
import { TableCell } from 'pamind-ui';
import { TableHead } from 'pamind-ui';
import { TableRow } from 'pamind-ui';
import { push } from 'connected-react-router';

export class Component extends React.Component {
  renderCustomers() {
    return (
      <Fragment>
        <CardHeader title="Kunder" />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Namn</TableCell>
                <TableCell>Personnummer</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Mobilnummer</TableCell>
                <TableCell>VIP</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.data.Customers.map(r => (
                <TableRow
                  key={r.Id}
                  style={{ cursor: 'pointer ' }}
                  onClick={() =>
                    this.props.push(replaceId(routes.customers.show, r.Id))
                  }
                >
                  <TableCell>{r.CustomerName}</TableCell>
                  <TableCell>{r.SecurityNumber}</TableCell>
                  <TableCell>{r.CustomerEmail}</TableCell>
                  <TableCell>{r.MobileNumber}</TableCell>
                  <TableCell>
                    {r.Vip ? <IconVip color={'primary'} /> : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Fragment>
    );
  }

  renderContracts() {
    return (
      <Fragment>
        <CardHeader title="Avtal" />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Leverantör</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell>Ärendenr</TableCell>
                <TableCell>Namn</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.data.Contracts.map(r => (
                <TableRow
                  key={r.Id}
                  style={{ cursor: 'pointer ' }}
                  onClick={() =>
                    this.props.push(replaceId(routes.contracts.show, r.Id))
                  }
                >
                  <TableCell>{r.Provider}</TableCell>
                  <TableCell>{r.ContractType}</TableCell>
                  <TableCell>{r.ReferenceNumber}</TableCell>
                  <TableCell>{r.CustomerName}</TableCell>
                  <TableCell>{r.CustomerEmail}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Fragment>
    );
  }

  render() {
    if (!this.props.data) {
      return null;
    }

    const noResults =
      !this.props.data.Customers.length && !this.props.data.Contracts.length;

    return (
      <Card>
        {this.props.data.Customers.length > 0 && this.renderCustomers()}
        {this.props.data.Contracts.length > 0 && this.renderContracts()}
        {noResults && <CardContent>Inga resultat</CardContent>}
      </Card>
    );
  }
}

Component.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func,
  push: PropTypes.func
};

export const SearchResultsView = connect(
  state => ({
    data: state.search.data
  }),
  { push }
)(Component);
