import React from 'react';
import PropTypes from 'prop-types';
import { setLoggedOut } from 'services/auth';
import { Link } from 'react-router-dom';
import { routes } from 'paths';
import SearchIcon from '@mui/icons-material/Search';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChartIcon from '@mui/icons-material/ShowChart';
import ProviderIcon from '@mui/icons-material/Domain';
import AdministratorIcon from '@mui/icons-material/VerifiedUser';
import JobsIcon from '@mui/icons-material/AccessTime';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Divider } from 'pamind-ui';
import { Drawer } from 'pamind-ui';
import { ListItem } from 'pamind-ui';
import { ListItemIcon } from 'pamind-ui';
import { ListItemText } from 'pamind-ui';
import { List } from 'pamind-ui';
import { Collapse } from 'pamind-ui';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { AppBar, Box } from 'pamind-ui';
import classNames from 'classnames';
import { Toolbar } from 'pamind-ui';
import { IconButton } from 'pamind-ui';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from 'pamind-ui';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AssignmentComplete from '@mui/icons-material/AssignmentTurnedInOutlined';
import { Progress } from 'components/dashboard/Progress';
import AssignementLate from '@mui/icons-material/AssignmentLateOutlined';
import NotificationsNoneOutlined from '@mui/icons-material/NotificationsNoneOutlined';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { SelectTheme } from './SelectTheme';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const drawerWidth = 300;

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.primary.main,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    grow: {
      flexGrow: 1
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(15)
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    rotate: {
      animation: '$rotate 1s linear infinite'
    },
    version: {
      marginLeft: theme.spacing(3)
    },
    '@keyframes rotate': {
      to: { transform: 'rotate(360deg)' }
    }
  }),
  { name: 'NavigationMenu' }
);

export const NavigationMenu = props => {
  const classes = useStyles();
  const { theme, metadata } = useSelector(state => state);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [collapsedItemOpen, setCollapsedItemOpen] = React.useState(false);

  return (
    <Box className={classes.root}>
      <AppBar
        position="fixed"
        color="primary"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: openDrawer
        })}
      >
        <Toolbar disableGutters={!openDrawer}>
          <IconButton
            color="primary"
            aria-label="Open drawer"
            onClick={() => setOpenDrawer(!openDrawer)}
            className={classNames(
              classes.menuButton,
              theme === 'coolBanana' ? classes.rotate : undefined,
              {
                [classes.hide]: openDrawer
              }
            )}
            size="large"
          >
            {theme === 'coolBanana' ? '🍌' : <MenuIcon />}
          </IconButton>
          <Typography variant="h5" color="inherit" noWrap>
            Påmind
          </Typography>
          {!!metadata && (
            <Typography
              variant="h5"
              color="inherit"
              className={classes.version}
              noWrap
            >
              {`API version ${metadata.apiVersion}`}
            </Typography>
          )}
          <div className={classes.grow} />
          <SelectTheme />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer
          })
        }}
        open={openDrawer}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={() => setOpenDrawer(!openDrawer)} size="large">
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem component={Link} to={routes.search}>
            <ListItemIcon>
              <SearchIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Sök" />
          </ListItem>
        </List>
        <List>
          <ListItem
            button
            onClick={() => setCollapsedItemOpen(!collapsedItemOpen)}
          >
            <ListItemIcon>
              {collapsedItemOpen ? (
                <ExpandLess color="primary" />
              ) : (
                <ExpandMore color="primary" />
              )}
            </ListItemIcon>
            <ListItemText primary="Listor" />
          </ListItem>
          <Collapse in={collapsedItemOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem component={Link} to={routes.lists.closedNegotiations}>
                <ListItemIcon>
                  <AssignmentComplete color="primary" />
                </ListItemIcon>
                <ListItemText primary="Stängda förhandlingar" />
              </ListItem>
              <ListItem component={Link} to={routes.lists.pausedNegotiations}>
                <ListItemIcon>
                  <AssignementLate color="primary" />
                </ListItemIcon>
                <ListItemText primary="Svar i leverantörswebben" />
              </ListItem>
              <ListItem component={Link} to={routes.lists.unpausedNegotiations}>
                <ListItemIcon>
                  <PlayCircleOutline color="primary" />
                </ListItemIcon>
                <ListItemText primary="Nyligen hanterade ärenden + TermsNonReceipt" />
              </ListItem>
              <ListItem component={Link} to={routes.lists.unselectedOffers}>
                <ListItemIcon>
                  <NotificationsNoneOutlined color="primary" />
                </ListItemIcon>
                <ListItemText primary="Aktuella erbjudanden" />
              </ListItem>
              <ListItem
                component={Link}
                to={routes.lists.otherContractsWithImages}
              >
                <ListItemIcon>
                  <PhotoOutlinedIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Övriga kontrakt med bilder" />
              </ListItem>
            </List>
          </Collapse>
        </List>
        <List>
          <ListItem component={Link} to={routes.statistics}>
            <ListItemIcon>
              <ChartIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Statistik" />
          </ListItem>
          <ListItem component={Link} to={routes.providers.list}>
            <ListItemIcon>
              <ProviderIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Leverantörer" />
          </ListItem>
          <ListItem component={Link} to={routes.administrators.list}>
            <ListItemIcon>
              <AdministratorIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Administratörer" />
          </ListItem>
          <ListItem component={Link} to={routes.jobs.list}>
            <ListItemIcon>
              <JobsIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Jobs" />
          </ListItem>
          <ListItem component={Link} to={routes.templates.list}>
            <ListItemIcon>
              <ListAltIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Templates" />
          </ListItem>
          <ListItem component={Link} to={routes.messageTemplates.list}>
            <ListItemIcon>
              <MailOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="MessageTemplates" />
          </ListItem>
          <Divider />
          <ListItem onClick={() => setLoggedOut()}>
            <ListItemIcon>
              <ExitToAppIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Logga ut" />
          </ListItem>
        </List>
      </Drawer>
      <Box className={classes.content}>
        <div className={classes.toolbar} />
        <Progress />
        {props.children}
      </Box>
    </Box>
  );
};

NavigationMenu.propTypes = {
  children: PropTypes.node.isRequired
};
