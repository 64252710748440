"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatTwoDecimals = formatTwoDecimals;
exports.formatCurrency = formatCurrency;

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function formatTwoDecimals(input) {
  if (typeof input !== 'number' || isNaN(input)) return input;
  return input.toFixed(2);
}

function formatCurrency(number) {
  if (_typeof(number) === 'object') {
    return formatCurrency(number.Value) + (number.Percent ? '%' : '');
  }

  if (typeof number === 'string') {
    number = Number(number);
  }

  Number.isInteger = Number.isInteger || function (value) {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  };

  var thousandSeparator = ' ';
  var decimalSeparator = ',';
  var decimals = ''; // Format number to 2 decimal places

  number = formatTwoDecimals(number); // Save decimals for later

  if (!Number.isInteger(number)) {
    decimals = number.toString().replace(/(.*)\./g, '');
    number -= number % 1;
  } // Add thousand separators to integer


  number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator); // Add decimals back in

  if (decimals !== '00') {
    decimals = decimals.toString().replace(/(.*)\./g, ''); // Remove "0." and "-0."

    decimals = decimals.substr(0, 2); // It's already rounded, so just strip remaining decimals

    number += decimalSeparator + decimals;
  }

  return number;
}