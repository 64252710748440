/**
 * @param {string} mobileNumber
 * @returns string
 */
export const getConvertedMobileNumber = mobileNumber => {
  let convertedMobileNumber = mobileNumber;
  if (!mobileNumber) return mobileNumber;
  if (mobileNumber.startsWith('+46')) {
    convertedMobileNumber = `0${convertedMobileNumber.slice('+46'.length)}`;
  } else if (mobileNumber.startsWith('0046')) {
    convertedMobileNumber = `0${convertedMobileNumber.slice('0046'.length)}`;
  }
  return convertedMobileNumber;
};
