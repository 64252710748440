import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes } from 'paths';
import { fetchMetadata } from 'state/actions';
import { SearchContainer } from 'components/search/SearchContainer';
import { ShowCustomer } from 'components/customer/ShowCustomer';
import { EditCustomer } from 'components/customer/EditCustomer';
import { ShowContract } from 'components/contract/ShowContract';
import { ListProviders } from 'components/provider/ListProviders';
import { ShowProvider } from 'components/provider/ShowProvider';
import { AddProvider } from 'components/provider/AddProvider';
import { EditProvider } from 'components/provider/EditProvider';
import { AddAdministrator } from 'components/administrator/AddAdministrator';
import { EditAdministrator } from 'components/administrator/EditAdministrator';
import { ListAdministrators } from 'components/administrator/ListAdministrators';
import { ListJobs } from 'components/jobs/ListJobs';
import { ShowAdministrator } from 'components/administrator/ShowAdministrator';
import { ClosedNegotiationsList } from 'components/lists/ClosedNegotiationsList';
import { PausedNegotiationsList } from 'components/lists/PausedNegotiationsList';
import { UnpausedNegotiationsList } from 'components/lists/UnpausedNegotiationsList';
import { UnselectedOffersList } from 'components/lists/UnselectedOffersList';
import { OtherContractsWithImagesList } from 'components/lists/OtherContractsWithImagesList';
import { Statistics } from 'components/statistics/Statistics';
import { NavigationMenu } from 'components/dashboard/NavigationMenu';
import { Notification } from 'components/dashboard/Notification';
import { Error } from 'components/dashboard/Error';
import { Box } from 'pamind-ui';
import { TemplateList } from 'components/templates/TemplateList';
import { TemplateDetailView } from 'components/templates/TemplateDetailView';
import { MessageTemplatesList } from 'components/messageTemplates/MessageTemplatesList';
import { CustomersList } from 'components/customer/CustomersList';

const NoMatch = props => <div>404</div>;

export class Component extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchMetadata());
  }

  renderRoutes() {
    if (!this.props.metadata) {
      return null;
    }

    return (
      <Switch>
        <Redirect exact from={routes.dashboard} to={routes.search} />
        <Route path={routes.search} component={SearchContainer} exact />
        <Route path={routes.customerList} component={CustomersList} exact />
        <Route path={routes.customers.show} component={ShowCustomer} exact />
        <Route path={routes.customers.edit} component={EditCustomer} exact />
        <Route path={routes.contracts.show} component={ShowContract} exact />
        <Route path={routes.statistics} component={Statistics} exact />
        <Route path={routes.providers.list} component={ListProviders} exact />
        <Route path={routes.providers.add} component={AddProvider} exact />
        <Route path={routes.providers.edit} component={EditProvider} exact />
        <Route path={routes.providers.show} component={ShowProvider} exact />
        <Route
          path={routes.administrators.list}
          component={ListAdministrators}
          exact
        />
        <Route
          path={routes.administrators.add}
          component={AddAdministrator}
          exact
        />
        <Route
          path={routes.administrators.edit}
          component={EditAdministrator}
          exact
        />
        <Route
          path={routes.administrators.show}
          component={ShowAdministrator}
          exact
        />
        <Route path={routes.jobs.list} component={ListJobs} exact />
        <Route
          path={routes.lists.closedNegotiations}
          component={ClosedNegotiationsList}
          exact
        />
        <Route
          path={routes.lists.pausedNegotiations}
          component={PausedNegotiationsList}
          exact
        />
        <Route
          path={routes.lists.unselectedOffers}
          component={UnselectedOffersList}
          exact
        />
        <Route
          path={routes.lists.otherContractsWithImages}
          component={OtherContractsWithImagesList}
          exact
        />
        <Route
          path={routes.lists.unpausedNegotiations}
          component={UnpausedNegotiationsList}
          exact
        />
        <Route path={routes.templates.list} component={TemplateList} exact />
        <Route
          path={routes.templates.show}
          component={TemplateDetailView}
          exact
        />
        <Route
          path={routes.messageTemplates.list}
          component={MessageTemplatesList}
          exact
        />
        <Route component={NoMatch} />
      </Switch>
    );
  }

  render() {
    return (
      <Box>
        <NavigationMenu>
          <Error />
          <Notification />
          {this.renderRoutes()}
        </NavigationMenu>
      </Box>
    );
  }
}

Component.propTypes = {
  dispatch: PropTypes.func,
  metadata: PropTypes.object
};

export const DashboardContainer = connect(state => ({
  metadata: state.metadata
}))(Component);
