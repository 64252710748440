import { put, call, all, fork, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { replaceId, routes } from 'paths';
import { API_ACTION_TYPES, STATUS, fetchContract } from 'state/actions';
import {
  getContract,
  createContract,
  updateContract,
  deleteContract,
  updateNegotiationTerms,
  requestCompletion,
  sendNegotiationProviderMessage,
  sendNegotiationCustomerMessage,
  sendNegotiationToAddressMessage,
  sendCustomerMessage,
  sendCustomerNotification,
  createNegotiation,
  updateNegotiation,
  deleteNegotiation,
  selectTerms,
  deleteTerms,
  setNotCustomer,
  confirmTerms,
  getMetadata,
  getCustomer,
  updateCustomer,
  deleteCustomer,
  search,
  getProviders,
  getContractsWithClosedNegotiation,
  getContractsWithPausedNegotiation,
  getContractsWithUnPausedNegotiation,
  getContractsWithUnselectedOffers,
  getOtherContractsWithImages,
  getStatistics,
  getTranslations,
  updateTranslations,
  getUnsentMessages,
  processMessage,
  getProvider,
  updateProvider,
  createProvider,
  deleteProvider,
  getJobs,
  startJob,
  cancelJob,
  getAdministrators,
  getAdministrator,
  deleteAdministrator,
  createAdministrator,
  updateAdministrator,
  getContractDocuments,
  updateNegotiationDates,
  getOfferTemplates,
  improveContract,
  getTemplates,
  updateTemplate,
  getMessageTemplates
} from 'services/api';

function* fetchSagaHelper(actionType, apiMethod, parameters) {
  try {
    const data = yield call(apiMethod, ...parameters);
    yield putSuccessData(actionType, data);
  } catch (error) {
    yield putFailure(actionType, error);
  }
}

export function* fetchContractSaga(action) {
  const { id } = action;
  yield* fetchSagaHelper(action.type, getContract, [id]);
}

export function* fetchMetadataSaga(action) {
  yield* fetchSagaHelper(action.type, getMetadata, []);
}

export function* fetchCustomerSaga(action) {
  const { id } = action;
  yield* fetchSagaHelper(action.type, getCustomer, [id]);
}

export function* updateCustomerSaga(action) {
  const { customer } = action;
  try {
    yield call(updateCustomer, customer);
    yield putSuccessNotification(action.type);
    yield put(push(replaceId(routes.customers.show, customer.CustomerId)));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* searchSaga(action) {
  const { query } = action;
  yield* fetchSagaHelper(action.type, search, [query]);
}

export function* fetchProvidersSaga(action) {
  yield* fetchSagaHelper(action.type, getProviders, []);
}

export function* fetchContractsWithClosedNegotiationSaga(action) {
  yield* fetchSagaHelper(action.type, getContractsWithClosedNegotiation, []);
}

export function* fetchContractsWithPausedNegotiationSaga(action) {
  yield* fetchSagaHelper(action.type, getContractsWithPausedNegotiation, []);
}

export function* fetchContractsWithUnPausedNegotiationSaga(action) {
  yield* fetchSagaHelper(action.type, getContractsWithUnPausedNegotiation, []);
}

export function* fetchContractsWithUnselectedOffersSaga(action) {
  yield* fetchSagaHelper(action.type, getContractsWithUnselectedOffers, []);
}

export function* fetchOtherContractsWithImagesSaga(action) {
  yield* fetchSagaHelper(action.type, getOtherContractsWithImages, []);
}

export function* fetchStatisticsSaga(action) {
  yield* fetchSagaHelper(action.type, getStatistics, []);
}

export function* fetchTranslationsSaga(action) {
  yield* fetchSagaHelper(action.type, getTranslations, []);
}

export function* updateTranslationsSaga(action) {
  const { json } = action;
  yield* fetchSagaHelper(action.type, updateTranslations, [json]);
  yield putSuccessNotification(action.type);
}

export function* fetchMessagesSaga(action) {
  yield* fetchSagaHelper(action.type, getUnsentMessages, []);
}

export function* processMessageSaga(action) {
  const { id } = action;
  try {
    yield call(processMessage, id);
    yield put({
      type: action.type,
      status: STATUS.SUCCESS,
      id
    });
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* fetchProviderSaga(action) {
  const { id } = action;
  yield* fetchSagaHelper(action.type, getProvider, [id]);
}

export function* updateProviderSaga(action) {
  const { providerId, provider } = action;
  try {
    yield call(updateProvider, providerId, provider);
    yield putSuccessNotification(action.type);
    yield put(push(replaceId(routes.providers.show, providerId)));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* createProviderSaga(action) {
  const { provider } = action;
  try {
    const data = yield call(createProvider, provider);
    yield putSuccessNotification(action.type);
    yield put(push(replaceId(routes.providers.show, data.Id)));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* deleteProviderSaga(action) {
  const { id } = action;
  try {
    yield call(deleteProvider, id);
    yield putSuccessNotification(action.type);
    yield put(push(routes.providers.list));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* createContractSaga(action) {
  const { contract } = action;
  try {
    const contractId = yield call(createContract, contract);
    yield putSuccessNotification(action.type);
    yield put(push(replaceId(routes.contracts.show, contractId)));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* updateContractSaga(action) {
  const {
    id,
    preferences,
    attributes,
    isNonNegotiable,
    contractDetails,
    contractState
  } = action;
  try {
    yield call(
      updateContract,
      id,
      preferences,
      attributes,
      isNonNegotiable,
      contractDetails,
      contractState
    );
    yield putSuccessNotification(action.type);
    yield put(fetchContract(id));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* deleteCustomerSaga(action) {
  const { id } = action;
  try {
    yield call(deleteCustomer, id);
    yield putSuccessNotification(action.type);
    yield put(push(routes.dashboard));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* deleteContractSaga(action) {
  const { id, customerId } = action;
  try {
    yield call(deleteContract, id);
    yield putSuccessNotification(action.type);
    yield put(push(replaceId(routes.customers.show, customerId)));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* improveContractSaga(action) {
  const { id } = action;
  try {
    yield call(improveContract, id);
    yield putSuccessNotification(action.type);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* setNotCustomerSaga(action) {
  const { id, customerId } = action;
  try {
    yield call(setNotCustomer, id);
    yield putSuccessNotification(action.type);
    yield put(push(replaceId(routes.customers.show, customerId)));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* fetchJobsSaga(action) {
  try {
    const data = yield call(getJobs);
    yield putSuccessData(action.type, data);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* startJobSaga(action) {
  const { id } = action;
  try {
    const data = yield call(startJob, id);
    yield putSuccessData(action.type, data);
    yield putSuccessNotification(action.type);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* cancelJobSaga(action) {
  const { id } = action;
  try {
    const data = yield call(cancelJob, id);
    yield putSuccessData(action.type, data);
    yield putSuccessNotification(action.type);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* fetchAdministratorsSaga(action) {
  yield* fetchSagaHelper(action.type, getAdministrators, []);
}

export function* fetchAdministratorSaga(action) {
  const { id } = action;
  yield* fetchSagaHelper(action.type, getAdministrator, [id]);
}

export function* createAdministratorSaga(action) {
  const { administrator } = action;
  try {
    const data = yield call(createAdministrator, administrator);
    yield putSuccessNotification(action.type);
    yield put(push(replaceId(routes.administrators.show, data.Id)));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* updateAdministratorSaga(action) {
  const { administrator } = action;
  try {
    yield call(updateAdministrator, administrator);
    yield putSuccessNotification(action.type);
    yield put(push(replaceId(routes.administrators.show, administrator.Id)));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* deleteAdministratorSaga(action) {
  const { id } = action;
  try {
    yield call(deleteAdministrator, id);
    yield putSuccessNotification(action.type);
    yield put(push(routes.administrators.list));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* updateNegotiationTermsSaga(action) {
  const {
    negotiationId,
    contractId,
    nonNegotiatedTerms,
    offer,
    details,
    isSupplierResponse,
    noNonNegotiated,
    noOffer,
    noOfferReason,
    parentContractId,
    generateAutoPO,
    templateId
  } = action;
  try {
    yield call(
      updateNegotiationTerms,
      negotiationId,
      nonNegotiatedTerms,
      offer,
      details,
      isSupplierResponse,
      noNonNegotiated,
      noOffer,
      noOfferReason,
      parentContractId,
      generateAutoPO,
      templateId
    );
    yield putSuccessNotification(action.type);
    yield put(fetchContract(contractId));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* createNegotiationSaga(action) {
  const { negotiationData } = action;
  try {
    yield call(createNegotiation, negotiationData);
    yield putSuccessNotification(action.type);
    yield put(fetchContract(negotiationData.ContractId));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* updateNegotiationSaga(action) {
  const { id, negotiationData } = action;
  try {
    yield call(updateNegotiation, id, negotiationData);
    yield putSuccessNotification(action.type);
    yield put(fetchContract(negotiationData.ContractId));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* deleteNegotiationSaga(action) {
  const { id, contractId } = action;
  try {
    yield call(deleteNegotiation, id);
    yield putSuccessNotification(action.type);
    yield put(fetchContract(contractId));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* selectTermsSaga(action) {
  const { negotiationId, contractTermsId, contractId } = action;
  try {
    yield call(selectTerms, negotiationId, contractTermsId);
    yield putSuccessNotification(action.type);
    yield put(fetchContract(contractId));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* deleteTermsSaga(action) {
  const { contractTermsId, contractId } = action;
  try {
    yield call(deleteTerms, contractTermsId);
    yield putSuccessNotification(action.type);
    yield put(fetchContract(contractId));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* requestCompletionSaga(action) {
  const { negotiationId, message, contractId } = action;
  try {
    yield call(requestCompletion, negotiationId, message);
    yield putSuccessNotification(action.type);
    yield put(fetchContract(contractId));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* sendNegotiationProviderMessageSaga(action) {
  const { negotiationId, template } = action;
  try {
    yield call(sendNegotiationProviderMessage, negotiationId, template);
    yield putSuccessNotification(action.type);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* sendNegotiationCustomerMessageSaga(action) {
  const { negotiationId, template } = action;
  try {
    yield call(sendNegotiationCustomerMessage, negotiationId, template);
    yield putSuccessNotification(action.type);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* sendNegotiationToAddressMessageSaga(action) {
  const { negotiationId, template, toAddress, ccCustomer } = action;
  try {
    yield call(
      sendNegotiationToAddressMessage,
      negotiationId,
      template,
      toAddress,
      ccCustomer
    );
    yield putSuccessNotification(action.type);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* sendCustomerMessageSaga(action) {
  const { customerId, template } = action;
  try {
    yield call(sendCustomerMessage, customerId, template);
    yield putSuccessNotification(action.type);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* sendCustomerNotificationSaga(action) {
  const { email, firstName, lastName, template } = action;
  try {
    yield call(sendCustomerNotification, email, firstName, lastName, template);
    yield putSuccessNotification(action.type);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* updateNegotiationDatesSaga(action) {
  const { negotiationId, numberOfDays } = action;
  try {
    yield call(updateNegotiationDates, negotiationId, numberOfDays);
    yield putSuccessNotification(action.type);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* confirmTermsSaga(action) {
  const { negotiationId, contractId } = action;
  try {
    yield call(confirmTerms, negotiationId);
    yield putSuccessNotification(action.type);
    yield put(fetchContract(contractId));
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* getContractDocumentsSaga(action) {
  const { contractId } = action;
  try {
    yield fetchSagaHelper(action.type, getContractDocuments, [contractId]);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* getOfferTemplatesSaga(action) {
  const { contractId } = action;
  try {
    const data = yield call(getOfferTemplates, contractId);
    yield putSuccessData(action.type, data);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* fetchTemplatesSaga(action) {
  yield* fetchSagaHelper(action.type, getTemplates, []);
}

export function* updateTemplateSaga(action) {
  const { id, template } = action;
  try {
    yield call(updateTemplate, id, template);
    yield putSuccessNotification(action.type);
  } catch (error) {
    yield putFailure(action.type, error);
  }
}

export function* fetchMessageTemplatesSaga(action) {
  yield* fetchSagaHelper(action.type, getMessageTemplates, []);
}

const putSuccessData = (actionType, data) =>
  put({
    type: actionType,
    status: STATUS.SUCCESS,
    data
  });

const putSuccessNotification = actionType =>
  put({
    type: actionType,
    status: STATUS.SUCCESS,
    notification: true
  });

const putFailure = (actionType, error) =>
  put({
    type: actionType,
    status: STATUS.FAILURE,
    error: error.message
  });

const takeEveryRequest = (actionType, fetchSaga) =>
  takeEvery(
    action => action.type === actionType && action.status === STATUS.REQUESTED,
    fetchSaga
  );

function* watchers() {
  yield takeEveryRequest(API_ACTION_TYPES.FETCH_CONTRACT, fetchContractSaga);
  yield takeEveryRequest(API_ACTION_TYPES.CREATE_CONTRACT, createContractSaga);
  yield takeEveryRequest(API_ACTION_TYPES.UPDATE_CONTRACT, updateContractSaga);
  yield takeEveryRequest(API_ACTION_TYPES.DELETE_CONTRACT, deleteContractSaga);
  yield takeEveryRequest(
    API_ACTION_TYPES.IMPROVE_CONTRACT,
    improveContractSaga
  );
  yield takeEveryRequest(API_ACTION_TYPES.NOT_CUSTOMER, setNotCustomerSaga);
  yield takeEveryRequest(
    API_ACTION_TYPES.UPDATE_NEGOTIATION_TERMS,
    updateNegotiationTermsSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.CREATE_NEGOTIATION,
    createNegotiationSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.UPDATE_NEGOTIATION,
    updateNegotiationSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.DELETE_NEGOTIATION,
    deleteNegotiationSaga
  );
  yield takeEveryRequest(API_ACTION_TYPES.SELECT_TERMS, selectTermsSaga);
  yield takeEveryRequest(API_ACTION_TYPES.DELETE_TERMS, deleteTermsSaga);
  yield takeEveryRequest(
    API_ACTION_TYPES.REQUEST_COMPLETION,
    requestCompletionSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.SEND_NEGOTIATION_PROVIDER_MESSAGE,
    sendNegotiationProviderMessageSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.SEND_NEGOTIATION_CUSTOMER_MESSAGE,
    sendNegotiationCustomerMessageSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.SEND_NEGOTIATION_TOADDRESS_MESSAGE,
    sendNegotiationToAddressMessageSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.SEND_CUSTOMER_MESSAGE,
    sendCustomerMessageSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.SEND_CUSTOMER_NOTIFICATION,
    sendCustomerNotificationSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.UPDATE_NEGOTIATION_DATES,
    updateNegotiationDatesSaga
  );
  yield takeEveryRequest(API_ACTION_TYPES.CONFIRM_TERMS, confirmTermsSaga);
  yield takeEveryRequest(API_ACTION_TYPES.FETCH_METADATA, fetchMetadataSaga);
  yield takeEveryRequest(API_ACTION_TYPES.FETCH_CUSTOMER, fetchCustomerSaga);
  yield takeEveryRequest(API_ACTION_TYPES.UPDATE_CUSTOMER, updateCustomerSaga);
  yield takeEveryRequest(API_ACTION_TYPES.SEARCH, searchSaga);
  yield takeEveryRequest(API_ACTION_TYPES.FETCH_PROVIDERS, fetchProvidersSaga);
  yield takeEveryRequest(
    API_ACTION_TYPES.FETCH_CONTRACTS_WITH_CLOSED_NEGOTIATION,
    fetchContractsWithClosedNegotiationSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.FETCH_CONTRACTS_WITH_PAUSED_NEGOTIATION,
    fetchContractsWithPausedNegotiationSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.FETCH_CONTRACTS_WITH_UNPAUSED_NEGOTIATION,
    fetchContractsWithUnPausedNegotiationSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.FETCH_CONTRACTS_WITH_UNSELECTED_OFFERS,
    fetchContractsWithUnselectedOffersSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.FETCH_MANUAL_CONTRACTS_WITH_IMAGES,
    fetchOtherContractsWithImagesSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.FETCH_STATISTICS,
    fetchStatisticsSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.FETCH_TRANSLATIONS,
    fetchTranslationsSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.UPDATE_TRANSLATIONS,
    updateTranslationsSaga
  );
  yield takeEveryRequest(API_ACTION_TYPES.FETCH_MESSAGES, fetchMessagesSaga);
  yield takeEveryRequest(API_ACTION_TYPES.PROCESS_MESSAGE, processMessageSaga);
  yield takeEveryRequest(API_ACTION_TYPES.FETCH_PROVIDER, fetchProviderSaga);
  yield takeEveryRequest(API_ACTION_TYPES.UPDATE_PROVIDER, updateProviderSaga);
  yield takeEveryRequest(API_ACTION_TYPES.CREATE_PROVIDER, createProviderSaga);
  yield takeEveryRequest(API_ACTION_TYPES.DELETE_PROVIDER, deleteProviderSaga);
  yield takeEveryRequest(API_ACTION_TYPES.FETCH_JOBS, fetchJobsSaga);
  yield takeEveryRequest(API_ACTION_TYPES.START_JOB, startJobSaga);
  yield takeEveryRequest(API_ACTION_TYPES.CANCEL_JOB, cancelJobSaga);
  yield takeEveryRequest(
    API_ACTION_TYPES.FETCH_ADMINISTRATORS,
    fetchAdministratorsSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.FETCH_ADMINISTRATOR,
    fetchAdministratorSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.CREATE_ADMINISTRATOR,
    createAdministratorSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.UPDATE_ADMINISTRATOR,
    updateAdministratorSaga
  );
  yield takeEveryRequest(
    API_ACTION_TYPES.DELETE_ADMINISTRATOR,
    deleteAdministratorSaga
  );
  yield takeEveryRequest(API_ACTION_TYPES.DELETE_CUSTOMER, deleteCustomerSaga);
  yield takeEveryRequest(
    API_ACTION_TYPES.GET_OFFER_TEMPLATES,
    getOfferTemplatesSaga
  );
  yield takeEveryRequest(API_ACTION_TYPES.FETCH_TEMPLATES, fetchTemplatesSaga);
  yield takeEveryRequest(API_ACTION_TYPES.UPDATE_TEMPLATE, updateTemplateSaga);
  yield takeEveryRequest(
    API_ACTION_TYPES.FETCH_MESSAGETEMPLATES,
    fetchMessageTemplatesSaga
  );
}

export default function* root() {
  yield all([fork(watchers)]);
}
