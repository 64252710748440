export const DISMISS_ERROR = 'DISMISS_ERROR';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

export const API_ACTION_TYPES = {
  CREATE_CONTRACT: 'CREATE_CONTRACT',
  FETCH_CONTRACT: 'FETCH_CONTRACT',
  UPDATE_CONTRACT: 'UPDATE_CONTRACT',
  DELETE_CONTRACT: 'DELETE_CONTRACT',
  IMPROVE_CONTRACT: 'IMPROVE_CONTRACT',
  UPDATE_NEGOTIATION_TERMS: 'UPDATE_NEGOTIATION_TERMS',
  CREATE_NEGOTIATION: 'CREATE_NEGOTIATION',
  UPDATE_NEGOTIATION: 'UPDATE_NEGOTIATION',
  DELETE_NEGOTIATION: 'DELETE_NEGOTIATION',
  SELECT_TERMS: 'SELECT_TERMS',
  DELETE_TERMS: 'DELETE_TERMS',
  REQUEST_COMPLETION: 'REQUEST_COMPLETION',
  SEND_NEGOTIATION_PROVIDER_MESSAGE: 'SEND_NEGOTIATION_PROVIDER_MESSAGE',
  SEND_NEGOTIATION_CUSTOMER_MESSAGE: 'SEND_NEGOTIATION_CUSTOMER_MESSAGE',
  SEND_NEGOTIATION_TOADDRESS_MESSAGE: 'SEND_NEGOTIATION_TOADDRESS_MESSAGE',
  SEND_CUSTOMER_MESSAGE: 'SEND_CUSTOMER_MESSAGE',
  SEND_CUSTOMER_NOTIFICATION: 'SEND_CUSTOMER_NOTIFICATION',
  CONFIRM_TERMS: 'CONFIRM_TERMS',
  FETCH_METADATA: 'FETCH_METADATA',
  FETCH_CUSTOMER: 'FETCH_CUSTOMER',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  SEARCH: 'SEARCH',
  FETCH_PROVIDER: 'FETCH_PROVIDER',
  FETCH_PROVIDERS: 'FETCH_PROVIDERS',
  FETCH_CONTRACTS_WITH_CLOSED_NEGOTIATION:
    'FETCH_CONTRACTS_WITH_CLOSED_NEGOTIATION',
  FETCH_CONTRACTS_WITH_PAUSED_NEGOTIATION:
    'FETCH_CONTRACTS_WITH_PAUSED_NEGOTIATION',
  FETCH_CONTRACTS_WITH_UNPAUSED_NEGOTIATION:
    'FETCH_CONTRACTS_WITH_UNPAUSED_NEGOTIATION',
  FETCH_CONTRACTS_WITH_UNSELECTED_OFFERS:
    'FETCH_CONTRACTS_WITH_UNSELECTED_OFFERS',
  FETCH_MANUAL_CONTRACTS_WITH_IMAGES: 'FETCH_MANUAL_CONTRACTS_WITH_IMAGES',
  FETCH_STATISTICS: 'FETCH_STATISTICS',
  FETCH_TRANSLATIONS: 'FETCH_TRANSLATIONS',
  UPDATE_TRANSLATIONS: 'UPDATE_TRANSLATIONS',
  FETCH_MESSAGES: 'FETCH_MESSAGES',
  PROCESS_MESSAGE: 'PROCESS_MESSAGE',
  UPDATE_PROVIDER: 'UPDATE_PROVIDER',
  CREATE_PROVIDER: 'CREATE_PROVIDER',
  DELETE_PROVIDER: 'DELETE_PROVIDER',
  FETCH_JOBS: 'FETCH_JOBS',
  START_JOB: 'START_JOB',
  CANCEL_JOB: 'CANCEL_JOB',
  FETCH_ADMINISTRATORS: 'FETCH_ADMINISTRATORS',
  FETCH_ADMINISTRATOR: 'FETCH_ADMINISTRATOR',
  CREATE_ADMINISTRATOR: 'CREATE_ADMINISTRATOR',
  UPDATE_ADMINISTRATOR: 'UPDATE_ADMINISTRATOR',
  DELETE_ADMINISTRATOR: 'DELETE_ADMINISTRATOR',
  NOT_CUSTOMER: 'NOT_CUSTOMER',
  UPDATE_NEGOTIATION_DATES: 'UPDATE_NEGOTIATION_DATES',
  GET_OFFER_TEMPLATES: 'GET_OFFER_TEMPLATES',
  FETCH_TEMPLATES: 'FETCH_TEMPLATES',
  UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
  FETCH_MESSAGETEMPLATES: 'FETCH_MESSAGETEMPLATES'
};

export const STATUS = {
  REQUESTED: 'REQUESTED',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};

export const THEME = {
  CHANGE: 'CHANGE'
};

export function fetchMetadata() {
  return {
    type: API_ACTION_TYPES.FETCH_METADATA,
    status: STATUS.REQUESTED
  };
}

export function fetchCustomer(id) {
  return {
    type: API_ACTION_TYPES.FETCH_CUSTOMER,
    status: STATUS.REQUESTED,
    id
  };
}

export function search(query) {
  return {
    type: API_ACTION_TYPES.SEARCH,
    status: STATUS.REQUESTED,
    query
  };
}

export function fetchProviders() {
  return {
    type: API_ACTION_TYPES.FETCH_PROVIDERS,
    status: STATUS.REQUESTED
  };
}

export function createContract(contract) {
  return {
    type: API_ACTION_TYPES.CREATE_CONTRACT,
    status: STATUS.REQUESTED,
    contract
  };
}

export function fetchContract(id) {
  return {
    type: API_ACTION_TYPES.FETCH_CONTRACT,
    status: STATUS.REQUESTED,
    id
  };
}

export function fetchContractsWithClosedNegotiation() {
  return {
    type: API_ACTION_TYPES.FETCH_CONTRACTS_WITH_CLOSED_NEGOTIATION,
    status: STATUS.REQUESTED
  };
}

export function fetchContractsWithPausedNegotiation() {
  return {
    type: API_ACTION_TYPES.FETCH_CONTRACTS_WITH_PAUSED_NEGOTIATION,
    status: STATUS.REQUESTED
  };
}

export function fetchContractsWithUnPausedNegotiation() {
  return {
    type: API_ACTION_TYPES.FETCH_CONTRACTS_WITH_UNPAUSED_NEGOTIATION,
    status: STATUS.REQUESTED
  };
}

export function fetchContractsWithUnselectedOffers() {
  return {
    type: API_ACTION_TYPES.FETCH_CONTRACTS_WITH_UNSELECTED_OFFERS,
    status: STATUS.REQUESTED
  };
}

export function fetchOtherContractsWithImages() {
  return {
    type: API_ACTION_TYPES.FETCH_MANUAL_CONTRACTS_WITH_IMAGES,
    status: STATUS.REQUESTED
  };
}

export function fetchStatistics() {
  return {
    type: API_ACTION_TYPES.FETCH_STATISTICS,
    status: STATUS.REQUESTED
  };
}

export function fetchTranslations() {
  return {
    type: API_ACTION_TYPES.FETCH_TRANSLATIONS,
    status: STATUS.REQUESTED
  };
}

export function updateTranslations(json) {
  return {
    type: API_ACTION_TYPES.UPDATE_TRANSLATIONS,
    status: STATUS.REQUESTED,
    json
  };
}

export function updateNegotiationTerms(
  negotiationId,
  contractId,
  nonNegotiatedTerms,
  offer,
  details,
  isSupplierResponse,
  noNonNegotiated,
  noOffer,
  noOfferReason,
  parentContractId,
  generateAutoPO,
  templateId
) {
  return {
    type: API_ACTION_TYPES.UPDATE_NEGOTIATION_TERMS,
    status: STATUS.REQUESTED,
    negotiationId,
    contractId,
    nonNegotiatedTerms,
    offer,
    details,
    isSupplierResponse,
    noNonNegotiated,
    noOffer,
    noOfferReason,
    parentContractId,
    generateAutoPO,
    templateId
  };
}

export function createNegotiation(negotiationData) {
  return {
    type: API_ACTION_TYPES.CREATE_NEGOTIATION,
    status: STATUS.REQUESTED,
    negotiationData
  };
}

export function updateNegotiation(id, negotiationData) {
  return {
    type: API_ACTION_TYPES.UPDATE_NEGOTIATION,
    status: STATUS.REQUESTED,
    id,
    negotiationData
  };
}

export function deleteNegotiation(id, contractId) {
  return {
    type: API_ACTION_TYPES.DELETE_NEGOTIATION,
    status: STATUS.REQUESTED,
    id,
    contractId
  };
}

export function updateContract(
  id,
  preferences,
  attributes,
  isNonNegotiable,
  contractDetails,
  contractState
) {
  return {
    type: API_ACTION_TYPES.UPDATE_CONTRACT,
    status: STATUS.REQUESTED,
    id,
    preferences,
    attributes,
    isNonNegotiable,
    contractDetails,
    contractState
  };
}

export function dismissError() {
  return {
    type: DISMISS_ERROR
  };
}

export function dismissNotification() {
  return {
    type: DISMISS_NOTIFICATION
  };
}

export function updateCustomer(customer) {
  return {
    type: API_ACTION_TYPES.UPDATE_CUSTOMER,
    status: STATUS.REQUESTED,
    customer
  };
}

export function fetchMessages() {
  return {
    type: API_ACTION_TYPES.FETCH_MESSAGES,
    status: STATUS.REQUESTED
  };
}

export function processMessage(id) {
  return {
    type: API_ACTION_TYPES.PROCESS_MESSAGE,
    status: STATUS.REQUESTED,
    id
  };
}

export function fetchProvider(id) {
  return {
    type: API_ACTION_TYPES.FETCH_PROVIDER,
    status: STATUS.REQUESTED,
    id
  };
}

export function updateProvider(providerId, provider) {
  return {
    type: API_ACTION_TYPES.UPDATE_PROVIDER,
    status: STATUS.REQUESTED,
    providerId,
    provider
  };
}

export function createProvider(provider) {
  return {
    type: API_ACTION_TYPES.CREATE_PROVIDER,
    status: STATUS.REQUESTED,
    provider
  };
}

export function deleteProvider(id) {
  return {
    type: API_ACTION_TYPES.DELETE_PROVIDER,
    status: STATUS.REQUESTED,
    id
  };
}

export function deleteCustomer(id) {
  return {
    type: API_ACTION_TYPES.DELETE_CUSTOMER,
    status: STATUS.REQUESTED,
    id
  };
}

export function deleteContract(id, customerId) {
  return {
    type: API_ACTION_TYPES.DELETE_CONTRACT,
    status: STATUS.REQUESTED,
    id,
    customerId
  };
}

export function improveContract(id) {
  return {
    type: API_ACTION_TYPES.IMPROVE_CONTRACT,
    status: STATUS.REQUESTED,
    id
  };
}

export function setNotCustomer(id, customerId) {
  return {
    type: API_ACTION_TYPES.NOT_CUSTOMER,
    status: STATUS.REQUESTED,
    id,
    customerId
  };
}

export function fetchJobs() {
  return {
    type: API_ACTION_TYPES.FETCH_JOBS,
    status: STATUS.REQUESTED
  };
}

export function startJob(id) {
  return {
    type: API_ACTION_TYPES.START_JOB,
    status: STATUS.REQUESTED,
    id
  };
}

export function cancelJob(id) {
  return {
    type: API_ACTION_TYPES.CANCEL_JOB,
    status: STATUS.REQUESTED,
    id
  };
}

export function fetchAdministrators() {
  return {
    type: API_ACTION_TYPES.FETCH_ADMINISTRATORS,
    status: STATUS.REQUESTED
  };
}

export function fetchAdministrator(id) {
  return {
    type: API_ACTION_TYPES.FETCH_ADMINISTRATOR,
    status: STATUS.REQUESTED,
    id
  };
}

export function deleteAdministrator(id) {
  return {
    type: API_ACTION_TYPES.DELETE_ADMINISTRATOR,
    status: STATUS.REQUESTED,
    id
  };
}

export function createAdministrator(administrator) {
  return {
    type: API_ACTION_TYPES.CREATE_ADMINISTRATOR,
    status: STATUS.REQUESTED,
    administrator
  };
}

export function updateAdministrator(administrator) {
  return {
    type: API_ACTION_TYPES.UPDATE_ADMINISTRATOR,
    status: STATUS.REQUESTED,
    administrator
  };
}

export function selectTerms(negotiationId, contractTermsId, contractId) {
  return {
    type: API_ACTION_TYPES.SELECT_TERMS,
    status: STATUS.REQUESTED,
    negotiationId,
    contractTermsId,
    contractId
  };
}

export function deleteTerms(contractTermsId, contractId) {
  return {
    type: API_ACTION_TYPES.DELETE_TERMS,
    status: STATUS.REQUESTED,
    contractTermsId,
    contractId
  };
}

export function requestCompletion(negotiationId, message, contractId) {
  return {
    type: API_ACTION_TYPES.REQUEST_COMPLETION,
    status: STATUS.REQUESTED,
    negotiationId,
    message,
    contractId
  };
}

export function sendNegotiationProviderMessage(negotiationId, template) {
  return {
    type: API_ACTION_TYPES.SEND_NEGOTIATION_PROVIDER_MESSAGE,
    status: STATUS.REQUESTED,
    negotiationId,
    template
  };
}

export function sendNegotiationCustomerMessage(negotiationId, template) {
  return {
    type: API_ACTION_TYPES.SEND_NEGOTIATION_CUSTOMER_MESSAGE,
    status: STATUS.REQUESTED,
    negotiationId,
    template
  };
}

export function sendNegotiationToAddressMessage(
  negotiationId,
  template,
  toAddress,
  ccCustomer
) {
  return {
    type: API_ACTION_TYPES.SEND_NEGOTIATION_TOADDRESS_MESSAGE,
    status: STATUS.REQUESTED,
    negotiationId,
    template,
    toAddress,
    ccCustomer
  };
}

export function sendCustomerMessage(customerId, template) {
  return {
    type: API_ACTION_TYPES.SEND_CUSTOMER_MESSAGE,
    status: STATUS.REQUESTED,
    customerId,
    template
  };
}

export function sendCustomerNotification(email, firstName, lastName, template) {
  return {
    type: API_ACTION_TYPES.SEND_CUSTOMER_NOTIFICATION,
    status: STATUS.REQUESTED,
    email,
    firstName,
    lastName,
    template
  };
}

export function updateNegotiationDates(negotiationId, numberOfDays) {
  return {
    type: API_ACTION_TYPES.UPDATE_NEGOTIATION_DATES,
    status: STATUS.REQUESTED,
    negotiationId,
    numberOfDays
  };
}

export function confirmTerms(negotiationId, contractId) {
  return {
    type: API_ACTION_TYPES.CONFIRM_TERMS,
    status: STATUS.REQUESTED,
    negotiationId,
    contractId
  };
}

export function changeTheme(theme) {
  return {
    type: THEME.CHANGE,
    status: STATUS.REQUESTED,
    theme
  };
}

export function getOfferTemplates(contractId) {
  return {
    type: API_ACTION_TYPES.GET_OFFER_TEMPLATES,
    status: STATUS.REQUESTED,
    contractId
  };
}

export function fetchTemplates() {
  return {
    type: API_ACTION_TYPES.FETCH_TEMPLATES,
    status: STATUS.REQUESTED
  };
}

export function updateTemplate(id, template) {
  return {
    type: API_ACTION_TYPES.UPDATE_TEMPLATE,
    status: STATUS.REQUESTED,
    id,
    template
  };
}

export function fetchMessageTemplates() {
  return {
    type: API_ACTION_TYPES.FETCH_MESSAGETEMPLATES,
    status: STATUS.REQUESTED
  };
}
