"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectors = exports.removeNullValuesFromView = exports.buildViewArray = exports.getContractTypeMetadataForView = exports.getContractPreferencesFieldsFromMetadata = void 0;

var _domainComponents = require("domain-components");

var _ = require("..");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var isPartialNegotiation = function isPartialNegotiation(state) {
  switch (state) {
    case 'SupplierResponded':
    case 'TermsReceived':
    case 'OfferReceived':
    case 'Confirmed':
    case 'UserNotCustomerOfSupplier':
      return true;

    default:
      return false;
  }
};

var getNegotiationFieldsForView = function getNegotiationFieldsForView(state, view) {
  if (state && state.metadata && state.metadata.data && state.negotiation && state.negotiation.data) {
    if (isPartialNegotiation(state.negotiation.data.State)) return null;
    var contractType = state.negotiation.data.ContractType;
    var metadata = state.metadata.data.contractTypes[contractType];

    if (metadata.views != null && view != null && metadata.views[view] != null) {
      return metadata.views[view].map(function (field) {
        var _dataTypes$field$type;

        return _objectSpread(_objectSpread({}, field), {}, {
          type: (_dataTypes$field$type = _domainComponents.dataTypes[field.type]) !== null && _dataTypes$field$type !== void 0 ? _dataTypes$field$type : null
        });
      });
    }

    return metadata;
  }

  return null;
};

var getContractAttributeFieldsFromMetadata = function getContractAttributeFieldsFromMetadata(metadata, contractType) {
  var _metadata$contractTyp, _metadata$contractTyp2, _metadata$contractTyp3;

  var attributes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'contractAttributes';

  if (metadata !== null && metadata !== void 0 && (_metadata$contractTyp = metadata.contractTypes) !== null && _metadata$contractTyp !== void 0 && (_metadata$contractTyp2 = _metadata$contractTyp[contractType]) !== null && _metadata$contractTyp2 !== void 0 && (_metadata$contractTyp3 = _metadata$contractTyp2.views) !== null && _metadata$contractTyp3 !== void 0 && _metadata$contractTyp3[attributes]) {
    return metadata.contractTypes[contractType].views[attributes].map(function (a) {
      return _objectSpread(_objectSpread({}, a), {}, {
        type: _domainComponents.dataTypes[a.type]
      });
    });
  }

  return null;
};

var getManualContractAttributeFieldsFromMetadata = function getManualContractAttributeFieldsFromMetadata(metadata, contractType) {
  if (metadata && metadata.contractTypes && metadata.contractTypes[contractType] && metadata.contractTypes[contractType].views && metadata.contractTypes[contractType].views.addManualContractAttributes) {
    return metadata.contractTypes[contractType].views.addManualContractAttributes.map(function (a) {
      return _objectSpread(_objectSpread({}, a), {}, {
        type: _domainComponents.dataTypes[a.type]
      });
    });
  }

  return null;
};

var getContractPreferencesFieldsFromMetadata = function getContractPreferencesFieldsFromMetadata(metadata, contractType) {
  if (metadata && metadata.contractTypes && metadata.contractTypes[contractType] && metadata.contractTypes[contractType].views && metadata.contractTypes[contractType].views.contractPreferences) {
    return metadata.contractTypes[contractType].views.contractPreferences.map(function (field) {
      return _objectSpread(_objectSpread({}, field), {}, {
        type: _domainComponents.dataTypes[field.type]
      });
    });
  }

  return null;
};

exports.getContractPreferencesFieldsFromMetadata = getContractPreferencesFieldsFromMetadata;

var getContractTypeMetadataForView = function getContractTypeMetadataForView(state, view, contractType) {
  if (!state || !view || !contractType) return [];

  try {
    var contractTypeMetadata = state.metadata.contractTypes[contractType].views[view];
    return contractTypeMetadata ? contractTypeMetadata : [];
  } catch (e) {
    _.ErrorHandler.logException(e);

    return [];
  }
};

exports.getContractTypeMetadataForView = getContractTypeMetadataForView;

var buildViewArray = function buildViewArray() {
  var views = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var viewString = arguments.length > 1 ? arguments[1] : undefined;

  if (!Object.keys(views).length || !viewString) {
    throw new Error('Views(object) and viewString(string) must be specified.');
  }

  return Object.keys(views).reduce(function (result, viewKey) {
    if (viewKey.includes("".concat(viewString, "-"))) {
      result.push(views[viewKey]);
    }

    return result;
  }, []);
};

exports.buildViewArray = buildViewArray;

var removeNullValuesFromView = function removeNullValuesFromView(view, values) {
  if (!view || !values) {
    throw new Error('Missing argument');
  }

  var returnArray = view.map(function (field) {
    return !!values[field.key] || !!Object.keys((field === null || field === void 0 ? void 0 : field.formatReferenceFields) || {}).length ? field : null;
  });
  return returnArray.filter(Boolean);
};

exports.removeNullValuesFromView = removeNullValuesFromView;
var selectors = {
  getNegotiationFieldsForView: getNegotiationFieldsForView,
  getContractAttributeFieldsFromMetadata: getContractAttributeFieldsFromMetadata,
  getContractPreferencesFieldsFromMetadata: getContractPreferencesFieldsFromMetadata,
  getContractTypeMetadataForView: getContractTypeMetadataForView,
  getManualContractAttributeFieldsFromMetadata: getManualContractAttributeFieldsFromMetadata,
  buildViewArray: buildViewArray,
  removeNullValuesFromView: removeNullValuesFromView
};
exports.selectors = selectors;