"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.substitution = void 0;

var substituteString = function substituteString(string, substitutionKey, substitute) {
  var caseInsensitiveSubstitute = new RegExp(substitutionKey, 'gi');
  return string.replace(caseInsensitiveSubstitute, substitute);
};

var substitution = {
  string: substituteString
};
exports.substitution = substitution;