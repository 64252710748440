import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Button } from 'pamind-ui';
import { TextField } from 'pamind-ui';
import { updateNegotiationDates } from 'state/actions';

export class Component extends React.Component {
  state = {
    numberOfDays: 0
  };

  handleChangeDays = event =>
    this.setState({ numberOfDays: event.target.value });

  handleClick = (event, template) => {
    this.props.dispatch(
      updateNegotiationDates(this.props.negotiationId, this.state.numberOfDays)
    );
  };

  render() {
    return (
      <span>
        <TextField
          id="numberOfDays"
          label="Antal dagar för att flytta alla datum bakåt i tiden"
          type="number"
          margin="normal"
          style={{ width: '15rem', margin: 0 }}
          onChange={this.handleChangeDays}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            min: 0
          }}
          variant="outlined"
        />
        <Button
          size="small"
          onClick={this.handleClick}
          color="primary"
          variant="outlined"
        >
          Uppdatera datum
        </Button>
      </span>
    );
  }
}

Component.propTypes = {
  dispatch: PropTypes.func,
  negotiationId: PropTypes.number
};

export const UpdateNegotiationDates = connect()(withStyles()(Component));
