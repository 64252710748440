"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatPersonalNumber = void 0;

var formatPersonalNumber = function formatPersonalNumber(personalnumber) {
  try {
    if (typeof personalnumber === 'number') {
      personalnumber = personalnumber.toString();
    }

    var dates = personalnumber.substring(0, personalnumber.length - 4);
    var controllNumber = personalnumber.substring(personalnumber.length - 4, personalnumber.length);
    return "".concat(dates, "-").concat(controllNumber);
  } catch (error) {
    return;
  }
};

exports.formatPersonalNumber = formatPersonalNumber;