import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestCompletion } from 'state/actions';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from 'pamind-ui';
import QuestionAnswer from '@mui/icons-material/QuestionAnswer';

export class Component extends React.Component {
  state = {
    newRequestOpen: false,
    requestMessage: ''
  };

  handleOpen = () => {
    this.setState({ newRequestOpen: true });
  };

  handleClose = () => {
    this.setState({ newRequestOpen: false });
  };

  handleRequestCompletion = () => {
    this.props.dispatch(
      requestCompletion(
        this.props.negotiation.Id,
        this.state.requestMessage,
        this.props.contractId
      )
    );

    this.setState({
      newRequestOpen: false
    });
  };

  handleChangeRequestMessage = (event, value) => {
    this.setState({ requestMessage: event.target.value });
  };

  handleChangeContractType = (event, index, value) => {
    this.setState({
      newContractType: value,
      newProviderId: null
    });
  };

  render() {
    return (
      <span>
        <Button
          variant="contained"
          color={this.props.color}
          style={this.props.style}
          onClick={this.handleOpen}
          size="small"
        >
          <QuestionAnswer />
          Begär komplettering
        </Button>
        <Dialog
          open={this.state.newRequestOpen}
          onRequestClose={this.handleClose}
        >
          <DialogTitle>
            {'Begär komplettering från ' + this.props.contractProvider}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Skriv en text nedan som beskriver nödvändig komplettering.
            </DialogContentText>
            <TextField
              fullWidth={true}
              multiLine={true}
              rows={5}
              autoFocus={true}
              value={this.state.requestMessage}
              onChange={this.handleChangeRequestMessage}
              defaultValue={this.state.requestMessage}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="primary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              keyboardFocused={true}
              disabled={this.state.requestMessage === ''}
              onClick={this.handleRequestCompletion}
              size="small"
            >
              Skicka
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  }
}

Component.propTypes = {
  dispatch: PropTypes.func,
  metadata: PropTypes.object,
  negotiation: PropTypes.object,
  providers: PropTypes.array,
  contractId: PropTypes.number,
  contractProvider: PropTypes.string,
  signedPowerOfAttorney: PropTypes.bool,
  style: PropTypes.object,
  color: PropTypes.string
};

export const RequestCompletion = connect(state => ({
  metadata: state.metadata
}))(Component);
