import React from 'react';
import { ContractsWithPausedNegotiation } from 'components/lists/ContractsWithPausedNegotiation';

export class PausedNegotiationsList extends React.Component {
  render() {
    return (
      <div>
        <ContractsWithPausedNegotiation />
      </div>
    );
  }
}
