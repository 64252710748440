import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  flex: {
    flex: 'auto'
  }
};

function fillSpace(elm) {
  if (elm.length > 1) {
    return elm.map((child, index) => {
      return React.cloneElement(child, {
        key: index,
        style: {
          ...styles.flex,
          ...(child.props.style ? child.props.style : null)
        }
      });
    });
  }
  return React.cloneElement(elm, {
    style: {
      ...styles.flex,
      ...(elm.props.style ? elm.props.style : null)
    }
  });
}

export function Component(props) {
  const childElements = props.fillSpace
    ? fillSpace(props.children)
    : props.children;
  return <div className={props.classes.container}>{childElements}</div>;
}
Component.propTypes = {
  children: PropTypes.node,
  fillSpace: PropTypes.bool,
  classes: PropTypes.object
};

export const AlignHorizontally = withStyles(styles, {
  name: 'AlignHorizontally'
})(Component);
