"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNegotiationStates = getNegotiationStates;

function getNegotiationStates() {
  return [{
    state: 'Waiting',
    label: 'Väntar på startdatum',
    description: 'Förhandlingen kommer starta när startdatumet inträffar givet att kunden har tagit fullmakten'
  }, {
    state: 'TermsRequested',
    label: 'Väntar på information/erbjudande',
    description: 'När vi har fått informationen om avtalet från leverantör så fyll i formulären och tryck på knappen',
    nextState: 'OfferReceived'
  }, {
    state: 'SupplierResponded',
    label: 'Väntar på komplettering',
    description: 'Leverantören har svarat, men vi väntar på mer information och verifiering',
    nextState: 'OfferReceived'
  }, {
    state: 'OfferReceived',
    label: 'Väntar på kundsvar',
    description: 'Välj erbjudande eller ickeförhandlat'
  }, {
    state: 'Selected',
    label: 'Väntar på leverantörsbekräftelse',
    description: 'När leverantör har bekräftat, tryck på knappen',
    nextState: 'Confirmed'
  }, {
    state: 'Confirmed',
    label: 'Avtalet är bekräftat och förhandligen avslutad',
    description: 'Avtalet är bekräftat och förhandligen avslutad'
  }, {
    state: 'UserNotCustomerOfSupplier',
    label: 'Avtalet kan inte bevakas',
    description: 'Du står inte på avtalet'
  }];
}