"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.navbarSharedStyle = void 0;
var navbarSharedStyle = {
  /**
   *
   * @param {'md'|'xs'} size
   */
  getNavbarHeight: function getNavbarHeight(size, theme) {
    return {
      md: "calc(".concat(theme.spacing(20), " + var(--safe-area-inset-top, 0px))"),
      xs: "calc(".concat(theme.spacing(18), " + var(--safe-area-inset-bottom, 0px))")
    }[size];
  },

  /**
   *
   * @param {'md'|'xs'} size
   * @returns {{top:string}|{bottom:string}}
   */
  getNavbarPadding: function getNavbarPadding(size, theme) {
    return {
      md: {
        top: "max(".concat(theme.spacing(2), ", var(--safe-area-inset-top, 0px))")
      },
      xs: {
        bottom: "max(".concat(theme.spacing(1), ", var(--safe-area-inset-bottom, 0px))")
      }
    }[size];
  }
};
exports.navbarSharedStyle = navbarSharedStyle;