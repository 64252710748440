import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { MenuItem } from 'pamind-ui';
import { FormControl } from 'pamind-ui';
import { Select } from 'pamind-ui';
import { changeTheme } from 'state/actions';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(),
    minWidth: 120
  },
  wrapper: {
    margin: '0 1rem'
  }
});

export class Component extends React.Component {
  state = {
    theme: null,
    open: false
  };
  componentDidMount() {
    const activeTheme = localStorage.getItem('theme')
      ? localStorage.getItem('theme')
      : this.props.theme;
    this.setState({
      theme: activeTheme
    });
    this.props.dispatch(changeTheme(activeTheme));
  }

  handleChange = event => {
    this.setState({ theme: event.target.value });
    localStorage.setItem('theme', event.target.value);
    this.props.dispatch(changeTheme(event.target.value));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { classes } = this.props;
    if (this.state.theme === null) return null;
    return (
      <form className={classes.wrapper} autoComplete="off">
        <FormControl className={classes.formControl}>
          <Select
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={this.state.theme}
            onChange={this.handleChange}
          >
            <MenuItem value={'light'}>Light</MenuItem>
            <MenuItem value={'dark'}>Dark</MenuItem>
            <MenuItem value={'funkyBlue'}>FunkyBlue</MenuItem>
            <MenuItem value={'coolBanana'}>
              <span aria-label="banana" role="img">
                🍌 Cool Banana 🍌
              </span>
            </MenuItem>
            <MenuItem value={'forestGreen'}>
              <span aria-label="Tree" role="img">
                🌲🌲 Forest Green 🌲🌲🌲
              </span>
            </MenuItem>
          </Select>
        </FormControl>
      </form>
    );
  }
}
Component.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  dispatch: PropTypes.func
};

export const SelectTheme = connect(state => {
  return { theme: state.theme };
})(withStyles(styles)(Component));
