import React from 'react';
import { SearchInputView } from 'components/search/SearchInputView';
import { SearchResultsView } from 'components/search/SearchResultsView';
import { VerticalSpacing } from 'domain-components';

export class SearchContainer extends React.Component {
  render() {
    return (
      <VerticalSpacing>
        <SearchInputView />
        <SearchResultsView />
      </VerticalSpacing>
    );
  }
}
