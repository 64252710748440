import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replaceId, routes } from 'paths';
import { fetchContractsWithClosedNegotiation } from 'state/actions';
import { Card } from 'pamind-ui';
import { CardContent } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { Table } from 'pamind-ui';
import { TableBody } from 'pamind-ui';
import { TableCell } from 'pamind-ui';
import { TableHead } from 'pamind-ui';
import { TableRow } from 'pamind-ui';
import { Link } from 'react-router-dom';

export class Component extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchContractsWithClosedNegotiation());
  }

  render() {
    if (!this.props.contracts) {
      return null;
    }

    return (
      <Card>
        <CardHeader title={'Avtal vars senaste förhandling är stängd'} />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Avtalstyp</TableCell>
                <TableCell>Personnummer</TableCell>
                <TableCell>Leverantör</TableCell>
                <TableCell>Tillstånd</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.contracts.map(c => (
                <TableRow
                  component={Link}
                  to={replaceId(routes.contracts.show, c.Id)}
                  key={c.Id}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{c.ContractType}</TableCell>
                  <TableCell>{c.CustomerSecurityNumber}</TableCell>
                  <TableCell>{c.ProviderName}</TableCell>
                  <TableCell>{c.NegotiationState}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}

Component.propTypes = {
  contracts: PropTypes.array,
  dispatch: PropTypes.func
};

export const ContractsWithClosedNegotiation = connect(state => ({
  contracts: state.contractsWithClosedNegotiation
}))(Component);
