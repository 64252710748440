import React from 'react';
import { setLoggedIn } from 'services/auth';
import { login } from 'services/api';
import { AuthView } from 'components/auth/AuthView';

export class AuthContainer extends React.Component {
  state = {
    isAuthenticating: false,
    error: false
  };

  handleLogin = (email, password) => {
    this.setState({ isAuthenticating: true, error: false });

    login(email, password).then(
      token => {
        setLoggedIn(token);
      },
      error => {
        this.setState({
          isAuthenticating: false,
          error: true
        });
      }
    );
  };

  render() {
    return (
      <AuthView
        submitLogin={this.handleLogin}
        isAuthenticating={this.state.isAuthenticating}
        error={this.state.error}
      />
    );
  }
}
