import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TextField,
  TableHead,
  TableRow,
  IconButton
} from 'pamind-ui';
import { PageHeader } from 'domain-components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TemplateListItem } from './TemplateListItem';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplates } from 'state/actions';

const tableHead = [
  {
    label: 'Namn',
    id: 'Name',
    disablePadding: true
  },
  {
    label: 'Kontraktstyp',
    id: 'ContractType',
    disablePadding: true
  },
  {
    label: 'Leverantör',
    id: 'ProviderName',
    disablePadding: true
  },
  { label: '', id: 'Icon' }
];

const convertToLowerCase = (str = '') => str.toLowerCase();

const filterItems = (items, value) => {
  const currentTemplateList = items;
  const newTemplateList = items.filter(i => {
    const templateName = convertToLowerCase(i?.TemplateName);
    const providerName = convertToLowerCase(i?.ProviderName);
    const contractType = convertToLowerCase(i?.ContractType);
    return (
      templateName.includes(value) ||
      providerName.includes(value) ||
      contractType.includes(value)
    );
  });
  if (!value.length) {
    return currentTemplateList;
  }
  return newTemplateList;
};

export const TemplateList = () => {
  const [searchValue, setSearchValue] = useState('');
  const [showSupplierList, setShowSupplierList] = useState(true);
  const [templateList, setTemplateList] = useState(null);
  const dispatch = useDispatch();
  const templates = useSelector(state => state.templates);

  const supplierButtonProps = !showSupplierList
    ? { variant: 'outlined', color: 'black' }
    : null;
  const poButtonProps = showSupplierList
    ? { variant: 'outlined', color: 'black' }
    : null;

  const handleOnSearchChange = event => {
    const currentSearchValue = event.target.value;
    const filtredList =
      filterItems(templates, convertToLowerCase(currentSearchValue)) ??
      templateList;
    setSearchValue(currentSearchValue);
    setTemplateList(filtredList);
  };

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [dispatch]);

  useEffect(() => {
    setTemplateList(templates);
  }, [templates]);

  return (
    <Box paddingBlock={8}>
      <PageHeader
        header="Mallar"
        subheader="Uppdatera leverantörs eller Påmind offer mallar"
        reduceBottomMargin
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          alignItems: 'center',
          width: '100%',
          gap: 4,
          paddingTop: 6
        }}
      >
        <FormGroup
          sx={{
            display: 'grid',
            gridTemplateColumns: '2fr auto',
            gap: 2
          }}
        >
          <TextField
            label="Sök efter mall, leverantör eller kontraktstyp"
            placeholder="ex. Vimla Fri Surf..."
            value={searchValue}
            onChange={handleOnSearchChange}
          />
        </FormGroup>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button
            {...supplierButtonProps}
            onClick={() => setShowSupplierList(true)}
          >
            Leverantör
          </Button>
          <Button {...poButtonProps} onClick={() => setShowSupplierList(false)}>
            Påmind Offer
          </Button>
          <IconButton
            color="black"
            size="large"
            onClick={() =>
              alert(
                'Oups funktionalitet finns ej än för att skapa mallar, ha tålamod!'
              )
            }
          >
            <AddCircleIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ marginBlock: 4 }} />
      <Table>
        <TableHead>
          <TableRow>
            {tableHead.map(item => (
              <TableCell sx={{ fontWeight: 'bold' }}>{item.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {templateList?.map(listItem => {
            if (
              (listItem.IsPamindOffer && !showSupplierList) ||
              (!listItem.IsPamindOffer && showSupplierList)
            ) {
              return <TemplateListItem key={listItem.Id} listItem={listItem} />;
            } else {
              return null;
            }
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
