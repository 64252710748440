export function isNullOrEmpty(value) {
  if (value === null) {
    return true;
  }
  if (value === '') {
    return true;
  }
  if (Array.isArray(value) === true && value.length === 0) {
    return true;
  }

  return false;
}
