import {
  grey300,
  grey400,
  grey500,
  white,
  darkBlack,
  fullBlack
} from 'material-ui/styles/colors';
import { fade } from 'material-ui/utils/colorManipulator';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { LightRed, Green, Beige } from 'theme/PamindColors';

const theme = {
  fontFamily: 'Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif',
  palette: {
    primary1Color: LightRed,
    primary2Color: LightRed,
    primary3Color: grey400,
    accent1Color: Green,
    accent2Color: Beige,
    accent3Color: grey500,
    textColor: darkBlack,
    alternateTextColor: white,
    canvasColor: white,
    borderColor: grey300,
    disabledColor: fade(darkBlack, 0.3),
    pickerHeaderColor: LightRed,
    clockCircleColor: fade(LightRed, 0.07),
    shadowColor: fullBlack
  }
};
export const PamindTheme = getMuiTheme(theme);
