import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replaceId, routes } from 'paths';
import { fetchContractsWithUnselectedOffers } from 'state/actions';
import { Card } from 'pamind-ui';
import { CardContent } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { Table } from 'pamind-ui';
import { TableBody } from 'pamind-ui';
import { TableCell } from 'pamind-ui';
import { TableHead } from 'pamind-ui';
import { TableRow } from 'pamind-ui';
import { Typography } from 'pamind-ui';
import { Link } from 'react-router-dom';
import moment from 'moment';

export class Component extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchContractsWithUnselectedOffers());
  }

  render() {
    if (!this.props.contracts) {
      return null;
    }
    if (this.props.contracts.Length < 1) {
      return (
        <Card>
          <CardHeader title={'Inga aktuella erbjudanden'} />
        </Card>
      );
    }
    return (
      <Card>
        <CardHeader title={'Avtal som har aktuella erbjudanden'} />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Namn</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Telefonnummer</TableCell>
                <TableCell>Leverantör</TableCell>
                <TableCell>OfferCategory</TableCell>
                <TableCell>Förhandlingens start</TableCell>
                <TableCell>Erbjudande skapat</TableCell>
                <TableCell>
                  <Typography component="strong" variant="strong">
                    Erbjudandets sista svarsdag
                  </Typography>
                </TableCell>
                <TableCell>Förhandlingens förfallodag</TableCell>
                <TableCell>Påminnelse skickad</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.contracts.map(c => (
                <TableRow>
                  <TableCell
                    component={Link}
                    to={replaceId(routes.contracts.show, c.Id)}
                    key={c.Id}
                    style={{ cursor: 'pointer' }}
                  >
                    {c.Name}
                  </TableCell>
                  <TableCell component="a" href={`mailto:${c.Email}`}>
                    {c.Email}
                  </TableCell>
                  <TableCell>{c.MobileNumber}</TableCell>
                  <TableCell>{c.ProviderName}</TableCell>
                  <TableCell>{c.OfferCategory}</TableCell>
                  <TableCell>
                    {`▶ ${moment(c.StartDate).format('YYYY-MM-DD')}`}
                  </TableCell>
                  <TableCell>
                    {!!c.OfferCreatedDate &&
                      `🎁 ${moment(c.OfferCreatedDate).format('YYYY-MM-DD')}`}
                  </TableCell>
                  <TableCell>
                    <Typography component="strong" variant="strong">
                      {`⏹ ${moment(c.LastReplyDate).format('YYYY-MM-DD')} (${
                        c.DaysUntilExpiry
                      }) `}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {`🛑 ${moment(c.DeadlineDate).format('YYYY-MM-DD')}`}
                  </TableCell>
                  <TableCell>
                    {!!c.SentReminder &&
                      `☝ ${moment(c.SentReminder).format('YYYY-MM-DD')}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}

Component.propTypes = {
  contracts: PropTypes.array,
  dispatch: PropTypes.func
};

export const ContractsWithUnselectedOffers = connect(state => ({
  contracts: state.contractsWithUnselectedOffers
}))(Component);
