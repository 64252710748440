import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Divider, Grid, Typography } from 'pamind-ui';

const styles = {
  divider: {
    marginTop: '0.3rem',
    marginBottom: '0.3rem'
  }
};

export class KeyValueList extends React.Component {
  render() {
    return <div style={styles.grid}>{this.props.children}</div>;
  }
}
KeyValueList.propTypes = {
  children: PropTypes.node
};

export class KeyValueListItem extends React.Component {
  render() {
    const { xl, lg, md, sm, xs } = this.props;
    return (
      <span>
        <Grid spacing={1} container={true}>
          <Grid
            item
            xl={xl !== undefined ? xl : 2}
            lg={lg !== undefined ? lg : 3}
            md={md !== undefined ? md : 4}
            sm={sm !== undefined ? sm : 5}
            xs={xs !== undefined ? xs : 6}
          >
            <Typography variant={'h6'}>{this.props.itemKey}</Typography>
          </Grid>
          <Grid
            item
            xl={xl !== undefined ? 12 - xl : 7}
            lg={lg !== undefined ? 12 - lg : 7}
            md={md !== undefined ? 12 - md : 7}
            sm={sm !== undefined ? 12 - sm : 7}
            xs={xs !== undefined ? 12 - xs : 6}
          >
            <Typography variant={'body2'}>{this.props.itemValue}</Typography>
            <div style={styles.additionalInformation}>
              {this.props.additionalInformation}
            </div>
          </Grid>
        </Grid>
        <Divider style={styles.divider} />
      </span>
    );
  }
}
KeyValueListItem.propTypes = {
  itemKey: PropTypes.node,
  itemValue: PropTypes.node,
  additionalInformation: PropTypes.node,
  xs: PropTypes.node,
  sm: PropTypes.node,
  md: PropTypes.node,
  lg: PropTypes.node,
  xl: PropTypes.node
};

KeyValueList = Radium(KeyValueList);
KeyValueListItem = Radium(KeyValueListItem);
