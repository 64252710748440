import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Box, Button, FormGroup, TextField } from 'pamind-ui';
import {
  DialogModal,
  GeneratedForm,
  Loader,
  PageHeader
} from 'domain-components';
import { routes } from 'paths';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplates, updateTemplate } from 'state/actions';

export const TemplateDetailView = () => {
  const params = useParams();
  const id = params?.id;
  const [formValues, setFormValues] = useState(null);
  const [template, setTemplate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { metadata, templates } = useSelector(state => state);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleUpdate = () => {
    setShowModal(true);
    dispatch(updateTemplate(parseInt(id), formValues));
  };

  const goToTemplates = () => history.push(routes.templates.list);

  useEffect(() => {
    if (!formValues && id && templates) {
      const template = templates.find(t => t.Id.toString() === id);
      setFormValues(template?.Terms);
      setTemplate(template);
    }
  }, [formValues, id, templates]);

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [dispatch]);

  const view =
    metadata?.contractTypes[template?.ContractType]?.views
      ?.adminTemplateDetails;

  if (!formValues || !template)
    return (
      <Box paddingBlock={8}>
        <Loader />
      </Box>
    );

  return (
    <Box paddingBlock={2}>
      <PageHeader
        backUrl={goToTemplates}
        header={template?.TemplateName}
        subheader={`Uppdatera ${template?.ContractType.toLowerCase()} mallen för ${
          template?.ProviderName
        }☀️`}
      />

      <FormGroup sx={{ display: 'grid', gap: 8, paddingBottom: 8 }}>
        <TextField
          label="Leverantör"
          value={template?.ProviderName}
          locked
          disabled
        />
        <TextField
          label="Kontraktstyp"
          value={template?.ContractType}
          locked
          disabled
        />
        <TextField
          label="Påmind Offer Mall"
          value={template?.IsPamindOffer ? 'Ja' : 'Nej'}
          locked
          disabled
        />
      </FormGroup>

      <GeneratedForm
        view={view}
        values={formValues}
        onChange={newValues => setFormValues({ ...formValues, ...newValues })}
        onValidate={() => {}}
      />

      <Button onClick={handleUpdate}>Uppdatera</Button>
      <Button onClick={goToTemplates} variant="text" color="black">
        Avbryt
      </Button>

      <DialogModal open={showModal} onClose={() => setShowModal(false)}>
        <PageHeader
          header="Snyggt jobbat teammate! 🥳"
          subheader="Nu när mallen är uppdaterad så har du bidragit till en ljusare framtid för Påmind! *Klapp på axeln*"
          reduceBottomMargin
        />
        <Button onClick={goToTemplates}>Till mallarna</Button>
        <Button
          variant="text"
          color="black"
          onClick={() => setShowModal(false)}
        >
          Stäng
        </Button>
      </DialogModal>
    </Box>
  );
};
