import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sendCustomerNotification } from 'state/actions';
import { ConfirmationDialog } from 'domain-components';
import { Menu } from 'pamind-ui';
import { MenuItem } from 'pamind-ui';
import { Button } from 'pamind-ui';

export class Component extends React.Component {
  state = {
    menu: null
  };

  handleClick = event => {
    this.setState({ menu: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ menu: null });
  };

  handleMenuItemClick = (event, template) => {
    this.props.dispatch(
      sendCustomerNotification(
        this.props.email,
        this.props.firstName,
        this.props.lastName,
        template
      )
    );
  };

  render() {
    const { menu } = this.state;
    return (
      <span>
        <Button
          aria-owns={menu ? 'simple-menu' : undefined}
          aria-haspopup="true"
          style={this.props.style}
          onClick={this.handleClick}
          size="small"
          color="primary"
          variant="outlined"
        >
          {this.props.label}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={menu}
          open={Boolean(menu)}
          onClose={this.handleClose}
        >
          {this.props.templates &&
            this.props.templates.map((template, index) => (
              <MenuItem key={template}>
                <ConfirmationDialog
                  onConfirm={event => this.handleMenuItemClick(event, template)}
                  label={`Skicka ${template}`}
                  dialogMessage={`Är du säker på att du vill skicka en ${template}`}
                  dialogTitle="Skicka?"
                  confirmLabel="Skicka"
                  size="small"
                />
              </MenuItem>
            ))}
        </Menu>
      </span>
    );
  }
}

Component.propTypes = {
  dispatch: PropTypes.func,
  metadata: PropTypes.object,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  templates: PropTypes.array,
  label: PropTypes.string,
  style: PropTypes.object
};

export const SendCustomerNotification = connect()(Component);
