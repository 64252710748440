"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.negotiationFunctions = void 0;

var _domainComponents = require("domain-components");

var termValue = function termValue(value, field, unit, settings) {
  var returnEmptyTextInsteadOfNullWhenEmpty = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var displayValue = value && value.Value !== undefined ? JSON.parse(JSON.stringify(value.Value)) : value;

  if (field.type.inputType === 'number' || field.type.inputType === 'money') {
    displayValue = (displayValue || '').toString().replace('.', ','); // Use comma for the decimal separator
  }

  if (field.type.name === 'monthsFromDate' && value && !value.Infinity) {
    var _termValue = value.Value;

    var additionalInformation = _domainComponents.dataTypes[field.type.name].additionalInformation(settings.startDate, _termValue ? parseInt(_termValue, 10) : null, !!field.showFeedback);

    if (displayValue !== null) {
      return ["".concat(displayValue, " ").concat(unit), additionalInformation];
    } else {
      return null;
    }
  }

  if (field.type.name === 'discount' && value && value.Percent) {
    return "".concat(displayValue, "%");
  }

  if (value && value.Infinity) {
    return field.allowInfinityLabel;
  } else if (!!value && (value.Value === undefined || !!value.Value)) {
    return "".concat(displayValue, " ").concat(unit);
  } else {
    return returnEmptyTextInsteadOfNullWhenEmpty ? field.emptyText : null;
  }
};

var getSettingsFromNegotiation = function getSettingsFromNegotiation(negotiation) {
  return {
    startDate: negotiation.data.NegotiationDeadlineDate
  };
};

var negotiationFunctions = {
  termValue: termValue,
  getSettingsFromNegotiation: getSettingsFromNegotiation
};
exports.negotiationFunctions = negotiationFunctions;