import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchAdministrator, deleteAdministrator } from 'state/actions';
import { replaceId, routes } from 'paths';
import EditIcon from '@mui/icons-material/Edit';
import { KeyValueListItem, KeyValueList } from 'components/ui/KeyValueList';
import { ConfirmationDialog } from 'domain-components';
import { Card } from 'pamind-ui';
import { CardContent } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { CardActions } from 'pamind-ui';
import { Button } from 'pamind-ui';

export class Component extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchAdministrator(Number(this.props.match.params.id)));
  }

  handleDelete = () => {
    this.props.dispatch(
      deleteAdministrator(Number(this.props.match.params.id))
    );
  };

  render() {
    if (!this.props.administrator) {
      return null;
    }

    const administrator = this.props.administrator;
    const fullName = administrator.FirstName + ' ' + administrator.LastName;

    return (
      <Card>
        <CardHeader title={fullName} subheader={administrator.Id} />
        <CardContent>
          <KeyValueList>
            <KeyValueListItem itemKey="Epost" itemValue={administrator.Email} />
          </KeyValueList>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            size="small"
            icon={<EditIcon color={'inherit'} />}
            to={replaceId(routes.administrators.edit, administrator.Id)}
          >
            Redigera administratör
          </Button>
          <ConfirmationDialog
            onConfirm={this.handleDelete}
            variant="outlined"
            label="Ta bort administratör"
            size="small"
          />
        </CardActions>
      </Card>
    );
  }
}

Component.propTypes = {
  administrator: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
};

export const ShowAdministrator = connect(state => ({
  administrator: state.administrator
}))(Component);
