import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchAdministrator, updateAdministrator } from 'state/actions';
import { GeneratedForm } from 'domain-components';
import { getAdministratorFields } from 'services/domain';
import { Card } from 'pamind-ui';
import { CardContent } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { Button } from 'pamind-ui';

export class Component extends React.Component {
  state = {
    values: this.props.administrator,
    validationResult: { isValid: false, isChanged: false }
  };

  componentDidMount() {
    this.props.dispatch(fetchAdministrator(Number(this.props.match.params.id)));
  }

  handleUpdate = data => {
    const id = Number(this.props.match.params.id);
    data.Id = id;
    this.props.dispatch(updateAdministrator(data));
  };

  render() {
    if (!this.props.administrator) {
      return null;
    }

    return (
      <Card>
        <CardHeader title="Redigera administratör" />
        <CardContent>
          <GeneratedForm
            values={this.state.values}
            initialValues={this.props.administrator}
            view={getAdministratorFields()}
            onChange={values => {
              this.setState({ values });
            }}
            onValidate={validationResult => {
              this.setState({ validationResult });
            }}
          />
          <Button
            disabled={
              !this.state.validationResult.isValid ||
              !this.state.validationResult.isChanged
            }
            onClick={() => {
              this.handleUpdate(this.state.values);
            }}
            size="small"
          >
            Spara
          </Button>
        </CardContent>
      </Card>
    );
  }
}

Component.propTypes = {
  administrator: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
};

export const EditAdministrator = connect(state => ({
  administrator: state.administrator
}))(Component);
