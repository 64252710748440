"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deepEqual = exports.getMoneyDisplayValueWithUnit = exports.getNumberDisplayValueWithUnit = exports.isPercent = exports.getValue = exports.getDisplayValueFromValue = exports.addDaysToTodaysDate = exports.getValueStringFromValue = void 0;

var _Format = require("../utils/Format");

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var getValueStringFromValue = function getValueStringFromValue(value) {
  if (value.Value !== undefined && value.Value !== null) {
    if (value.Percent === true) {
      return "".concat(value.Value, "%");
    }

    return value.Value;
  }

  return value;
};

exports.getValueStringFromValue = getValueStringFromValue;

var addDaysToTodaysDate = function addDaysToTodaysDate(days) {
  var date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

exports.addDaysToTodaysDate = addDaysToTodaysDate;

var getDisplayValueFromValue = function getDisplayValueFromValue(value, field) {
  var returnValue = {
    Value: '',
    Infinity: false,
    Percent: false
  };

  if (value === null) {
    return returnValue;
  }

  if (_typeof(value) === 'object') {
    if (value.Value !== undefined && value.Value !== null) {
      returnValue.Value = JSON.parse(JSON.stringify(value.Value)).toString().replace('.', ','); // Use comma for the decimal separator (this might backfire for text fields with infinity switch)
    }

    if (value.Infinity !== undefined && value.Infinity !== null) {
      returnValue.Infinity = JSON.parse(JSON.stringify(value.Infinity));
    }
  } else {
    returnValue.Value = value;
  }

  return returnValue;
};

exports.getDisplayValueFromValue = getDisplayValueFromValue;

var getValue = function getValue(value) {
  var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (_typeof(value) === 'object') {
    if (value !== null && value !== void 0 && value.Infinity) {
      return field.allowInfinityLabel;
    }

    return value === null || value === void 0 ? void 0 : value.Value;
  }

  return value;
};

exports.getValue = getValue;

var isPercent = function isPercent(value) {
  return _typeof(value) === 'object' ? value.Percent : false;
};

exports.isPercent = isPercent;

var getNumberDisplayValueWithUnit = function getNumberDisplayValueWithUnit(value, field) {
  var _field$unit;

  if (value.Infinity) {
    return field.allowInfinityLabel;
  }

  if (value.Percent) {
    return "".concat(value.Value, " %");
  }

  return "".concat(getValue(value), " ").concat((_field$unit = field === null || field === void 0 ? void 0 : field.unit) !== null && _field$unit !== void 0 ? _field$unit : '').replace('.', ',');
};

exports.getNumberDisplayValueWithUnit = getNumberDisplayValueWithUnit;

var getMoneyDisplayValueWithUnit = function getMoneyDisplayValueWithUnit(value, field) {
  if (_typeof(value) === 'object') {
    if (!(value !== null && value !== void 0 && value.Value)) return field.emptyText;
  } else {
    if (!value) return field.emptyText;
  }

  if (value !== null && value !== void 0 && value.Infinity) {
    return field === null || field === void 0 ? void 0 : field.allowInfinityLabel;
  }

  if (value !== null && value !== void 0 && value.Percent) {
    return "".concat(value === null || value === void 0 ? void 0 : value.Value, " %");
  }

  return "".concat((0, _Format.formatCurrency)(getValue(value)), " ").concat(field === null || field === void 0 ? void 0 : field.unit);
};

exports.getMoneyDisplayValueWithUnit = getMoneyDisplayValueWithUnit;

var deepEqual = function deepEqual(a, b) {
  // Compare numbers as strings, since all user typed input becomes strings:
  if (a === undefined && b === undefined) {
    return true;
  }

  if (a === undefined || b === undefined) {
    return false;
  }

  if (a === null && b === null) {
    return true;
  }

  if (a === null || b === null) {
    return false;
  }

  if (typeof a === 'number') {
    a = a.toString();
  }

  if (typeof b === 'number') {
    b = b.toString();
  }

  if (a === b) {
    return true;
  } else if ((a.constructor === Object || Array.isArray(a)) && a != null && (b.constructor === Object || Array.isArray(b)) && b != null) {
    if (Object.keys(a).length !== Object.keys(b).length) return false;

    for (var prop in a) {
      if (b.hasOwnProperty(prop)) {
        if (!deepEqual(a[prop], b[prop])) return false;
      } else return false;
    }

    return true;
  } else return false;
};

exports.deepEqual = deepEqual;