import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchProvider, updateProvider } from 'state/actions';
import {
  ConfirmationDialog,
  GeneratedForm,
  Markdown,
  SupplierAvatar
} from 'domain-components';
import {
  getProviderFields,
  getProviderContractTypeFields
} from 'services/domain';
import {
  Link,
  Typography,
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
  Button,
  Divider,
  Accordion
} from 'pamind-ui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PopupState, {
  bindTrigger,
  bindMenu
} from 'material-ui-popup-state/index';

export const updateContractTypesForState = (
  provider,
  contractType,
  values,
  providerState
) => {
  if (!provider) return;

  const updatedProvider = provider.reduce((accumulator, currentContract) => {
    if (currentContract.ContractType === contractType) {
      accumulator.push(values);
    } else {
      accumulator.push(currentContract);
    }
    return accumulator;
  }, []);
  return { ...providerState, ContractTypes: updatedProvider };
};

export class Component extends React.Component {
  state = {
    checked: [],
    expanded: '',
    provider: this.props.provider,
    validationResult: { isValid: false, isChanged: false },
    logo: null
  };

  componentDidUpdate(oldProps) {
    if (
      JSON.stringify(oldProps.provider) !== JSON.stringify(this.props.provider)
    ) {
      this.setState({ provider: this.props.provider });
    }
  }

  handleExpansionPanelChange = panel => expanded => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  componentDidMount() {
    this.props.dispatch(fetchProvider(Number(this.props.match.params.id)));
  }

  handleUpdateProvider = () => {
    const logo = this.state.logo;
    let provider = this.state.provider;

    const updatedProvider = { ...provider, Logo: logo };
    this.props.dispatch(
      updateProvider(this.props.provider.Id, updatedProvider)
    );
  };

  updateState = provider => {
    if (provider.Name.length) {
      this.setState(state => ({
        provider: {
          ...provider,
          ContractTypes:
            (state.provider && state.provider.ContractTypes) ||
            this.props.provider.ContractTypes
        }
      }));
    }
  };

  updateValidationResult = validationResult => {
    this.setState({ validationResult });
  };

  updateContractTypeTermsState = (data, type) => {
    const updatedContractTypes = this.state.provider.ContractTypes.map(t => {
      if (t.ContractType === type) {
        t.PamindOfferTerms = data.target.value;
      }
      return t;
    });
    this.setState({
      provider: {
        ...(this.state.provider || {}),
        ContractTypes: updatedContractTypes
      }
    });
  };
  updateContractTypeDescriptionState = (data, type) => {
    const updatedContractTypes = this.state.provider.ContractTypes.map(t => {
      if (t.ContractType === type) {
        t.PamindOfferProfile = data.target.value;
      }
      return t;
    });
    this.setState({
      provider: {
        ...(this.state.provider || {}),
        ContractTypes: updatedContractTypes
      }
    });
  };

  updateContractTypeState = (contractType, values) => {
    if (!this.props.provider) return;
    const stateContractTypes =
      (this.state.provider && this.state.provider.ContractTypes) ||
      this.props.provider.ContractTypes;
    const updated = updateContractTypesForState(
      stateContractTypes,
      contractType,
      values,
      this.state.provider
    );
    let newProviderState = { ...this.state.provider };
    newProviderState.ContractTypes = updated.ContractTypes;

    if (JSON.stringify(this.state.provider) !== JSON.stringify(updated)) {
      this.setState(state => ({
        provider: updated
      }));
    }
  };

  buildAddContractTypeMenu(popupState) {
    const addContractType = contractType => {
      this.setState({
        provider: {
          ...(this.state.provider || {}),
          ContractTypes: [
            ...(this.state.provider || this.props.provider).ContractTypes,
            {
              ContractType: contractType.name
            }
          ]
        }
      });
      popupState.close();
    };

    return (
      <Menu {...bindMenu(popupState)}>
        {Object.values(this.props.metadata.contractTypes)
          .filter(
            contractType =>
              !this.state.provider ||
              !this.state.provider.ContractTypes.some(
                ct => ct.ContractType === contractType.name
              )
          )
          .map(contractType => (
            <MenuItem
              key={contractType.label}
              onClick={() => addContractType(contractType)}
            >
              {contractType.label}
            </MenuItem>
          ))}
      </Menu>
    );
  }

  renderContractType(contractType, index) {
    const removeContractType = () => {
      this.setState(state => ({
        provider: {
          ...(state.provider || {}),
          ContractTypes: (
            state.provider || this.props.provider
          ).ContractTypes.filter(ct => ct !== contractType)
        }
      }));
    };
    return (
      <Box marginBottom={6}>
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4" color="inherit" noWrap>
              {contractType.ContractType}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box padding={4}>
              <Link
                color="primary"
                target="_blank"
                href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
              >
                Markdown Cheatsheet
              </Link>
              <Box gap={2} display="flex" flexFlow="row">
                <Box flex="auto">
                  <TextField
                    id="Description"
                    label="Profile beskrivning (Markdown)"
                    margin="normal"
                    defaultValue={contractType.PamindOfferProfile}
                    onChange={data => {
                      this.updateContractTypeDescriptionState(
                        data,
                        contractType.ContractType
                      );
                    }}
                    multiline
                    fullWidth
                  />
                  <Box>
                    <Markdown source={contractType.PamindOfferProfile} />
                  </Box>
                </Box>
                <Box flex="auto">
                  <TextField
                    id="PamindOfferTerms"
                    label="Terms för PåmindOffer (Markdown)"
                    margin="normal"
                    defaultValue={contractType.PamindOfferTerms}
                    onChange={data => {
                      this.updateContractTypeTermsState(
                        data,
                        contractType.ContractType
                      );
                    }}
                    multiline
                    fullWidth
                  />
                  <Box>
                    <Markdown source={contractType.PamindOfferTerms} />
                  </Box>
                </Box>
              </Box>

              <Box marginY={12}>
                <Divider />
              </Box>
              <GeneratedForm
                values={contractType}
                initialValues={contractType}
                onChange={values => {
                  this.updateContractTypeState(
                    contractType.ContractType,
                    values
                  );
                }}
                onValidate={() => {}}
                view={getProviderContractTypeFields()}
              />
              <Box paddingBottom={10}>
                <ConfirmationDialog
                  label="Radera avtalstyp"
                  icon={<DeleteIcon />}
                  size="small"
                  style={{
                    float: 'right',
                    paddingLeft: '12px',
                    paddingRight: '12px'
                  }}
                  color="secondary"
                  variant="outlined"
                  onConfirm={removeContractType}
                  confirmLabel="Radera"
                  dialogTitle="Radera avtalstyp"
                  dialogMessage="Är du säker på att du vill radera avtalstypen för den här leverantören? Detta påverkar inte existerande kontrakt."
                  destructive
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }

  handleLogoChange(files) {
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = () => {
      this.setState({
        logo: reader.result.substr(reader.result.indexOf(';base64,') + 8)
      });
    };
  }

  render() {
    if (!this.props.provider) {
      return null;
    }
    return (
      <Box>
        <Card>
          <CardHeader
            avatar={
              <SupplierAvatar
                logo={`${process.env.PUBLIC_URL}/api/c/provider/logo/${this.props.provider.Id}`}
                resetPosition
                size={48}
              />
            }
            title={
              <Typography variant="h1">
                <Box fontFamily="fontFamily">Redigera leverantör</Box>
              </Typography>
            }
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleUpdateProvider()}
                size="small"
              >
                Spara leverantör
              </Button>
            }
          />
          <CardContent>
            <Box display="flex">
              <Box flexGrow={1}>
                <Box marginTop={6}>
                  <Typography variant="h4" gutterBottom color="inherit" noWrap>
                    Logga
                  </Typography>
                  <input
                    type="file"
                    onChange={e => this.handleLogoChange(e.target.files)}
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </Box>
                <GeneratedForm
                  values={this.state.provider || {}}
                  onChange={provider => {
                    this.updateState(provider);
                  }}
                  onValidate={validationResult => {
                    this.updateValidationResult(validationResult);
                  }}
                  view={getProviderFields()}
                  initialValues={this.props.provider}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: 8 }}>
          <CardHeader
            title={<Typography variant="h3">Avtalstyper</Typography>}
            action={
              <PopupState variant="popover" popupId="add-contracyType-menu">
                {popupState => (
                  <Box marginY={6} maxWidth={375}>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      style={{ paddingLeft: '12px', paddingRight: '12px' }}
                      {...bindTrigger(popupState)}
                    >
                      Lägg till avtalstyp <ArrowDropDownIcon />
                    </Button>
                    {this.buildAddContractTypeMenu(popupState)}
                  </Box>
                )}
              </PopupState>
            }
          />
          <CardContent>
            <Box>
              {(
                this.state.provider || this.props.provider
              ).ContractTypes.map((contractType, index) =>
                this.renderContractType(
                  contractType,
                  index,
                  this.props.metadata
                )
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

Component.propTypes = {
  provider: PropTypes.object,
  metadata: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
};

export const EditProvider = connect(state => ({
  provider: state.provider,
  metadata: state.metadata
}))(Component);
