"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  selectors: true,
  substitution: true,
  negotiationFunctions: true,
  ErrorHandler: true,
  formatPersonalNumber: true,
  getDeviceType: true,
  addDaysToTodaysDate: true
};
Object.defineProperty(exports, "selectors", {
  enumerable: true,
  get: function get() {
    return _Selectors.selectors;
  }
});
Object.defineProperty(exports, "substitution", {
  enumerable: true,
  get: function get() {
    return _Substitution.substitution;
  }
});
Object.defineProperty(exports, "negotiationFunctions", {
  enumerable: true,
  get: function get() {
    return _NegotiationFunctions.negotiationFunctions;
  }
});
Object.defineProperty(exports, "ErrorHandler", {
  enumerable: true,
  get: function get() {
    return _ErrorHandler.ErrorHandler;
  }
});
Object.defineProperty(exports, "formatPersonalNumber", {
  enumerable: true,
  get: function get() {
    return _FormatPersonalNumber.formatPersonalNumber;
  }
});
Object.defineProperty(exports, "getDeviceType", {
  enumerable: true,
  get: function get() {
    return _Devices.getDeviceType;
  }
});
Object.defineProperty(exports, "addDaysToTodaysDate", {
  enumerable: true,
  get: function get() {
    return _addDaysToTodaysDate.addDaysToTodaysDate;
  }
});

var _Selectors = require("./Selectors/Selectors.js");

var _Substitution = require("./Substitution/Substitution.js");

var _NegotiationFunctions = require("./Negotiation/NegotiationFunctions.js");

var _ErrorHandler = require("./ErrorHandler/ErrorHandler");

var _FormatPersonalNumber = require("./FormatPersonalNumber/FormatPersonalNumber");

var _Clipboard = require("./Clipboard/Clipboard");

Object.keys(_Clipboard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Clipboard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Clipboard[key];
    }
  });
});

var _BankIdUtils = require("./BankIdUtils/BankIdUtils");

Object.keys(_BankIdUtils).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _BankIdUtils[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _BankIdUtils[key];
    }
  });
});

var _Devices = require("./Devices/Devices");

var _addDaysToTodaysDate = require("./Dates/addDaysToTodaysDate");