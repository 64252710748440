import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchStatistics } from 'state/actions';
import { Card } from 'pamind-ui';
import { CardContent } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { List, ListItem } from 'material-ui/List';
import { StatisticsTable } from 'components/statistics/StatisticsTable';
import { VerticalSpacing } from 'domain-components';
import { Link } from 'react-router-dom';
import { Button } from 'pamind-ui';
import { AlignHorizontally } from 'components/ui/AlignHorizontally';
import { Select } from 'pamind-ui';
import { MenuItem } from 'pamind-ui';
import { Accordion } from 'pamind-ui';
import { AccordionSummary } from 'pamind-ui';

import DownloadIcon from '@mui/icons-material/GetApp';
import { ListItemText } from 'pamind-ui';

const NegotiationList = props => (
  <Card>
    <CardHeader title={'Förhandlingar'} />
    <CardContent>
      {Object.keys(props.data).map(contractType => {
        const states = props.data[contractType];
        return (
          <Accordion key={contractType}>
            <AccordionSummary>{contractType}</AccordionSummary>
            <List>
              {Object.keys(states).map(state => (
                <ListItem
                  key={state}
                  rightIcon={<div>{states[state]}</div>}
                  disabled={true}
                >
                  <ListItemText primary={state} secondary={states[state]} />
                </ListItem>
              ))}
            </List>
          </Accordion>
        );
      })}
    </CardContent>
  </Card>
);
NegotiationList.propTypes = {
  data: PropTypes.object
};

export class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 'Total' };
  }

  handleChange = (event, index, value) => this.setState({ value });

  componentDidMount() {
    this.props.dispatch(fetchStatistics());
  }

  render() {
    if (!this.props.statistics) {
      return null;
    }

    const customerStats = {
      Registreringar: this.props.statistics.Signups,
      Fullmakter: this.props.statistics.PowersOfAttorney,
      'Andel fullmakter': this.props.statistics.PowersOfAttorneyRatio,
      'Påbörjade BankID-inloggningar': this.props.statistics.BankIdLogins,
      'Lyckade BankID-inloggningar': this.props.statistics
        .BankIdSuccessfulLogins,
      'Andel lyckade BankID-inloggningar': this.props.statistics
        .BankIdSuccessfulLoginsRatio,
      'GDPR-samtycken': this.props.statistics.GdprConsents,
      'Onboarding rekommendationer': this.props.statistics.OnboardingCtas
    };

    const dl = '/api/a/statistics/CustomerStatistics?token=';
    const downloadCustomers = '/api/a/statistics/Customers';
    const downloadNps = '/api/a/statistics/Nps';
    const supplierResponseStatistics =
      '/api/a/statistics/SupplierResponseStatistics';
    const statisticsApi =
      '/api/a/statistics/ContractTypeStatistics/{contractType}';

    let contractTypes = [];
    for (let key in this.props.metadata.contractTypes) {
      contractTypes.push(this.props.metadata.contractTypes[key]);
    }

    return (
      <VerticalSpacing>
        <AlignHorizontally fillSpace={false}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={dl}
            target="_blank"
            style={{ marginRight: '1rem' }}
            size="small"
          >
            Ladda ner data för cohort analys
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={downloadCustomers}
            style={{ marginRight: '1rem' }}
            target="_blank"
            size="small"
          >
            Ladda ner användare
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={downloadNps}
            target="_blank"
            size="small"
          >
            Ladda ner NPS
          </Button>
        </AlignHorizontally>
        <AlignHorizontally>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={supplierResponseStatistics}
            target="_blank"
            size="small"
          >
            Ladda ner statestik över leverantörers svarstider
          </Button>
        </AlignHorizontally>
        <Card>
          <CardHeader title={'Cohort-data per avtalstyp'} />
          <CardContent>
            <Select value={this.state.value} onChange={this.handleChange}>
              <MenuItem value={'Total'}>Total</MenuItem>
              {contractTypes.map(contractType => (
                <MenuItem value={contractType.name}>
                  {contractType.label}
                </MenuItem>
              ))}
            </Select>
            <Button
              variant="text"
              label={'Ladda ner data'}
              primary={false}
              component={Link}
              to={statisticsApi.replace('{contractType}', this.state.value)}
              target="_blank"
              size="small"
            >
              <DownloadIcon color={'primary'} />
              Ladda ner data
            </Button>
          </CardContent>
        </Card>
        <StatisticsTable
          title="Kundstatistik"
          keyLabel="Typ"
          data={customerStats}
        />
      </VerticalSpacing>
    );
  }
}

Component.propTypes = {
  metadata: PropTypes.object,
  statistics: PropTypes.object,
  dispatch: PropTypes.func,
  contractTypes: PropTypes.array
};

export const Statistics = connect(state => ({
  metadata: state.metadata,
  statistics: state.statistics
}))(Component);
