import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import { dismissNotification } from 'state/actions';

export class Component extends React.Component {
  render() {
    return (
      <Snackbar
        open={this.props.notification.show}
        message="Klart!"
        autoHideDuration={4000}
        onRequestClose={() => this.props.dispatch(dismissNotification())}
      />
    );
  }
}

Component.propTypes = {
  notification: PropTypes.shape({
    show: PropTypes.bool
  }),
  dispatch: PropTypes.func
};

export const Notification = connect(state => {
  return { notification: state.notification };
})(Component);
