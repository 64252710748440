"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseTheme = exports.pamindTypographyMapping = exports.pamindFont = exports.pamindTypography = exports.palette = void 0;

var _convertHexToRgba = require("../utils/convertHexToRgba");

var _styles = require("@mui/material/styles");

var _Typography = require("../utils/Typography");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var palette = {
  red: {
    50: '#fff0f5',
    100: '#FFDFDD',
    200: '#FFC5CD',
    300: '#FFA5B2',
    400: '#EF8192',
    500: '#CC6273'
  },
  brown: {
    50: '#fffdfa',
    100: '#f9f5ee',
    200: '#edd7c5',
    300: '#cbab97',
    400: '#ab8981'
  },
  yellow: {
    100: '#fff8dc',
    200: '#ffeda3',
    300: '#ecc658',
    400: '#d8a61f',
    500: '#dfb641'
  },
  lime: {
    100: '#f8fec3',
    200: '#e4ed8d',
    300: '#cfda63',
    400: '#b4cc5f'
  },
  green: {
    100: '#c7f3e5',
    200: '#a3e8d5',
    300: '#66cdaa',
    350: '#00b68f',
    400: '#4ba98f',
    500: '#5AA08F',
    600: '#4D8E7E'
  },
  turqoise: {
    50: '#e6fbf5',
    100: '#b8f0f0',
    200: '#8ae1e0',
    300: '#5ecdcd',
    400: '#21a5a6'
  },
  blue: {
    100: '#d5e9f9',
    200: '#C3E1FF',
    300: '#629dcc',
    400: '#4682b4',
    500: '#4F8FB8',
    600: '#437CA1'
  },
  purple: {
    50: '#f0ecff',
    100: '#E7D4FF',
    200: '#BEAEEB',
    300: '#8F85D4',
    400: '#736EB0'
  },
  violet: {
    100: '#fff0f5',
    200: '#eec8e7',
    300: '#dda0dd',
    400: '#b678b6'
  },
  grey: {
    100: '#eaedf1',
    200: '#ced2d9',
    300: '#a1a9b7',
    400: '#7b8494',
    500: '#5E6773',
    600: '#484c57'
  },
  white: {
    0: '#ffffff'
  },
  black: {
    0: '#484c57'
  }
};
exports.palette = palette;
var pamindTypography = {
  fontSize: {
    mobile: {
      ratio: 1.111,
      base: 16
    },
    desktop: {
      ratio: 1.111,
      base: 16
    }
  },
  lineHeight: {
    header: 1.2,
    body: 1.5
  }
};
exports.pamindTypography = pamindTypography;
var pamindFont = {
  primary: ['Montserrat', 'sans-serif'].join(','),
  secondary: ['Open Sans', 'sans-serif'].join(',')
};
exports.pamindFont = pamindFont;
var pamindTypographyMapping = {
  modularScale: {
    display1: {
      mobile: 5,
      desktop: 6
    },
    h1: {
      mobile: 4,
      desktop: 5
    },
    h2: {
      mobile: 1,
      desktop: 2
    },
    h3: {
      mobile: 1,
      desktop: 2
    },
    h4: {
      mobile: -2,
      desktop: -1
    },
    h5: {
      mobile: -2,
      desktop: -1
    },
    h6: {
      mobile: -2,
      desktop: -1
    },
    subtitle1: {
      mobile: 0,
      desktop: 1
    },
    subtitle2: {
      mobile: -1,
      desktop: 0
    },
    body1: {
      mobile: 0,
      desktop: 1
    },
    body2: {
      mobile: -1,
      desktop: 0
    },
    button: {
      mobile: 0,
      desktop: 1
    },
    buttonText: {
      mobile: -1,
      desktop: 0
    },
    caption: {
      mobile: -1.3,
      desktop: -1.3
    },
    overline: {
      mobile: -6,
      desktop: -5
    },
    smallprint: {
      mobile: -2.2,
      desktop: -2.2
    },
    fineprint: {
      mobile: -3.2,
      desktop: -3.2
    }
  }
};
exports.pamindTypographyMapping = pamindTypographyMapping;
var sensibleDefaults = {
  themeName: 'Påmind',
  palette: {
    type: 'light',
    primary: {
      light: palette.green[100],
      main: palette.green[200],
      dark: palette.green[400],
      contrastText: palette.grey[600]
    },
    secondary: {
      light: palette.red[100],
      main: palette.red[200],
      dark: palette.red[400],
      contrastText: palette.grey[600]
    },
    success: {
      light: palette.green[100],
      main: palette.green[200],
      dark: palette.green[600],
      contrastText: palette.grey[600]
    },
    info: {
      light: palette.blue[100],
      main: palette.blue[100],
      dark: palette.blue[500],
      contrastText: palette.grey[600]
    },
    warning: {
      light: palette.yellow[100],
      main: palette.yellow[200],
      dark: palette.yellow[400],
      contrastText: palette.black[0]
    },
    error: {
      light: palette.red[100],
      main: palette.red[200],
      dark: palette.red[300],
      contrastText: palette.grey[600],
      alternativeContrastText: palette.white[0]
    },
    draft: {
      main: palette.green[200]
    },
    grey: _objectSpread(_objectSpread({
      light: palette.grey[100],
      main: palette.grey[200],
      dark: palette.grey[500],
      contrastText: palette.grey[600]
    }, palette.grey), {}, {
      700: palette.grey[600],
      800: palette.grey[600],
      900: palette.grey[600],
      A100: palette.grey[100],
      A200: palette.grey[200],
      A300: palette.grey[300],
      A400: palette.grey[400],
      A700: palette.grey[600]
    }),
    common: {
      white: palette.white[0],
      black: palette.grey[600]
    },
    text: {
      primary: palette.grey[600],
      secondary: palette.grey[600],
      hover: palette.grey[500],
      hint: palette.grey[300],
      disabled: 'rgba(0, 0, 0, 0.30)',
      light: palette.grey[500],
      main: palette.grey[600],
      dark: palette.grey[600],
      contrastText: palette.white[0]
    },
    action: {
      active: palette.grey[300],
      hover: palette.grey[200],
      selected: palette.grey[200],
      disabled: 'rgba(0, 0, 0, 0.30)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)'
    },
    background: {
      paper: palette.white[0],
      default: palette.brown[100],
      main: palette.brown[100],
      hover: (0, _convertHexToRgba.convertHexToRgba)(palette.black[0], 10)
    },
    red: {
      light: palette.red[100],
      main: palette.red[200],
      dark: palette.red[400],
      500: palette.red[500],
      contrastText: palette.grey[600]
    },
    brown: {
      light: palette.brown[100],
      main: palette.brown[200],
      dark: palette.brown[400],
      contrastText: palette.grey[600]
    },
    yellow: {
      light: palette.yellow[100],
      main: palette.yellow[200],
      dark: palette.yellow[400],
      500: palette.yellow[500],
      contrastText: palette.grey[600]
    },
    lime: {
      light: palette.lime[100],
      main: palette.lime[200],
      dark: palette.lime[400],
      contrastText: palette.grey[600]
    },
    green: {
      light: palette.green[100],
      main: palette.green[200],
      dark: palette.green[600],
      contrastText: palette.grey[600]
    },
    turqoise: {
      light: palette.turqoise[100],
      main: palette.turqoise[200],
      dark: palette.turqoise[400],
      contrastText: palette.grey[600]
    },
    blue: {
      light: palette.blue[100],
      main: palette.blue[200],
      500: palette.blue[500],
      dark: palette.blue[600],
      contrastText: palette.grey[600]
    },
    purple: {
      light: palette.purple[100],
      main: palette.purple[300],
      dark: palette.purple[400],
      contrastText: palette.grey[600]
    },
    violet: {
      light: palette.violet[100],
      main: palette.violet[200],
      dark: palette.violet[400],
      contrastText: palette.grey[600]
    },
    black: {
      light: palette.grey[600],
      main: palette.grey[600],
      dark: palette.grey[600],
      contrastText: palette.white[0]
    },
    white: {
      light: palette.white[0],
      main: palette.white[0],
      dark: palette.white[0],
      contrastText: palette.grey[600]
    },
    transparent: {
      light: 'transparent',
      main: 'transparent',
      dark: 'transparent',
      contrastText: 'transparent'
    }
  },
  typography: {
    h1: {
      fontWeight: 800,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Montserrat, sans-serif',
      lineHeight: 1.2,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.h1.mobile, pamindTypography.fontSize.mobile).px
    },
    h2: {
      fontWeight: 800,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Montserrat, sans-serif',
      lineHeight: 1.2,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.h2.mobile, pamindTypography.fontSize.mobile).px
    },
    h3: {
      fontWeight: 700,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: 1.2,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.h3.mobile, pamindTypography.fontSize.mobile).px
    },
    h4: {
      fontWeight: 700,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Montserrat, sans-serif',
      lineHeight: 1.2,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.h4.mobile, pamindTypography.fontSize.mobile).px
    },
    h5: {
      fontWeight: 700,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: 1.2,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.h5.mobile, pamindTypography.fontSize.mobile).px
    },
    h6: {
      fontWeight: 700,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: 1.2,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.h6.mobile, pamindTypography.fontSize.mobile).px
    },
    subtitle1: {
      fontWeight: 800,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Montserrat, sans-serif',
      lineHeight: 1.5,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.subtitle1.mobile, pamindTypography.fontSize.mobile).px
    },
    subtitle2: {
      fontWeight: 800,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Montserrat, sans-serif',
      lineHeight: 1.5,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.subtitle2.mobile, pamindTypography.fontSize.mobile).px
    },
    body1: {
      fontWeight: 400,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: 1.5,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.body1.mobile, pamindTypography.fontSize.mobile).px
    },
    body2: {
      fontWeight: 400,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: 1.5,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.body2.mobile, pamindTypography.fontSize.mobile).px
    },
    button: {
      fontWeight: 700,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Montserrat, sans-serif',
      lineHeight: 1.5,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.button.mobile, pamindTypography.fontSize.mobile).px
    },
    caption: {
      fontWeight: 400,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: 1.5,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.caption.mobile, pamindTypography.fontSize.mobile).px
    },
    overline: {
      fontWeight: 400,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: 1.5,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.overline.mobile, pamindTypography.fontSize.mobile).px
    },
    smallprint: {
      fontWeight: 400,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: 1.5,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.smallprint.mobile, pamindTypography.fontSize.mobile).px
    },
    fineprint: {
      fontWeight: 400,
      color: palette.grey[600],
      '--webkit-font-smoothing': 'antialiased',
      textRendering: 'optimizeLegibility',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: 1.5,
      fontSize: _Typography.typography.modularScale(pamindTypographyMapping.modularScale.fineprint.mobile, pamindTypography.fontSize.mobile).px
    },
    fontsize: 16
  },
  props: {
    MuiLink: {
      underline: 'always'
    },
    MuiTextField: {
      variant: 'filled'
    },
    MuiPopover: {
      elevation: 0
    }
  },
  shape: {
    borderRadius: 6
  },
  spacing: 4,
  transitions: {
    easing: {
      bounce: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)'
    }
  },
  zIndex: {
    background: 0,
    content: 100,
    foreground: 200,
    fixedFooter: 1150,
    overlay: 1600
  },
  height: {
    customerWebWithNavbar: '100vh',
    navigationBar: {
      mobile: '108px',
      tablet: '80px'
    }
  },
  components: {
    ContentWrapper: {
      maxWidth: 552
    },
    PlacementWrapper: {
      maxWidth: 520
    },
    MainMenu: {
      width: 280
    },
    NavigationBar: {
      height: 80
    },
    TextField: {
      backgroundColor: palette.brown[100]
    }
  }
};

var baseTheme = function baseTheme(overrideTheme) {
  var theme = (0, _styles.createTheme)(_objectSpread(_objectSpread(_objectSpread({}, sensibleDefaults), overrideTheme), {}, {
    typography: _objectSpread(_objectSpread({}, sensibleDefaults.typography), overrideTheme.typography),
    palette: _objectSpread(_objectSpread({}, sensibleDefaults.palette), overrideTheme.palette),
    components: _objectSpread(_objectSpread({}, sensibleDefaults.components), overrideTheme.components)
  }));
  return theme;
};

exports.baseTheme = baseTheme;