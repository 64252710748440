"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.openBankIdForAndroidOrIOS = exports.openBankIdApp = exports.isMobile = void 0;

var _mobileDetect = _interopRequireDefault(require("mobile-detect"));

var _uaParserJs = require("ua-parser-js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var isMobile = function isMobile() {
  var md = new _mobileDetect["default"](navigator.userAgent);
  return md.mobile() !== null;
}; // https://www.bankid.com/utvecklare/guider/teknisk-integrationsguide/programstart


exports.isMobile = isMobile;

var openBankIdApp = function openBankIdApp(autoStartToken) {
  var ua = new _uaParserJs.UAParser().getResult();
  var isIos = ua.os.name === 'iOS';
  var isMetaBrowser = ua.browser.name === 'Instagram' || ua.browser.name === 'Facebook';
  var bankIdUrl = isIos ? 'https://app.bankid.com/' : 'bankid:///';
  var redirect = ua.os.name === 'iOS' && !isMetaBrowser
  /* redirect doesn't work with FB/IG embedded browsers */
  ? window.location.href : 'null';
  bankIdUrl += "?autostarttoken=".concat(autoStartToken, "&redirect=").concat(encodeURIComponent(redirect));
  window.location.assign(bankIdUrl);
};

exports.openBankIdApp = openBankIdApp;

var openBankIdForAndroidOrIOS = function openBankIdForAndroidOrIOS(autoStartToken) {
  openBankIdApp(autoStartToken);
};

exports.openBankIdForAndroidOrIOS = openBankIdForAndroidOrIOS;