import React from 'react';
import { ContractsWithUnPausedNegotiation } from 'components/lists/ContractsWithUnPausedNegotiation';

export class UnpausedNegotiationsList extends React.Component {
  render() {
    return (
      <div>
        <ContractsWithUnPausedNegotiation />
      </div>
    );
  }
}
