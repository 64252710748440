import React from 'react';
import PropTypes from 'prop-types';
import { replaceId, routes } from 'paths';
import { connect } from 'react-redux';
import {
  fetchCustomer,
  fetchContract,
  deleteContract,
  setNotCustomer
} from 'state/actions';
import { Card } from 'pamind-ui';
import { CardContent } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { CardActions } from 'pamind-ui';
import { Collapse } from 'pamind-ui';
import { EditContract } from 'components/contract/EditContract';
import { NegotiationView } from 'components/negotiation/NegotiationView';
import { UpcomingNegotiation } from 'components/contract/UpcomingNegotiation';
import { SendCustomerMessage } from 'components/negotiation/SendCustomerMessage';
import { VerticalSpacing } from 'domain-components';
import { Link } from 'react-router-dom';
import IconDelete from '@mui/icons-material/Delete';
import IconChanges from '@mui/icons-material/TrackChanges';
import IconSchedule from '@mui/icons-material/Schedule';
import IconNotCustomer from '@mui/icons-material/SentimentDissatisfied';
import { ShowEventlog } from 'components/eventlog/ShowEventlog';
import { ConfirmationDialog, GeneratedList } from 'domain-components';
import { IconButton } from 'pamind-ui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classnames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import { Chip, Typography, Box } from 'pamind-ui';
import { UpdateNegotiationDates } from 'components/negotiation/UpdateNegotiationDates';
import { SupplierAvatar } from 'domain-components';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { getConvertedMobileNumber } from 'components/utils/getConvertedMobileNumber';

const styles = theme => ({
  sidebar: {
    marginLeft: theme.spacing(3),
    maxWidth: '400px'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  modal: {
    margin: theme.spacing(4),
    maxHeight: '100vh',
    overflow: 'scroll'
  },
  document: {
    width: '100%',
    height: 'auto'
  },
  title: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main
  },
  sidebarHeader: {
    paddingBottom: 0
  },
  subheaderList: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gridGap: theme.spacing(4),
      marginBottom: theme.spacing()
    },
    '& svg': {
      transform: `translateY(${theme.spacing()})`
    },
    '& a': {
      color: theme.palette.common.black
    }
  },
  providerAvatar: {
    transform: `translateY(${theme.spacing()})`
  }
});
export class Component extends React.Component {
  state = {
    expandedHeader: false,
    expandedEventlog: false,
    modalIsOpen: false,
    image: null
  };
  componentDidMount() {
    this.props.dispatch(fetchContract(Number(this.props.match.params.id)));
  }

  componentDidUpdate() {
    if (
      this.props.contract &&
      this.props.customer?.CustomerId !== this.props.contract.CustomerId
    ) {
      this.props.dispatch(fetchCustomer(this.props.contract.CustomerId));
    }
  }

  delete = () => {
    this.props.dispatch(
      deleteContract(this.props.contract.Id, this.props.contract.CustomerId)
    );
  };

  setNotCustomer = () => {
    this.props.dispatch(
      setNotCustomer(
        this.props.contract.CurrentNegotiation.Id,
        this.props.contract.CustomerId
      )
    );
  };

  handleExpandHeaderClick = () => {
    this.setState(state => ({ expandedHeader: !state.expandedHeader }));
  };
  handleExpandEventlogClick = () => {
    this.setState(state => ({ expandedEventlog: !state.expandedEventlog }));
  };
  handleModal = document => {
    let image;
    if (document) {
      image = `data:${document.Type};base64,${document.Data}`;
    }
    this.setState(state => ({
      modalIsOpen: !state.modalIsOpen,
      image: image ? image : null
    }));
  };

  render() {
    if (!this.props.contract || !this.props.customer) {
      return null;
    }
    const contractType = this.props.contract.ContractType;
    const contractTypes = this.props.metadata.contractTypes;
    const showCanceledBanner = this.props.contract.State === 'Canceled';
    const convertedMobileNumber = getConvertedMobileNumber(
      this.props.customer?.MobileNumber
    );

    const { classes } = this.props;
    return (
      <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <VerticalSpacing style={{ width: '100%' }}>
          {this.props.contract.CurrentNegotiation && (
            <Card>
              <CardHeader
                title={
                  <Typography variant="h2">Nuvarande förhandling</Typography>
                }
                subheader={this.props.contract.CurrentNegotiation.State}
                avatar={<IconSchedule color={'primary'} />}
              />
              <CardContent>
                {showCanceledBanner && (
                  <Box
                    border="solid 7px"
                    padding={5}
                    style={{ transform: 'rotate(15deg)' }}
                  >
                    <Typography variant="h1" align="center">
                      Canceled
                      <span role="img" aria-label="canceled!">
                        😾
                      </span>
                    </Typography>
                  </Box>
                )}

                <NegotiationView
                  contractId={this.props.contract.Id}
                  negotiation={this.props.contract.CurrentNegotiation}
                  contractProvider={this.props.contract.ContractProvider}
                  contractState={this.props.contract.State}
                />
              </CardContent>
            </Card>
          )}
        </VerticalSpacing>
        <div className={classes.sidebar}>
          <VerticalSpacing>
            <Card>
              <CardHeader
                classes={{ root: classes.sidebarHeader }}
                title={'Avtal'}
                titleTypographyProps={{
                  variant: 'h2'
                }}
                subheader={
                  <ul className={classes.subheaderList}>
                    <li>
                      <AccountCircleIcon />{' '}
                      <Link
                        to={replaceId(
                          routes.customers.show,
                          this.props.customer.CustomerId
                        )}
                      >
                        {`${this.props.customer.FirstName} ${this.props.customer.LastName}`}
                      </Link>
                    </li>
                    <li>
                      <div className={classes.providerAvatar}>
                        <SupplierAvatar
                          logo={`${process.env.PUBLIC_URL}/api/c/provider/logo/${this.props.contract.ContractProviderId}`}
                          resetPosition
                          size={24}
                        />
                      </div>
                      <span>
                        <Link
                          to={replaceId(
                            routes.providers.show,
                            this.props.contract.ProviderId
                          )}
                        >
                          {this.props.contract.ContractProvider}
                        </Link>{' '}
                        ({this.props.contract.ContractType})
                      </span>
                    </li>
                    <li>
                      <PhoneAndroidIcon /> {convertedMobileNumber}
                    </li>
                  </ul>
                }
              />

              <CardContent>
                {!!this.props.contract.Children.length && (
                  <Box>
                    <Typography variant="h2">Påmind offers</Typography>
                    <ul className={classes.subheaderList}>
                      {this.props.contract.Children.map(child => (
                        <li>
                          <Link
                            to={replaceId(
                              routes.contracts.show,
                              child.ContractId
                            )}
                            target="_blank"
                          >
                            {`${child.StartDate} ${child.SupplierName}`}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}
                {!!Object.keys(this.props.contract.CustomerPreferences)
                  .length && (
                  <Box>
                    <Typography variant="h2">Preferenser</Typography>
                    <GeneratedList
                      values={this.props.contract.CustomerPreferences}
                      view={
                        contractTypes[contractType].views.contractPreferences
                      }
                      settings={{
                        keyValueOrientation: 'KeyValueHorizontal',
                        keyValueCompact: true,
                        keyValueDivider: 'exceptLast',
                        keyValueShowOverflow: true
                      }}
                    />
                  </Box>
                )}
                {!!(
                  this.props.contract.Attributes &&
                  !!Object.keys(this.props.contract.Attributes).length
                ) && (
                  <Box>
                    <Typography variant="h2">Contract attributes</Typography>
                    <GeneratedList
                      values={this.props.contract.Attributes}
                      view={
                        contractTypes[contractType].views.contractAttributes
                      }
                      settings={{
                        keyValueOrientation: 'KeyValueVertical',
                        keyValueCompact: true,
                        keyValueDivider: 'exceptLast'
                      }}
                    />
                  </Box>
                )}

                {!!(
                  this.props.contract.CurrentActiveTerms &&
                  !!Object.keys(this.props.contract.CurrentActiveTerms).length
                ) && (
                  <Box>
                    <Typography variant="h2">Current Active Terms</Typography>
                    <GeneratedList
                      values={this.props.contract.CurrentActiveTerms}
                      view={
                        contractTypes[contractType].views
                          .showManualContractAttributes
                      }
                      settings={{
                        keyValueOrientation: 'KeyValueHorizontal',
                        keyValueCompact: true,
                        keyValueDivider: 'exceptLast'
                      }}
                    />
                  </Box>
                )}
                <Chip
                  variant="outlined"
                  size="small"
                  label={`Contract: ${this.props.contract.State}`}
                />
                {this.props.contract.IsNonNegotiable && (
                  <Chip
                    style={{ marginTop: '1rem' }}
                    label="Ej förhandlingsbar"
                    variant="outlined"
                    size="small"
                  />
                )}
                {this.props.contract.IsNonNegotiable && (
                  <Typography>{this.props.contract.ContractDetails}</Typography>
                )}
              </CardContent>
              <CardActions>
                <ConfirmationDialog
                  label="Radera avtal"
                  onConfirm={this.delete}
                  confirmLabel="Radera"
                  variant="outlined"
                  size="small"
                  dialogTitle="Radera avtal"
                  dialogMessage="Är du säker på att du vill radera avtalet? Det kommer att tas bort permanent och statistik kring avtal kommer att påverkas."
                  icon={<IconDelete color="inherit" />}
                />
                {this.props.contract.CurrentNegotiation &&
                  (this.props.contract.CurrentNegotiation.State ===
                    'TermsRequested' ||
                    this.props.contract.CurrentNegotiation.State ===
                      'TermsNonreceipt') && (
                    <ConfirmationDialog
                      label={
                        'Ej kund till ' + this.props.contract.ContractProvider
                      }
                      onConfirm={this.setNotCustomer}
                      confirmLabel="Ej kund"
                      size="small"
                      variant="outlined"
                      dialogTitle={
                        'Ej kund till ' + this.props.contract.ContractProvider
                      }
                      dialogMessage={
                        'Är du säker på att ' +
                        this.props.contract.CustomerFirstName +
                        ' ' +
                        this.props.contract.CustomerLastName +
                        ' ej är kund hos ' +
                        this.props.contract.ContractProvider
                      }
                      icon={<IconNotCustomer color={'primary'} />}
                    />
                  )}
                <SendCustomerMessage
                  customerId={this.props.contract.CustomerId}
                  label="Skicka kundmail"
                  templates={[
                    'OnboardingWelcome',
                    'OnboardingContractsNotAdded',
                    'OnboardingContractsNotAddedReminder'
                  ]}
                />
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: this.state.expandedHeader
                  })}
                  onClick={this.handleExpandHeaderClick}
                  aria-expanded={this.state.expandedHeader}
                  aria-label="Show more"
                  size="large"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse
                in={this.state.expandedHeader}
                timeout="auto"
                unmountOnExit
              >
                <CardContent>
                  <EditContract
                    metadata={this.props.metadata}
                    contract={this.props.contract}
                  />
                </CardContent>
              </Collapse>
            </Card>
            {this.props.contract.CurrentNegotiation && (
              <Card>
                <CardContent>
                  <Box width="300px">
                    När du har klickad på uppdatera datum, så kommer ett jobb
                    startas som hanterar förhandlingen och ändrar status. Detta
                    kan ta några minuter, så ladda om sidan efter ett tag.
                  </Box>
                </CardContent>
                <CardActions>
                  <UpdateNegotiationDates
                    negotiationId={this.props.contract.CurrentNegotiation.Id}
                  />
                </CardActions>
              </Card>
            )}
            <UpcomingNegotiation
              contractId={this.props.contract.Id}
              negotiation={this.props.contract.UpcomingNegotiation}
            />
            <Card>
              <CardHeader
                avatar={<IconChanges color={'primary'} />}
                title="Händelser"
              />
              <CardActions className={classes.actions} disableSpacing>
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: this.state.expandedEventlog
                  })}
                  onClick={this.handleExpandEventlogClick}
                  aria-expanded={this.state.expandedEventlog}
                  aria-label="Show more"
                  size="large"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse
                in={this.state.expandedEventlog}
                timeout="auto"
                unmountOnExit
              >
                <CardContent>
                  <ShowEventlog events={this.props.contract.Events} />
                </CardContent>
              </Collapse>
            </Card>
          </VerticalSpacing>
        </div>
        {/* {this.props.contract.CurrentNegotiation && <NegotiationStateStepper />} */}
      </div>
    );
  }
}

Component.propTypes = {
  customer: PropTypes.object,
  metadata: PropTypes.object,
  contract: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  classes: PropTypes.object,
  dispatch: PropTypes.func
};

export const ShowContract = connect(state => ({
  customer: state.customer,
  metadata: state.metadata,
  contract: state.contract
}))(withStyles(styles)(Component));
