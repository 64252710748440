import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { isLoggedIn } from 'services/auth';
import { DashboardContainer } from 'components/dashboard/DashboardContainer';
import { AuthContainer } from 'components/auth/AuthContainer';
import { Provider, connect } from 'react-redux';
import { compose } from 'redux';
import { ConnectedRouter } from 'connected-react-router';
import { PamindTheme } from 'theme/PamindTheme';
import { Theme } from 'domain-components';
import { StyledEngineProvider } from '@mui/styled-engine';
import { CssBaseline } from '@mui/material';

const selectTheme = theme => {
  switch (theme) {
    case 'light':
      return Theme.lightTheme;
    case 'dark':
      return Theme.darkTheme;
    case 'coolBanana':
      return Theme.coolBanana;
    case 'funkyBlue':
      return Theme.funkyBlue;
    case 'forestGreen':
      return Theme.forestGreen;
    default:
      break;
  }
};
const connectWrapper = Component =>
  class extends React.Component {
    static propTypes = {
      theme: PropTypes.string,
      history: PropTypes.object
    };
    render() {
      return (
        <Component theme={this.props.theme} history={this.props.history} />
      );
    }
  };

const mapStateToProps = (state, props) => {
  if (!state) return null;
  return {
    theme: state.theme ? state.theme : props.theme
  };
};

const withConnect = compose(connect(mapStateToProps), connectWrapper);

const ThemeProvider = props => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={selectTheme(props.theme)}>
      <V0MuiThemeProvider muiTheme={PamindTheme}>
        <ConnectedRouter history={props.history}>
          <CssBaseline />
          <Route
            path="/"
            component={isLoggedIn() ? DashboardContainer : AuthContainer}
          />
        </ConnectedRouter>
      </V0MuiThemeProvider>
    </MuiThemeProvider>
  </StyledEngineProvider>
);

ThemeProvider.propTypes = {
  history: PropTypes.object,
  theme: PropTypes.string
};

const ConnectedThemeProvider = withConnect(ThemeProvider);

export class App extends React.Component {
  render() {
    return (
      <Provider store={this.props.store}>
        <ConnectedThemeProvider
          history={this.props.history}
          theme={this.props.theme}
        />
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object,
  history: PropTypes.object,
  theme: PropTypes.string
};
