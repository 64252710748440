import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Switch } from 'pamind-ui';
import { FormGroup } from 'pamind-ui';
import { FormControlLabel } from 'pamind-ui';
import { Typography, Table, TableBody, TableRow, TableCell } from 'pamind-ui';

function EventDate(props) {
  const date = moment(props.date);
  const daysFromToday = moment().diff(date, 'days');
  if (props.definitiveDates) {
    return (
      <Typography variant="body2">
        {moment
          .utc(props.date)
          .local()
          .format('YYYY-MM-DD HH:mm:ss.mmm')}
      </Typography>
    );
  }

  return <Typography>{daysFromToday} dagar sen</Typography>;
}
EventDate.propTypes = {
  definitiveDates: PropTypes.bool,
  date: PropTypes.string
};

export class ShowEventlog extends React.Component {
  state = {
    definitiveDates: true
  };
  toggleDefinitiveDates = () => {
    const newState = !this.state.definitiveDates;
    this.setState({
      definitiveDates: newState
    });
  };

  render() {
    if (!this.props.events) {
      return null;
    }
    return (
      <div>
        <FormGroup row>
          <FormControlLabel
            control={<Switch onChange={this.toggleDefinitiveDates} />}
            label={
              this.state.definitiveDates
                ? 'Visa datum i dagar'
                : 'Visa datum i detalj'
            }
          />
        </FormGroup>
        <div style={{ overflow: 'scroll' }}>
          <Table aria-label="simple table" size="small">
            <TableBody>
              {this.props.events.map((event, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <EventDate
                      date={event.CreatedAt}
                      definitiveDates={this.state.definitiveDates}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography variant="body2">{event.Type}</Typography>
                  </TableCell>
                  <TableCell scope="row">
                    <Typography variant="body2">{event.Text}</Typography>
                  </TableCell>
                  <TableCell scope="row">
                    <Typography variant="body2">{event.Value}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}
ShowEventlog.propTypes = {
  events: PropTypes.array
};
