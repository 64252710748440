import { dataTypes } from 'domain-components';

export function getContractTypes(metadata) {
  if (!metadata) {
    throw new Error('Metadata must be specified');
  }

  const keys = Object.keys(metadata.contractTypes);
  return keys.map(k => ({ key: k, label: metadata.contractTypes[k].label }));
}

export function getContractTypeLabelByKey(metadata, contractType) {
  if (!metadata) {
    throw new Error('Metadata must be specified');
  }

  return metadata.contractTypes[contractType].label;
}

export function getContractTypeContractDetails(metadata, contractType) {
  if (!metadata) {
    throw new Error('Metadata must be specified');
  }

  return metadata.contractTypes[contractType].contractDetailsOptions;
}

export function getContractTypeNegotiationDetails(metadata, contractType) {
  if (!metadata) {
    throw new Error('Metadata must be specified');
  }

  return metadata.contractTypes[contractType].negotiationDetailsOptions;
}

export function formatFieldValue(field, value) {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    (value !== null && value.Value === null && value.Infinity === false)
  ) {
    return '-';
  }

  if (field.type !== undefined && field.type.options !== undefined) {
    value = field.type.options.find(e => e.key === value).label;
  }

  if (value.Percent) {
    return value.Value + '%';
  }

  if (field.unit && value !== undefined && value.Value === undefined) {
    return value + ' ' + field.unit;
  } else if (value.Infinity === true) {
    return field.allowInfinityLabel;
  } else if (
    field.unit &&
    value.Value !== undefined &&
    value.Infinity === false
  ) {
    return value.Value + ' ' + field.unit;
  } else if (field.unit) {
    return value.Value + ' ' + field.unit;
  } else if (value.Value !== undefined && value.Infinity === false) {
    return value.Value;
  }

  return value.toString();
}

export function getProviderContractTypeFields() {
  return [
    {
      key: 'Email',
      label: 'E-postadress',
      type: dataTypes.email,
      required: true,
      primary: true
    },
    {
      key: 'PhoneNumber',
      label: 'Telefonnummer',
      type: dataTypes.text,
      required: false,
      primary: true
    },
    {
      key: 'ProvidesTerms',
      label: 'Lämnar information (ProvidesTerms)',
      type: dataTypes.boolToggle,
      primary: true
    },
    {
      key: 'ProvidesOffers',
      label: 'Lämnar erbjudanden (ProvidesOffers)',
      type: dataTypes.boolToggle,
      primary: true
    },
    {
      key: 'AllowsCancellation',
      label: 'Kan sägas upp (AllowsCancellation)',
      type: dataTypes.boolToggle,
      required: false,
      primary: true
    },
    {
      key: 'AllowsCommunication',
      label: 'Tar emot mail (AllowsCommunication)',
      type: dataTypes.boolToggle,
      primary: true
    },
    {
      key: 'AwardsLoyalty',
      label: 'Belönar lojalitet (AwardsLoyalty)',
      type: dataTypes.boolToggle,
      primary: true
    },
    {
      key: 'HandlesCancellation',
      label: 'Leverantör sköter bytet vid churn',
      type: dataTypes.boolToggle,
      primary: true
    },
    {
      key: 'UnselectableByCustomer',
      label: 'Dold från att väljas i leverantörslistan för kund',
      type: dataTypes.boolToggle,
      primary: true
    },
    {
      key: 'IsSalesProvider',
      label: 'Är SalesProvider',
      type: dataTypes.boolToggle,
      primary: true
    },
    {
      key: 'ConfirmationEmail',
      label:
        'E-postadress för bekräftelsemail (om annan än huvudsakliga e-postadress)',
      type: dataTypes.email,
      required: false,
      primary: true
    },
    {
      key: 'DaysToLastReplyDate',
      label: 'Antal dagar till sista svarsdatum',
      type: dataTypes.integer,
      required: false,
      primary: true
    },
    {
      key: 'StartNegotiationDaysExpiringPlan',
      label:
        'Antal dagar till förhandlingens start med bindningstid (ExpiringPlan)',
      type: dataTypes.integer,
      required: false,
      primary: true
    },
    {
      key: 'StartNegotiationDaysExpiringDiscount',
      label:
        'Antal dagar till förhandlingens start med rabatt (ExpiringDiscount)',
      type: dataTypes.integer,
      required: false,
      primary: true
    },
    {
      key: 'StartNegotiationDays',
      label:
        'Antal dagar till förhandlingens start (ingen bindningstid eller rabatt) (NoPlanOrDiscount)',
      type: dataTypes.integer,
      required: false,
      primary: true
    }
  ];
}

export function getProviderFields() {
  return [
    {
      key: 'Name',
      label: 'Leverantörens namn',
      type: dataTypes.text,
      required: true,
      primary: true
    },
    {
      key: 'TermsRequestDailyRateLimit',
      label: 'Max antal förfrågningar per dag',
      type: dataTypes.integer,
      required: false,
      primary: true
    },
    {
      key: 'ScrapingAlias',
      label: 'Scraping alias',
      type: dataTypes.text,
      required: false,
      primary: true
    }
  ];
}

export function getAdministratorFields() {
  return [
    {
      key: 'Email',
      label: 'E-postadress',
      type: dataTypes.email
    },
    {
      key: 'FirstName',
      label: 'Förnamn',
      type: dataTypes.text
    },
    {
      key: 'LastName',
      label: 'Efternamn',
      type: dataTypes.text
    },
    {
      key: 'Password',
      label: 'Lösenord',
      type: dataTypes.text
    }
  ];
}

export function getCancellationFields() {
  return [
    {
      key: 'CancellationEmail',
      label: 'Behöver email',
      type: dataTypes.boolToggle,
      required: false
    },
    {
      key: 'CancellationMobileNumber',
      label: 'Behöver mobilnummer',
      type: dataTypes.boolToggle,
      required: false
    },
    {
      key: 'CancellationLastDigitsOfCreditCard',
      label: 'Behöver 4 sista siffror på kort',
      type: dataTypes.boolToggle,
      required: false
    },
    {
      key: 'CancellationContractName',
      label: 'Behöver avtalsnamn',
      type: dataTypes.boolToggle,
      required: false
    },
    {
      key: 'CancellationAddress',
      label: 'Behöver adress',
      type: dataTypes.boolToggle,
      required: false
    },
    {
      key: 'CancellationUsername',
      label: 'Behöver användarnamn',
      type: dataTypes.boolToggle,
      required: false
    },
    {
      key: 'CancellationMemberId',
      label: 'Behöver medlems-id',
      type: dataTypes.boolToggle,
      required: false
    },
    {
      key: 'CancellationSubscriptionNumber',
      label: 'Behöver prenumerationsnummer',
      type: dataTypes.boolToggle,
      required: false
    },
    {
      key: 'CancellationCustomerNumber',
      label: 'Behöver kundnummer',
      type: dataTypes.boolToggle,
      required: false
    },
    {
      key: 'ExpirationDate',
      label: 'Behöver datum för avtalets upphörande',
      type: dataTypes.boolToggle,
      required: false
    }
  ];
}

export function getCreateNegotiationFields() {
  return [
    {
      key: 'Deadline',
      label: 'Deadline',
      type: dataTypes.date
    },
    {
      key: 'Type',
      label: 'Type',
      type: dataTypes.select,
      options: [
        { key: 'Retry', label: 'Retry' },
        { key: 'ExpiringPlan', label: 'ExpiringPlan' },
        { key: 'ExpiringDiscount', label: 'ExpiringDiscount' },
        { key: 'NoPlanOrDiscount', label: 'NoPlanOrDiscount' }
      ]
    }
  ];
}

export function editTermsAllowed(negotiationState, contractState) {
  if (contractState === 'Canceled') return false;
  return [
    'Waiting',
    'TermsRequested',
    'TermsReceived',
    'OfferReceived',
    'TermsNonReceipt',
    'DecisionNonReceipt',
    'SupplierResponded'
  ].includes(negotiationState);
}

export function editTermsEnabled(negotiationState, contractState) {
  if (contractState === 'Canceled') return false;
  return ['Waiting', 'TermsRequested'].includes(negotiationState);
}
