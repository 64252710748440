"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typography = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var modularScale = function modularScale(unit, settings) {
  if (settings.ratio === undefined || settings.base === undefined) {
    var placeholder = {
      ratio: 1.333,
      base: 18
    };
    settings = _objectSpread(_objectSpread({}, settings), placeholder);
  }

  if (unit === undefined) {
    unit = 0;
  }

  var value = Math.floor(Math.pow(settings.ratio, unit) * 100) / 100;
  return {
    value: value * settings.base,
    px: "".concat(value * settings.base, "px"),
    em: "".concat(value, "em")
  };
};

var lineHeight = function lineHeight(fontSize, _lineHeight) {
  if (_lineHeight === undefined) {
    _lineHeight = 1.5;
  }

  if (fontSize === undefined) {
    fontSize = 18;
  }

  var value = Math.floor(fontSize * _lineHeight);

  while (value % 4 !== 0) {
    value++;
  }

  return {
    value: value,
    px: "".concat(value, "px")
  };
};

var capitalize = function capitalize(string) {
  if (string === null || string === undefined || string.length === 0) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

var typography = {
  modularScale: modularScale,
  lineHeight: lineHeight,
  capitalize: capitalize
};
exports.typography = typography;