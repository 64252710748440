import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchProviders, createContract } from 'state/actions';
import { getContractTypes } from 'services/domain';
import {
  Select,
  MenuItem,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Fab,
  Dialog,
  Button,
  Switch,
  InputLabel,
  DialogContent,
  FormControl,
  RadioGroup,
  Radio,
  Typography
} from 'pamind-ui';
import AddIcon from '@mui/icons-material/Add';

export class Component extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchProviders());
  }

  state = {
    newContractType: null,
    newProviderId: null,
    newContractOpen: false,
    skipRequestTerms: false,
    newContractState: 'Ongoing'
  };

  handleOpen = () => {
    this.setState({ newContractOpen: true });
  };

  handleClose = () => {
    this.setState({ newContractOpen: false });
  };

  handleCreateNewContract = () => {
    const newContract = [
      {
        CustomerId: this.props.customerId,
        ProviderId: this.state.newProviderId,
        ContractType: this.state.newContractType,
        SkipRequestTerms: this.state.skipRequestTerms,
        NewContractState: this.state.newContractState
      }
    ];

    this.props.dispatch(createContract(newContract));

    this.setState({
      newContractType: null,
      newProviderId: null,
      newContractOpen: false,
      skipRequestTerms: false,
      newContractState: 'Ongoing'
    });
  };

  setRequestTerms = (e, value) => this.setState({ skipRequestTerms: value });

  handleChangeProvider = event => {
    this.setState({ newProviderId: event.target.value });
  };

  handleChangeContractType = event => {
    this.setState({
      newContractType: event.target.value,
      newProviderId: null
    });
  };

  handleChangeNewContractState = event => {
    this.setState({
      newContractState: event.target.value
    });
  };

  render() {
    if (!this.props.providers) {
      return null;
    }

    const filteredProviders = this.props.providers.filter(
      p => p.ContractType === this.state.newContractType
    );

    return (
      <div>
        <Fab
          color="primary"
          aria-label="Skapa avtal"
          onClick={this.handleOpen}
          style={{ position: 'fixed', bottom: '2rem', right: '2rem' }}
        >
          <AddIcon />
        </Fab>
        <Dialog
          modal={false}
          open={this.state.newContractOpen}
          onClose={this.handleClose}
        >
          <DialogTitle>Skapa avtal</DialogTitle>
          <DialogContent>
            <form
              style={{
                display: 'flex',
                flexFlow: 'column',
                flexWrap: 'nowrap'
              }}
            >
              <FormControl>
                <InputLabel shrink={true} htmlFor="contractType">
                  Avtalstyp
                </InputLabel>
                <Select
                  value={this.state.newContractType}
                  onChange={this.handleChangeContractType}
                  inputProps={{
                    id: 'contractType'
                  }}
                >
                  {getContractTypes(this.props.metadata).map(c => (
                    <MenuItem key={c.key} value={c.key}>
                      {c.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel shrink={true} htmlFor="provider">
                  Leverantör
                </InputLabel>
                <Select
                  value={this.state.newProviderId}
                  onChange={this.handleChangeProvider}
                  disabled={!this.state.newContractType}
                  inputProps={{
                    id: 'provider'
                  }}
                >
                  {filteredProviders.map(p => (
                    <MenuItem key={p.Id} value={p.Id}>
                      {p.Name}
                    </MenuItem>
                  ))}
                </Select>

                <Typography
                  component="h4"
                  variant="h4"
                  style={{ marginTop: '2rem' }}
                >
                  Avtalet är ett...
                </Typography>

                <RadioGroup
                  aria-label="NewState"
                  name="NewState"
                  value={this.state.newContractState}
                  onChange={this.handleChangeNewContractState}
                  style={{ marginTop: '0.5rem' }}
                >
                  <FormControlLabel
                    value="Ongoing"
                    control={<Radio />}
                    label="Befintligt avtal"
                    disabled={!this.state.newProviderId}
                  />
                  <FormControlLabel
                    value="Draft"
                    control={<Radio />}
                    label="Erbjudande†"
                    disabled={!this.state.newProviderId}
                  />
                </RadioGroup>
                <Typography
                  component="sup"
                  variant="sup"
                  style={{ marginTop: '0.5rem' }}
                  disabled={!this.state.newProviderId}
                  color="default"
                >
                  † OBS! Avtalet är ej synligt för kund innan det avpausas.
                </Typography>
                <FormControlLabel
                  style={{ marginTop: '2rem' }}
                  control={
                    <Switch
                      onChange={this.setRequestTerms}
                      disabled={
                        !this.state.newContractType || !this.state.newProviderId
                      }
                    />
                  }
                  label="Skicka INTE informationsförfrågan"
                />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions style={{ marginTop: '0.5rem' }}>
            <Button
              variant="text"
              color={'primary'}
              onClick={this.handleClose}
              size="medium"
            >
              Avbryt
            </Button>
            <Button
              variant="contained"
              color={'primary'}
              keyboardFocused={true}
              disabled={
                !this.state.newContractType || !this.state.newProviderId
              }
              onClick={this.handleCreateNewContract}
              size="medium"
            >
              Skapa avtal
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Component.propTypes = {
  dispatch: PropTypes.func,
  metadata: PropTypes.object,
  providers: PropTypes.array,
  customerId: PropTypes.number,
  signedPowerOfAttorney: PropTypes.bool
};

export const CreateContract = connect(state => ({
  metadata: state.metadata,
  providers: state.providers
}))(Component);
