import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, TableCell, TableRow } from 'pamind-ui';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router';
import { replaceId, routes } from 'paths';

export const TemplateListItem = ({ listItem }) => {
  const history = useHistory();
  if (!listItem) return null;

  const handleOnClick = () => {
    const goToPath = replaceId(routes.templates.show, listItem?.Id);
    history.push(goToPath);
  };

  return (
    <TableRow sx={{ cursor: 'pointer' }} onClick={handleOnClick}>
      <TableCell>{listItem.TemplateName}</TableCell>
      <TableCell>{listItem.ContractType}</TableCell>
      <TableCell>{listItem.ProviderName}</TableCell>
      <TableCell sx={{ display: 'flex', gap: 2 }}>
        <IconButton color="black">
          <ChevronRightIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

TemplateListItem.propTypes = {
  listItem: PropTypes.object
};
