import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import { Button } from 'pamind-ui';
import { Paper } from 'pamind-ui';
import IconLock from '@mui/icons-material/LockOutlined';
import { CircularProgress } from 'pamind-ui';
const style = {
  height: 300,
  width: 400,
  margin: 20,
  textAlign: 'center',
  display: 'inline-block'
};

export class AuthView extends React.Component {
  state = {
    email: '',
    password: ''
  };

  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  };

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.submitLogin(this.state.email, this.state.password);
  };

  renderError() {
    return <p className="error">Inloggning misslyckades</p>;
  }

  render() {
    const isValid = this.state.email !== '' && this.state.password !== '';
    const header = this.props.isAuthenticating ? 'Loggar in' : 'Logga in';
    return (
      <div>
        <Paper style={style} elevation={2}>
          <h2>
            <IconLock color={'primary'} /> {header}
          </h2>
          {!this.props.isAuthenticating && (
            <form onSubmit={this.handleSubmit}>
              <div>
                <TextField
                  floatingLabelText="E-postadress"
                  onChange={this.handleEmailChange}
                  id="email"
                />
              </div>
              <div>
                <TextField
                  floatingLabelText="Lösenord"
                  onChange={this.handlePasswordChange}
                  type="password"
                  id="password"
                />
              </div>
              <div>
                <Button
                  color={'primary'}
                  variant="contained"
                  type="submit"
                  disabled={this.props.isAuthenticating || !isValid}
                  size="small"
                >
                  Logga in
                </Button>
              </div>

              {this.props.error && this.renderError()}
            </form>
          )}
          {this.props.isAuthenticating && (
            <CircularProgress
              variant="indeterminate"
              style={{ marginTop: '4rem' }}
            />
          )}
        </Paper>
      </div>
    );
  }
}

AuthView.propTypes = {
  submitLogin: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  error: PropTypes.bool
};
