import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'pamind-ui';
import { TableBody } from 'pamind-ui';
import { TableCell } from 'pamind-ui';
import { TableHead } from 'pamind-ui';
import { TableRow } from 'pamind-ui';
import { Card } from 'pamind-ui';
import { CardContent } from 'pamind-ui';
import { CardHeader } from 'pamind-ui';
import { MonthsChart } from 'components/statistics/MonthsChart';

const formatValue = (value, statisticsType) => {
  if (value === null) return '-';
  return statisticsType === 'Ratio' ? value + ' %' : value;
};

export class StatisticsTable extends React.Component {
  state = {
    chartData: null,
    selectedRow: null
  };

  handleRowSelection = selectedRow => {
    this.setState({
      selectedRow: selectedRow ? selectedRow : null
    });
  };

  render() {
    const selectedRow = this.state.selectedRow
      ? this.props.data[this.state.selectedRow]
      : null;
    const selectedMonths = selectedRow ? selectedRow.Months : null;
    const selectedStatisticsType = selectedRow
      ? selectedRow.StatisticsType
      : null;
    return (
      <Card>
        <CardHeader title={this.props.title} />
        <CardContent>
          <MonthsChart
            data={selectedMonths}
            statisticsType={selectedStatisticsType}
          />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{this.props.keyLabel}</TableCell>
                <TableCell>Totalt</TableCell>
                <TableCell>7 dagar</TableCell>
                <TableCell>Idag</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(this.props.data).map((key, index) => {
                const row = this.props.data[key];
                const type = row.StatisticsType;
                return (
                  <TableRow
                    key={key}
                    onClick={() => this.handleRowSelection(key)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell title={key}>{key}</TableCell>
                    <TableCell>{formatValue(row.Total, type)}</TableCell>
                    <TableCell>{formatValue(row.Last7Days, type)}</TableCell>
                    <TableCell>{formatValue(row.Today, type)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}
StatisticsTable.propTypes = {
  title: PropTypes.string,
  keyLabel: PropTypes.string,
  data: PropTypes.object
};
